import Vue from 'vue';
var locale = Vue.localStorage.get('locale') || 'es';

const ParticipantTypeOptions = Object.freeze([
  { text: locale == 'en' ? 'Active' : 'Activo', value: 'Active' },
  { text: locale == 'en' ? 'Passive' : 'Pasivo', value: 'Passive' },
  { text: locale == 'en' ? 'Citizen' : 'Ciudadano', value: 'Citizen' },
  { text: locale == 'en' ? 'Monitor' : 'Monitor', value: 'Monitor' },
  { text: locale == 'en' ? 'Supervisor' : 'Supervisor', value: 'Supervisor' },
]);

export default ParticipantTypeOptions;
