const EmployeeStatus = Object.freeze({
  Attending: 'Atendiendo',
  Available: 'Disponible',
  Logout: 'Logout',
  Login: 'Login',
  Break: 'Receso',
  OutOfOffice: 'No Está'
});

export default EmployeeStatus;
