<template>
  <section>
    <content-header :title="$t('appointmentsAvailabeVsAppointmentsTaken')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <report-filters
          ref="ReportFilters"
          class="mb-4"
          :company-default-value="companyId"
          :export-text="$t('export')"
          :search-text="$t('generate')"
          :send-current-user-id="true"
          :service-types-data-source="serviceTypesDataSource"
          :show-hour-from="true"
          :show-hour-to="true"
          :show-locations="false"
          :show-service-queues="false"
          :show-service-types="true"
          :show-users="false"
          @on-external-display="onDisplayExternally($event)"
          @search="onSearchClick($event)"
        />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <report-viewer
            ref="report-ref"
            export-report-name="AppointmentsAvailableExport"
            report-name="AppointmentsAvailable"
            width="790px"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import ReportViewer from '@/components/ReportViewer';
import ReportFilters from '@/components/ReportFilters';

import { mapGetters } from 'vuex';
import { getAppointmentServicesByCompanyId as _getAppointmentServicesByCompanyId } from '@/services/AppointmentService';

export default {
  name: 'AppointmentsAvailableReport',
  components: {
    contentHeader,
    ReportViewer,
    ReportFilters,
  },
  data() {
    return {
      companyId: null,
    };
  },
  computed: {
    ...mapGetters('$_user', [
      'fullProfile',
      'profile',
      'currentActiveLocation',
    ]),
  },
  mounted() {
    const companyIds = Object.keys(this.fullProfile.companies).map((a) =>
      Number.parseInt(a),
    );

    this.companyId = Math.max(...companyIds);
  },
  methods: {
    async serviceTypesDataSource() {
      const response = await _getAppointmentServicesByCompanyId(this.companyId)
        .then((response) => {
          return response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      return response;
    },
    async onSearchClick(parameters) {
      this.$refs['report-ref'].render(parameters);
    },
    async onDisplayExternally(parameters) {
      this.$refs['report-ref'].renderExternal(parameters);
    },
  },
};
</script>