<template>
  <div>
    <h1>Encuestas por Operador</h1>
    <office-details-map id="surveybyoperatorreport" />
  </div>
</template>
<script>
import OfficeDetailsMap from '@/components/reports/SurveyByOperator';

// NOTE: This view is for testing purpose. This component will be inside the dashboard view
export default {
  name: 'SurveyByOperatorTest',
  components: {
    OfficeDetailsMap
  }
};
</script>
