<template>
  <div class="reportviewer-container">
      <iframe
      v-show="!loading"
      :id="id"
      :class="cssClass"
      sandbox="allow-scripts allow-same-origin allow-popups allow-downloads allow-forms"
      src=""
      :style="reportStyle"
      width="100%"
      @load="() => loading = false"
      />
      <loading
          :active="loading"
          color="#F37E00"
          :is-full-page="true"
      />
  </div>
</template>

<script>

import { REPORTS } from '@/config';
import { getReportDocument as _getReportDocument,
         setReportParameters as _setReportParameters 
        } from '@/services/ReportService';

import _generalApi from '@/modules/app/api.js';

import ReportType from '@/constants/ReportType';

export default {
  props: {
    id: {
      type: String,
      default: 'reportContainer',
    },
    reportServerUrl: {
      type: String,
      default: () => REPORTS.BASE_URL,
    },
    reportServerExportUrl: {
      type: String,
      default: () => REPORTS.BASE_EXPORT_URL
    },
    reportPath: {
      type: String,
      default: () => '',
    },
    reportName: {
      type: String,
      required: true,
      default: () => '',
    },
    exportReportName: {
      type: String,
      default: () => '',
    },
    exportFormat: {
      type: String,
      default: () => 'EXCEL',
    },
    showParameters: {
      type: Boolean,
      default: () => false,
    },
    language: {
      type: String,
      default: () => 'es-US',
    },
    width: {
      type: String,
      default: '1360px',
    },
    toolbar: {
      type: Boolean,
      default: () => true,
    },
    styleSheetName: {
      type: String,
      default: () => '',
    },
    cssClass: {
      type: String,
      default: () => '',
    },
    reportType: {
      type: Number,
      default: () => ReportType.SSRS,
    }
  },
  data() {
    return {
      parameters: null,
      showToolbar: this.toolbar,
      loading: false,
      currentHashCode: null
    };
  },
  computed: {
    reportStyle() {
      return `flex-grow: 1; border: none; margin: 0; padding: 0; height: 840px; max-width: ${this.width}`;
    },
    reportAbsoluteQueryString() {
      const result =
        `rs:Embed=true&rs:Command=ClearSession` +
        `&rc:ReportName=${this.reportName}` +
        `&rc:Parameters=${this.showParameters ? 'true' : 'false'}` +
        this.reportParameters +
        `&rs:ParameterLanguage=${this.language}&rc:Toolbar=${this.showToolbar}` +
        `${this.styleSheetName ? '&rc:Stylesheet=' + this.styleSheetName : ''}`;

      return result;
    },
    reportAbsoluteExportQueryString() {
      const result =
        `rs:Embed=true&rs:Command=ClearSession` +
        this.reportParameters +
        `&rs:Command=Render&rs:ClearSession=true&rs:Format=${this.exportFormat}`;

      return result;
    },
    reportAbsoluteUrl() {
      const result =
        `${this.reportServerUrl}?${this.reportPath}/${this.reportName}&hc=${this.currentHashCode}`;

      return result;
    },
    reportAbsoluteExportUrl() {
      const result =
        `${this.reportServerExportUrl}?${this.reportPath}/${this.exportReportName}&hc=${this.currentHashCode}`;

      return result;
    },
    reportParameters() {
      let strFilter = '';

      if (this.parameters == null || Object.keys(this.parameters).length == 0) {
        return strFilter;
      }

      for (const property in this.parameters) {
        if (Array.isArray(this.parameters[property])) {
          for (let i = 0; i < this.parameters[property].length; i++) {
            strFilter += `&${property}=${this.parameters[property][i]}`;
          }
        }

        if (
          this.parameters[property] != null &&
          this.parameters[property] != undefined &&
          !Array.isArray(this.parameters[property])
        ) {
          strFilter += `&${property}=${this.parameters[property]}`;
        }

        if (this.parameters[property] == null) {
          strFilter += `&${property}:isnull=true`;
        }
      }

      return strFilter;
    },
    hasParameters() {
      const result =
        this.parameters &&
        (this.parameters != {} ||
          (Array.isArray(this.parameters) && this.parameters.length > 0));

      return result;
    },
  },
  async mounted() {},
  methods: {
    async render(params) {
      this.parameters = params;

      let frame = document.getElementById(this.id);

      this.loading = true;

      await _setReportParameters({
        data: this.reportAbsoluteQueryString,
        reportType: this.reportType
      }).then((resp) => {
        this.currentHashCode = resp.data;

        frame.src = this.reportAbsoluteUrl;
      })
    },
    async renderExternal(params) {
      this.parameters = params;

      await _setReportParameters({
        data: this.reportAbsoluteQueryString,
        reportType: this.reportType
      }).then((resp) => {
        this.currentHashCode = resp.data;

        this.showToolbar = false;

        const targetUrl = this.reportAbsoluteUrl + '&rc:allPages=true';

        this.showToolbar = true;

        window.open(targetUrl, '_blank');
      })
    },
    async renderExport(params) {
      this.parameters = params;

      await _setReportParameters({
        data: this.reportAbsoluteExportQueryString,
        reportType: this.reportType
      }).then(async (resp) => {
        this.currentHashCode = resp.data;

        const targetUrl = this.reportAbsoluteExportUrl;

        await _getReportDocument(targetUrl).then((response) => {
          _generalApi.ConvertToBlob(response.data);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reportviewer-container {
  text-align: center;
  width: 100%;
  background-color: White;
}

.reportviewer-container iframe {
  margin-left: auto;
  margin-right: auto;
}

.iframe-container {
  overflow: hidden;
  padding-top: 840px;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
  padding-top: 75%;
}
</style>
