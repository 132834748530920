import { apiTurnos } from '@/core/api/';

export const getSpecialistList = (
  locationConfigurationId
) => apiTurnos.get(
  `/api/specialist/list?locationConfigurationId=${locationConfigurationId}`
);

export default {
  getDropDownListForCreateSpace(
    companyId,
    specialistId,
    municipalityId,
    locationId,
    locationConfigurationId
  ) {
    return apiTurnos.get(
      `/api/specialist/list?companyId=${companyId}&specialistId=${specialistId}&municipalityId=${municipalityId}&locationId=${locationId}&locationConfigurationId=${locationConfigurationId || 0}`
    );
  }
};