<template>
  <validation-observer ref="form">
    <div class="row">
      <div class="col-md-6">
        <base-input
          :value="value.clientEmail"
          :disabled="true"
          :label="$t('email')"
        />
      </div>
      <div class="col-md-6">
        <base-input
          :value="value.clientAppointmentId"
          :disabled="true"
          :label="$t('appointmentNumber')"
        />
      </div>
      <div class="col-md-6">
        <base-input :value="firstName" :disabled="true" :label="$t('name')" />
      </div>
      <div class="col-md-6">
        <base-input
          :value="lastName"
          :disabled="true"
          :label="$t('simpleLastName')"
        />
      </div>
      <validation-provider
        v-slot="{ errors }"
        class="col-md-6"
        rules="required"
      >
        <filter-select
          :error="errors[0]"
          :error-msg="$t('mustSelectRow')"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('row')"
          :options="serviceQueues"
          :clearable="false"
          v-model="value.serviceQueueId"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        class="col-md-6"
        :rules="{
          required: !!serviceTypes.length,
        }"
      >
        <filter-select
          :error="errors[0]"
          :error-msg="$t('mustSelectService')"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('service')"
          :options="serviceTypes"
          :disabled="!serviceTypes.length"
          :clearable="false"
          v-model="value.codeServiceTypeId"
        />
      </validation-provider>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <filter-select
              fieldtext="name"
              fieldvalue="id"
              :label="$t('visitTo')"
              :options="attendants"
              v-model="value.visitingId"
            />
          </div>
          <div class="col-12">
            <base-input
              :value="value.cited"
              :disabled="true"
              :label="$t('cited')"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <base-input
          :value="value.comment"
          :disabled="true"
          :label="$t('comment')"
          :textarea="true"
          :max-length="240"
          :rows="7"
        />
      </div>
      <div class="col-md-6">
        <base-input
          :value="value.specialField1"
          :disabled="true"
          :label="$t('specialField1')"
        />
      </div>
      <div class="col-md-6">
        <base-input
          :value="value.specialField2"
          :disabled="true"
          :label="$t('specialField2')"
        />
      </div>
      <div class="col-md-6">
        <base-input
          :value="value.specialField3"
          :disabled="true"
          :label="$t('specialField3')"
        />
      </div>
      <div class="col-md-6">
        <base-input
          :value="value.specialField4"
          :disabled="true"
          :label="$t('specialField4')"
        />
      </div>
      <div class="col-md-6">
        <base-input
          :value="value.specialField5"
          :disabled="true"
          :label="$t('specialField5')"
        />
      </div>
      <div class="col-md-6">
        <filter-select
          :value="value.isBlind"
          :disabled="true"
          fieldtext="text"
          fieldvalue="value"
          :label="$t('blind')"
          :options="confirmationOptions"
          translate
        />
      </div>
      <div class="col-md-6">
        <filter-select
          :value="value.attendedByPhone"
          :disabled="true"
          fieldtext="text"
          fieldvalue="value"
          :label="$t('attendByPhone')"
          :options="confirmationOptions"
          translate
        />
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-12">
            <h5 class="text-center title-phone py-2">
              {{ $t('phoneNotification') }}
            </h5>
          </div>
          <div class="col-md-6">
            <base-input
              :value="value.clientCel"
              :disabled="true"
              :label="$t('mobileNumber')"
            />
          </div>
          <div class="col-md-6">
            <filter-select
              :disabled="true"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('provider')"
              :options="carriers"
              :value="value.carrierId"
            />
          </div>
        </div>
      </div>

      <div class="col-12 my-2">
        <button class="btn btn-dark float-left" @click="$emit('cancel')">
          <i class="fas fa-ban" /> {{ $t('cancel') }}
        </button>
        <button class="btn btn-primary float-right" @click="onSubmit()">
          <i class="fas fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import { editTurn as _editTurn } from '@/services/TurnService';
import { getOverWaitingServiceTypes as _getOverWaitingServiceTypes } from '@/services/ServicesService';
import ConfirmationOptions from '@/constants/ConfirmationOptions';

export default {
  name: 'EditForm',
  components: {
    BaseInput,
  },
  props: {
    serviceQueues: {
      type: Array,
      default: () => [],
    },
    attendants: {
      type: Array,
      default: () => [],
    },
    carriers: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      serviceTypes: [],
      confirmationOptions: ConfirmationOptions,
    };
  },
  computed: {
    firstName() {
      if (!this.value) return null;
      return this.value.clientFirstName ?? this.value.clientName;
    },
    lastName() {
      if (this.value && this.value.clientFirstName)
        return this.value.clientLastName;
      return null;
    },
  },
  watch: {
    'value.serviceQueueId': {
      immediate: true,
      handler() {
        this.getServiceTypes();
      },
    },
  },
  methods: {
    async getServiceTypes() {
      if (!this.value.serviceQueueId) return;
      this.serviceTypes = [];
      await _getOverWaitingServiceTypes(
        this.value.serviceQueueId,
        this.value.locationConfigurationId,
      )
        .then(({ data }) => {
          const type = data.find((x) => x.id === this.value.codeServiceTypeId);
          if (!type) this.value.codeServiceTypeId = null;
          this.serviceTypes = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;
      await _editTurn({
          ...this.value,
            clientAppointmentId: this.value.clientAppointmentId ? parseInt(this.value.clientAppointmentId) : null
      })
        .then(() => {
          this.$emit('submitted');
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
.title-phone {
  background-color: #feebd0;
  color: $color-font-secondary;
}
</style>
