import { apiTurnos } from '@/core/api/';
const qs = require('querystring');

export const getStampStatusList = () => apiTurnos.get(`/api/stamp/status/list`);

export const getSubServicesList = (serviceTypeList) => {
    const qParams = {
        params: {
            serviceTypeList
        },
        paramsSerializer: params => {
            return qs.stringify(params);
        }
    };
    return apiTurnos.get(`/api/stamp/subservice/list`, qParams);
};