import { apiTurnos } from '@/core/api/';

export const getInitialOfficeDetailsMapFilters = () => {
  return apiTurnos.get('api/report/filters/init/officedetailsmap');
};

export const getInitialAttentionPerformanceFilters = () => {
  return apiTurnos.get('api/report/filters/init/attentionperformance');
};

export const getInitialMainMetricsFilters = () => {
  return apiTurnos.get('api/report/filters/init/mainmetrics');
};

export const getInitialSurveyFilters = () => {
  return apiTurnos.get('api/report/filters/init/survey');
};

export const getInitialSurveyByOperatorFilters = () => {
  return apiTurnos.get('api/report/filters/init/surveybyoperator');
};

export const getRegionalRealTime = (payload) =>
  apiTurnos.get(`api/report/regionalrealtime/${payload}`);

export const getSurveyDataDocument = (surveyId, dateFrom, dateTo, answerMode) => {
  return apiTurnos.get(`api/report/survey/turndata`, {
    params: {
      surveyId,
      dateFrom,
      dateTo,
      answerMode
    }
  });
};

export const getTurnsQuantity = (payload) => {
  return apiTurnos.get(`api/report/turnsquantitygauge`, {
    params: payload,
  });
}

export const getTotalTransactions = (payload) => {
  return apiTurnos.get(`api/report/totaltransactionsgauge`, {
    params: payload,
  });
}

export const getAverageWaitTime = (payload) => {
  return apiTurnos.get(`api/report/averagewaittimegauge`, {
    params: payload,
  });
}

export const getAverageServiceTime = (payload) => {
  return apiTurnos.get(`api/report/averageservicetimegauge`, {
    params: payload,
  });
}

export const getTurnsQuantityPhone = (payload) => {
  return apiTurnos.get(`api/report/turnsquantityphonegauge`, {
    params: payload,
  });
}

export const getTurnsQuantityEmail = (payload) => {
  return apiTurnos.get(`api/report/turnsquantityemailgauge`, {
    params: payload,
  });
}

export const getReportDocument = (url) => {
  return apiTurnos.get(url);
}

export const getRegionalLiveDashboard = (payload) =>
  apiTurnos.get(`api/report/regionallivedashboard/${payload}`);

export const getRegionalCFSERealTime = (payload) =>
  apiTurnos.get(`api/report/regionalcfserealtime/${payload}`);

export const setReportParameters = model =>
  apiTurnos.post(`api/report/params/set`, model);