<template>
  <div>
    <b-form-group class="c-filterListBox">
      <template :v-slot="label">
        <div class="c-s-filterLabel">
          <span>{{ this.label != null ? this.label + ':' : null }}</span>
          <span v-if="required" class="text-danger">*</span>
        </div>
      </template>

      <div class="c-s-filterListBox">
        <button :id="id" ref="listBoxButton" type="button" aria-haspopup="listbox" :disabled="isDisabled"
          class="position-relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          @click="changeState($event)">
          <span class="d-block truncate">{{ selectedItemsText }}</span>
          <i class="d-block pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 fad fa-sort-down"></i>
        </button>
        <transition name="fade">
          <ul v-if="isOpen"
              ref="listBoxContent"
              aria-orientation="vertical" 
              :id="listBoxId" 
              role="listbox" 
              tabindex="0" 
              class="position-absolute mt-1 max-h-60 w-100 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
            >
            <li v-for="(item, index) in options" 
                :id="`${id}-option-${index}`"
                :key="`${id}-option-${index}`"
                :ref="`${id}-option-${index}`"
                role="option" 
                tabindex="-1" 
                class="text-gray-900 position-relative cursor-default select-none py-2 pl-10 pr-4"
                :class="{ 'active' : item.selected }"
                @keypress="handleSearch($event)"
                @click="onSelectionsChanged(item)">
              <span :id="`${id}-option-text-${index}`" class="option-text">{{ item.name }}</span>
              <span :id="`${id}-option-status-${index}`" v-if="item.selected" class="option-check text-amber-600">
                <i class="far fa-check"></i>
              </span>
            </li>
          </ul>
      </transition>
      </div>
      <button
        v-if="
          isMultiple && options.length > 0 && !disabled
        "
        class="c-selectAllButton"
        @click="selectAllOptions"
      >
        {{ $t('selectall') }}
      </button>
      <button v-if="
          isMultiple && options.length > 0 && !disabled
        " 
        class="c-clearAllButton" @click="deselectAllOptions">
        {{ $t('clear') }}
      </button>
      <error v-if="error" class="mt-n1" :message="errorMsg" variant="no-border no-background" />
    </b-form-group>
  </div>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';

export default {
  name: 'FilterListBox',
  components: {
    Error,
  },
  props: {
    id: {
      type: String,
      default: () => '',
      meta: {
        description: 'Id of the tag',
        example: 'ddlOffice',
      },
    },
    name: {
      type: String,
      default: () => '',
      meta: {
        description: 'Name of the tag',
        example: 'ddlOffice',
      },
    },
    label: {
      type: String || null,
      default: () => null, // Taken from the locale dictionary
      required: false,
      meta: {
        description: 'Label text',
        example: 'Office',
      },
    },
    placeholder: {
      type: String,
      default: 'select', // Taken from the locale dictionary
      meta: {
        description: 'Texto de referencia para el CustomDropDown',
        example: 'Select',
      },
    },
    required: {
      type: Boolean,
      default: function () {
        return false;
      },
      meta: {
        description: 'Required flag',
      },
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      meta: {
        description: 'Disabled flag',
      },
    },
    options: {
      type: Array,
      default: () => [],
      meta: {
        description: 'Elements who can be chosen',
        example: '[4, 8, 15, 16, 23, 42]',
      },
    },
    value: {
      type: Array,
      default: () => [],
    },
    fieldvalue: {
      type: String,
      required: true,
      meta: {
        description: 'Field that contains the value that will be chosen',
        example: 'id',
      },
    },
    fieldtext: {
      type: String,
      required: true,
      meta: {
        description: 'Field that contains the text to display',
        example: 'name',
      },
    },
    clearable: {
      type: Boolean,
      default: () => false,
      meta: {
        description: 'Makes the clear button visible',
        example: 'true',
      },
    },
    isMultiple: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'If the field is gonna be able to select multiple options or not',
        example: 'true',
      },
    },
    selectAllEnabled: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'Whether or not display a button to select all options at once',
      },
    },
    icon: {
      type: String,
      default: null,
      meta: {
        description:
          'Go down, to the css section and look for a list of available icons. Use any class identifier found there.',
      },
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    allowSelectAll: {
      type: Boolean,
      default: () => true,
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        this.model = newVal;
      }
    },
    isOpen(newVal){
      if(newVal)
        this.$nextTick(() => {
          document.getElementById(this.id).addEventListener('keypress', this.handleSearch);
        });
    },
  },
  data() {
    return {
      model: this.value,
      isOpen: false,
      searchText: ''
    };
  },
  computed: {
    isDisabled() {
      return this.options.length == 0 || this.disabled;
    },
    selectedItemsText() {
      if(this.value.length == 0)
        return this.placeholder || `-- ${this.$t('select')} --`;

      if(this.options.length == 0)
        return '';

      if(this.options.length == this.value.length && this.value.length > 1)
        return `-- ${this.$t('all')} --`;

      const result = this.options.filter(x => x.selected).map(x => x.name).join(', ').substring(0, 100);

      return result;
    },
    listBoxId() {
      return this.id + '-listbox-options';
    },
    hasDisabledValue() {
      return (
        Array.isArray(this.model) &&
        this.model.length > 0 &&
        this.model.some((x) => x.disabled == true)
      );
    },
    areAllOptionsSelected() {
      return (
        this.options.length > 0 && this.value.length === this.options.length
      );
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (this.$refs.listBoxContent && 
          !this.$refs.listBoxContent.contains(event.target) &&
          !this.$refs.listBoxButton.contains(event.target)) {

        if(!event.target.id.includes(`${this.id}-option-`))
          this.isOpen = false;
      }
    },
    handleSearch(event) {
      if(this.searchText == '') {
          setTimeout(async () => {
            this.searchText = '';
          }, 1000);
        }

      const isLetter = /^[a-zA-Z]$/.test(event.key);

      if (isLetter) {
        this.searchText += event.key;
      }

      if(this.searchText != '') {
        const searchIndex = this.options.findIndex(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()));

        this.scrollListToItem(searchIndex);
      }
    },
    changeState(){
      this.isOpen = !this.isOpen;
    },
    onSelectionsChanged(item) {
      this.$emit('change', [item], this.isMultiple);
    },
    selectAllOptions() {
      if (this.options.length > 0) {
        this.$emit('change', this.options);
      }
    },
    deselectAllOptions() {
      if (!this.disabled)
        this.$emit('change', []);
    },
    scrollListToItem(index) {
      const lbList = document.getElementById(this.listBoxId)
      const lbItem = document.getElementById(`${this.id}-option-${index}`);

      if(lbItem)
        lbList.scrollTop = lbItem.offsetTop;
    },
  },
};
</script>
<style scoped lang="scss">
.c-filterListBox {
  i {
    color: #eb6d00 !important;
  }

  button {
    width: 100%;
    border: 0;

    span {
      width: 90%;
      float: left;
    }

    i {
      float: right;
      padding-top: 4px;
    }
  }

  .c-s-filterLabel {
    color: $color-font-secondary;
    padding-bottom: calc(0.375rem + 1px) !important;
  }

  

  .c-s-filterListBox {
    background-color: $color-white;
    border-radius: 4px;
    border: 1px solid $color-label-default;
    display: block;
    height: calc(1.5em + 1rem + 2px);
    align-items: center;
    position: relative;

    &:focus-visible, & ul:focus-visible, & ul li:focus-visible {
      outline: 0px;
    }

    ul {
      z-index: 3;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding-left: 0;
      line-height: 1rem;

      li {
        list-style-type: none;
        border-bottom: 1px solid lightgray;

        &.active {
          --tw-bg-opacity: 1;
          background-color: rgba(255, 129, 5, 0.1);
          --tw-text-opacity: 1;
          color: #78350f;
        }

        span.option-text {
          display: block;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }

        span.option-check {
          display: flex;
          position: absolute;
          align-items: center;
          font-size: 16px;
          top: 0;
          bottom: 0;
          left: 0;
          padding-left: 0.8rem !important;
        }

        &:hover{
          --tw-bg-opacity: 1;
            background-color: rgba(255, 129, 5, 0.1);
          --tw-text-opacity: 1;
          color: rgb(120,53,15);
        }

        &.bg-amber-100 {
          --tw-bg-opacity: 1;
          background-color: rgba(255, 129, 5, 0.1);
        }

        &.text-amber-900 {
          --tw-text-opacity: 1;
          color: rgb(120,53,15);
        }

        .text-amber-600 {
            --tw-text-opacity: 1;
            color: rgb(255, 129, 5);
        }
      }
    }

    ::v-deep .vs__dropdown-option.vs__dropdown-option--selected {
      /*background-color: #ececec;*/
      /*color:black;*/
      /*cursor: default;*/
      display: none;
    }

    ::v-deep .vs__dropdown-toggle {
      border: none;
    }

    ::v-deep .v-select {
      flex: 100% 1 1;
    }

    &.clock {
      ::v-deep .vs__selected-options {
        padding-left: 40px;
      }

      .icon {
        display: flex;
        align-items: center;
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;

        /*ICONS IDENTIFIER*/
        &:after {
          content: '\f017';
          font-family: 'Font Awesome 5 Pro', sans-serif;
          font-size: 19px;
          color: $color-primary;
          margin-right: 13px;
        }
      }
    }
  }
}

.c-selectAllButton, .c-clearAllButton {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  margin-top: 10px;
  text-decoration: underline;
  width: 50%!important;
}

.c-selectAllButton {
  text-align: left;
}

.c-clearAllButton {
  text-align: right;
}

.scrollable-list {
  height: 200px;
  overflow-y: auto;
}

.truncate,
.whitespace-nowrap {
  white-space: nowrap;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.shadow-lg {
  --tw-shadow: 0 5px 10px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
  --tw-shadow-colored: 0 5px 10px -3px black,0 4px 6px -4px black;
}

.max-h-60 {
  max-height: 15rem;
}

.sm\:text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.ring-black\/5 {
  --tw-ring-color: rgba(0,0,0,.05);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.rounded-md {
    border-radius: 0.375rem;
}

.w-5 {
    width: 1.25rem;
}
.h-5 {
    height: 1.25rem;
}

.left-0 {
  left: 0;
}

.pl-10 {
    padding-left: 2.5rem;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.items-center {
    align-items: center;
}

</style>
