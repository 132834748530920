<template>
  <section>
    <content-header :title="$t('employeeStatus')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <filter-manager
          v-model="selectedFilters"
          class="mb-4"
          :disabled-search="!isLocationSelected"
          :filters="filtersConfig"
          :search-text="$t('generate')"
          @search="onGenerateHandler()"
        />
      </div>
      <separator
        v-if="showContent"
        class="my-4"
        :text="$t('employeeStatusList')"
      />
      <div v-if="showContent" class="row my-4 px-3">
        <div
          class="col-md-12 col-lg-5 py-2"
          style="border: 1px solid #ff8105; border-radius: 10px"
        >
          <div class="row">
            <div class="col-sm-6 col-md-4">
              <status-indicator-icon
                :count="result.stats.openStation"
                icon="users-class"
                :title="$t('registeredRepresentative')"
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <status-indicator-icon
                :count="result.stats.totalAvailable"
                icon="users"
                :title="$t('available')"
              />
            </div>
            <div class="col-md-4">
              <status-indicator-icon
                :count="result.stats.totalAttending"
                icon="user-cog"
                :title="$t('attending')"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-2 my-3 my-lg-0 px-lg-4">
          <div class="row">
            <div
              class="col-md-12 py-2"
              style="border: 1px solid #0c8a29; border-radius: 10px"
            >
              <status-indicator-icon
                :count="result.stats.totalBreak"
                icon="sign-out-alt"
                :title="$t('breaks')"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12 col-lg-5 py-2"
          style="border: 1px solid #006fff; border-radius: 10px"
        >
          <div class="row">
            <div class="col-md-4">
              <status-indicator-icon
                :count="result.stats.totalAttended"
                icon="check-double"
                :title="$t('customerAttended')"
              />
            </div>
            <div class="col-sm-6 col-md-4">
              <status-indicator-icon
                :count="result.stats.totalNotAttended"
                icon="people-arrows"
                :title="$t('customerToAttend')"
              />
            </div>
            <div class="col-sm-6 col-md-4" @click="goToOverwaitingReport()">
              <status-indicator-icon
                class="cursor-pointer"
                :count="result.stats.totalOverWaiting"
                icon="hourglass-end"
                :title="$t('aboutWaitingTime')"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="showContent" class="main-page-container mb-5">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <div>
              <div class="chip" />
              {{ $t('available') }}
            </div>
            <div>
              <div class="chip chip-grey" />
              {{ $t('attending') }}
            </div>
            <div>
              <div class="chip chip-blue" />
              {{ $t('requestingRecess') }}
            </div>
            <div v-if="haveRepresentativeType">
              <div class="chip chip-purple" />
              {{ $t('onLogout') }}
            </div>
            <div>
              <div class="chip chip-green" />
              {{ $t('inRecess') }}
            </div>
            <div>
              <div class="chip chip-red" />
              {{ $t('excessRecess') }}
            </div>
          </div>
          <div
            v-if="employees.length"
            class="col-md-12 mt-5"
            style="height: 600px; overflow: auto"
          >
            <div class="row">
              <div
                v-for="(item, i) in employees"
                :key="i"
                class="col-md-12 mt-3"
              >
                <div class="row">
                  <div class="col-12" :class="{'col-md-8 pr-1': isActiveParticipant(item), 'col-md-12': !isActiveParticipant(item) }">
                    <search-result
                      :has-hold-configured="result.hasHoldConfig"
                      :have-representative-type="haveRepresentativeType"
                      :is-active-participant="isActiveParticipant(item)"
                      :special-field-configuration="specialFieldConfiguration"
                      :value="item"
                      @on-approve-or-deny-break="
                        onApproveOrDenyBreak(item, $event)
                      "
                      @on-close-session="onCloseSession(item)"
                      @on-set-turn-break="onSetTurnBreak(item)"
                      @on-set-turn-not-present="onSetTurnNotPresent(item)"
                      @on-transfer-turn="onTransferTurn(item)"
                      @on-turn-call="onTurnCall(item)"
                      @on-turn-finalize="openDeleteModal(item)"
                      @on-turn-place-on-Hold="onTurnPlaceOnHold(item)"
                    />
                  </div>
                  <div v-if="getPassiveParticipant(item.waitingRoomId) && isActiveParticipant(item) && haveRepresentativeType" class="col-12 col-md-4 pl-1">
                    <search-result
                      :has-hold-configured="result.hasHoldConfig"
                      :have-representative-type="haveRepresentativeType"
                      small
                      :special-field-configuration="specialFieldConfiguration"
                      :value="getPassiveParticipant(item.waitingRoomId)"
                      @on-approve-or-deny-break="
                        onApproveOrDenyBreak(getPassiveParticipant(item.waitingRoomId), $event)
                      "
                      @on-close-session="onCloseSession(getPassiveParticipant(item.waitingRoomId))"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="noResultsFound" class="col-md-12 mt-3">
            <CustomNotFound :text="$t('noResultsFound')" />
          </div>
        </div>
      </div>

      <custom-modal
        id="turn-call-modal"
        v-model="showTurnCallModal"
        size="sm"
        :title="$t('callTurn')"
      >
        <validation-observer ref="confirm-turn-call-form-ref" novalidate>
          <div class="row">
            <div class="col">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('row')"
                  :options="collections.turnCall.serviceQueues"
                  :value="turnCallModel.serviceQueueId"
                  @input="onTurnCallServiceQueueChanged($event)"
                />
              </validation-provider>
            </div>
          </div>
          <div class="clearfix" />
          <div class="row">
            <div class="col">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('station')"
                  :options="collections.turnCall.stations"
                  :value="turnCallModel.stationId"
                  @input="onTurnCallStationChanged($event)"
                />
              </validation-provider>
            </div>
          </div>
        </validation-observer>
        <template v-slot:footer>
          <button
            class="btn btn-primary float-right"
            type="button"
            @click="confirmTurnCall()"
          >
            <i class="fal fa-check" /> {{ $t('call') }}
          </button>
        </template>
      </custom-modal>

      <custom-modal
        id="turn-break-modal"
        v-model="showTurnBreakModal"
        size="md"
        :title="$t('takeABreak')"
      >
        <SetBreakForm
          :value="turnBreakModel"
          @close="showTurnBreakModal = false"
          @on-break-set="onBreakSet"
          :is-request-for-another-user="true"
        />
      </custom-modal>

      <custom-modal
        id="turn-transfer-modal"
        v-model="showTurnTransferModal"
        size="md"
        :title="$t('transferTurn')"
      >
        <TransferTurnForm
          :item="transferTurnModel"
          @transfer-turn="transferTurn"
        />
      </custom-modal>
      <DeleteModal
        v-model="showDeleteModal"
        error-message="turnNumberIsNotCorrect"
        :item="selectedRecord"
        label="turnNumber"
        title="areYouSureYouWantToFinalizeTheTurn"
        subtitle="toFinalizeTheTurnWriteTheWord"
        name="number"
        @on-confirm="onTurnFinalize()"
      />
    </div>
  </section>
</template>

<script>
import SearchResult from './components/SearchResult.vue';
import FilterManager from '@/components/FilterManager';
import StatusIndicatorIcon from '@/components/StatusIndicatorIcon.vue';
import contentHeader from '@/components/Header';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SetBreakForm from './components/SetBreakForm';
import TransferTurnForm from '@/components/turns/TransferTurnForm.vue';
import { mapGetters } from 'vuex';
import DeleteModal from '@/components/DeleteModal';
import sessionState from '@/mixins/sessionState';

import OverwaitingStatus from '@/constants/status/OverwaitingStatus';
import OverwaitingTypes from '@/constants/status/OverwaitingTypes';

import {
  getServiceTypes as _getServiceTypes,
  getStatusWithBreaks as _getStatusWithBreaks,
  callTurn as _callTurn,
  finalizeTurn as _finalizeTurn,
  turnPlaceOnHold as _turnPlaceOnHold,
  closeEmployeeSession as _closeEmployeeSession,
} from '@/services/EmployeeService';

import {
  getBreakTypes as _getBreakTypes,
  approveOrDenyBreak as _approveOrDenyBreak,
} from '@/services/BreakManagerService';

import { getQueueList as _getServiceQueues } from '@/services/ServicesService';

import { getStationList as _getStationsList } from '@/services/StationService';

import {
  getUserOffices as _getUserOffices,
  getDefaultStationAndServiceQueue as _getDefaultStationAndServiceQueue,
} from '@/services/UserService';

import {
  haveRepresentativeType as _haveRepresentativeType,
  getSpecialFieldConfiguration as _getSpecialFieldConfiguration,
} from '@/services/CompanyService';

import ParticipantType from '@/constants/ParticipantType';

export default {
  name: 'EmployeeStatus',
  components: {
    contentHeader,
    SetBreakForm,
    CustomModal,
    TransferTurnForm,
    FilterManager,
    StatusIndicatorIcon,
    Separator,
    SearchResult,
    CustomNotFound,
    DeleteModal,
  },
  mixins: [sessionState],
  data: () => ({
    latestSearchValues: null,
    showTurnCallModal: false,
    showTurnTransferModal: false,
    showTurnBreakModal: false,
    selectedRecord: null,
    noResultsFound: false,
    showContent: false,
    haveRepresentativeType: false,
    specialFieldConfiguration: {},
    collections: {
      locations: [],
      breakTypes: [],
      serviceQueues: [],
      serviceTypes: [],
      employeeStatus: [],
      turnCall: {
        serviceQueues: [],
        stations: [],
      },
    },
    selectedFilters: {
      locationConfigurationId: null,
      breakTypeId: 0,
      serviceQueues: [],
      serviceTypes: [],
      employeeStatusId: 0,
    },
    transferTurnModel: {},
    turnCallModel: {
      serviceQueueId: null,
      stationId: null,
    },
    turnBreakModel: null,
    result: {
      hasHoldConfig: false,
      data: [],
      stats: {
        totalAvailable: 0,
        totalBreak: 0,
        totalAttending: 0,
        totalNotAttended: 0,
        openStation: 0,
        totalOverWaiting: 0,
        totalAttended: 0,
      },
    },
    cancelInterval: null,
    showDeleteModal: false,
  }),
  computed: {
    ...mapGetters('$_user', ['profile', 'fullProfile']),
    isLocationSelected() {
      return !!this.selectedFilters.locationConfigurationId;
    },
    employees() {
      if (this.haveRepresentativeType)
        return this.result.data.filter(x => x.participantType != ParticipantType.Passive);
      return this.result.data;
    },
    filtersConfig() {
      return [
        {
          name: 'locationConfigurationId',
          label: this.$t('office'),
          component: 'LocationFilterSelect',
          options: this.collections.locations,
          clearable: false,
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-xs-12 col-sm-12 col-lg-6',
          onChanged: this.onLocationChanged,
        },
        {
          name: 'breakTypeId',
          label: this.$t('breakType'),
          component: 'FilterSelect',
          options: this.collections.breakTypes,
          disabled: !this.isLocationSelected,
          clearable: false,
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-xs-12 col-sm-12 col-lg-6',
        },
        {
          name: 'serviceQueues',
          label: this.$t('row'),
          placeholder: 'select',
          component: 'FilterSelect',
          options: this.collections.serviceQueues,
          disabled: !this.isLocationSelected,
          isMultiple: true,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-xs-12 col-sm-12 col-lg-6',
          selectAllEnabled: true,
        },
        {
          name: 'serviceTypes',
          label: this.$t('service'),
          placeholder: 'select',
          component: 'FilterSelect',
          options: this.collections.serviceTypes,
          disabled: !this.isLocationSelected,
          isMultiple: true,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-xs-12 col-sm-12 col-lg-6',
          selectAllEnabled: true,
        },
        {
          name: 'employeeStatusId',
          label: this.$t('employeeStatus'),
          component: 'FilterSelect',
          options: this.collections.employeeStatus,
          clearable: false,
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-xs-12 col-sm-12 col-lg-6',
        },
      ];
    },
  },
  methods: {
    isActiveParticipant(item) {
      return item.participantType == ParticipantType.Active && !!this.haveRepresentativeType
    },
    getPassiveParticipant(waitingRoomId) {
      return this.result.data.find(x => x.participantType == ParticipantType.Passive && x.waitingRoomId == waitingRoomId);
    },
    onLocationChanged() {
      (this.selectedFilters = {
        locationConfigurationId: this.selectedFilters.locationConfigurationId,
        breakTypeId: 0,
        serviceQueues: [],
        serviceTypes: [],
        employeeStatusId: 0,
      }),
        this.getBreakTypes(this.selectedFilters.locationConfigurationId);
      this.getServiceQueues(this.selectedFilters.locationConfigurationId);
      this.getServiceTypes(this.selectedFilters.locationConfigurationId);
    },
    async onGenerateHandler() {
      await this.getResultOfStatusWithBreaks();
      // Persist current state of this view
      this.saveSessionData(
        'registeredEmployeeStatusData',
        Object.assign({}, this.$data),
      );
    },
    async getResultOfStatusWithBreaks() {
      const payload = {
        locationConfigurationId: this.selectedFilters.locationConfigurationId,
        breakTypeId: this.selectedFilters.breakTypeId,
        serviceQueueIds: this.selectedFilters.serviceQueues,
        serviceTypeIds: this.selectedFilters.serviceTypes,
        employeeStatus: this.selectedFilters.employeeStatusId,
      };
      await this.getStatusWithBreaks(payload);
    },
    async getStatusWithBreaks(payload) {
      this.showContent = true;
      if (payload) this.latestSearchValues = payload;
      if (this.latestSearchValues) {
        await _getStatusWithBreaks(this.latestSearchValues)
          .then(({ data }) => {
            this.result.hasHoldConfig = data.hasHoldConfig;
            this.result.data = data.employeesDataWithBreakData;
            this.noResultsFound = !this.result.data.length;
            this.result.stats = data.employeesStatsData;

            clearInterval(this.cancelInterval);
            this.cancelInterval = setInterval(async () => {
              if (
                !this.showTurnCallModal &&
                !this.showTurnTransferModal &&
                !this.showTurnBreakModal
              )
                await this.getStatusWithBreaks();
            }, 60000);
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async getLocations(userId) {
      await _getUserOffices(userId)
        .then((response) => {
          this.collections.locations = response.data;

          if (response.data.length === 1) {
            this.selectedFilters.locationConfigurationId = response.data[0].id;

            this.onLocationChanged();
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getBreakTypes(locationConfigurationId) {
      await _getBreakTypes(locationConfigurationId)
        .then((response) => {
          this.collections.breakTypes = [
            { id: 0, name: this.$t('selectall') },
            ...response.data,
          ];
          this.selectedFilters.breakTypeId = 0;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getServiceQueues(locationConfigurationId) {
      await _getServiceQueues(locationConfigurationId)
        .then((response) => {
          this.collections.serviceQueues = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getServiceTypes(locationConfigurationId) {
      await _getServiceTypes(locationConfigurationId)
        .then((response) => {
          this.collections.serviceTypes = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onTurnCallServiceQueueChanged(value) {
      this.turnCallModel.serviceQueueId = value;
    },
    onTurnCallStationChanged(value) {
      this.turnCallModel.stationId = value;
    },
    async onTurnCall(item) {
      this.selectedRecord = item;

      const payload = {
        locationConfigurationId: this.selectedRecord.locationConfigurationId,
        userId: this.selectedRecord.userId,
      };

      await _getDefaultStationAndServiceQueue(payload).then(
        async (response) => {
          this.collections.turnCall.serviceQueues = [];
          this.collections.turnCall.stations = [];

          if (response.data) {
            this.turnCallModel.stationId = response.data.stationId || null;
            this.turnCallModel.serviceQueueId =
              response.data.serviceQueueId || null;
          }

          await _getServiceQueues(this.selectedRecord.locationConfigurationId)
            .then((response) => {
              this.collections.turnCall.serviceQueues = response.data;
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));

          await _getStationsList(this.selectedRecord.locationConfigurationId)
            .then((response) => {
              this.collections.turnCall.stations = response.data;
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));

          this.showTurnCallModal = true;
        },
      );
    },
    async onTurnFinalize() {
      const payload = {
        locationConfigurationId: this.selectedRecord.locationConfigurationId,
        userId: this.selectedRecord.userId,
        serviceTypeIds: this.selectedRecord.serviceTypeIds || [],
        turnId: this.selectedRecord.turnId,
        validAppointmentStamps: false,
      };
      await _finalizeTurn(payload)
        .then(async () => {
          this.ShowToast(
            this.$t('success'),
            this.$t('turnFinalizedSuccessfully'),
            'success',
          );
          this.showDeleteModal = false;
          await this.getStatusWithBreaks();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onSetTurnBreak(item) {
      this.turnBreakModel = { ...item };
      this.showTurnBreakModal = true;
    },
    onBreakSet() {
      this.showTurnBreakModal = false;
      this.onGenerateHandler();
    },
    async confirmTurnCall() {
      const isValid = await this.$refs['confirm-turn-call-form-ref'].validate();
      if (!isValid) {
        return;
      }

      await this.performTurnCall(
        this.selectedRecord.locationConfigurationId,
        this.turnCallModel.stationId,
        this.turnCallModel.serviceQueueId,
        this.selectedRecord.userId,
      );
    },
    async performTurnCall(
      locationConfigurationId,
      stationId,
      serviceQueueId,
      userId,
    ) {
      const payload = {
        locationConfigurationId: locationConfigurationId,
        stationId: stationId,
        serviceQueueId: serviceQueueId,
        userId: userId,
      };

      await _callTurn(payload)
        .then(() => {
          this.ShowToast(
            this.$t('success'),
            this.$t('turnCalledSuccessfully'),
            'success',
          );
          this.showTurnCallModal = false;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => {
          this.getStatusWithBreaks();
        });
    },
    async onSetTurnNotPresent(item) {
      this.selectedRecord = item;

      const payload = {
        locationConfigurationId: this.selectedRecord.locationConfigurationId,
        userId: this.selectedRecord.userId,
        serviceTypeIds: this.selectedRecord.serviceTypeIds || [],
        turnId: this.selectedRecord.turnId,
        noShow: true,
        comment: `Marcado no presente por ${this.profile.name}`,
      };
      await _finalizeTurn(payload)
        .then(async () => {
          this.ShowToast(
            this.$t('success'),
            this.$t('turnMarkedAsNotPresentSuccessfully'),
            'success',
          );

          await this.getStatusWithBreaks();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onCloseSession(item) {
      const { userId, locationConfigurationId } = item;

      await _closeEmployeeSession(locationConfigurationId, userId)
        .then(async () => {
          await this.getStatusWithBreaks();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onApproveOrDenyBreak(item, isApproved) {
      await _approveOrDenyBreak({
        requestedBreakId: item.requestedBreakId,
        isApproved: isApproved,
        userId: item.userId,
      })
        .then(async () => {
          await this.getStatusWithBreaks();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onTransferTurn(item) {
      this.transferTurnModel = item;

      this.showTurnTransferModal = true;
    },
    transferTurn() {
      this.getResultOfStatusWithBreaks();

      this.showTurnTransferModal = false;
    },
    async onTurnPlaceOnHold(item) {
      this.selectedRecord = item;

      await _turnPlaceOnHold(
        this.selectedRecord.turnId,
        this.selectedRecord.userId,
      )
        .then(async () => {
          await this.getStatusWithBreaks();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    goToOverwaitingReport() {
      if (
        !this.latestSearchValues ||
        !this.latestSearchValues.locationConfigurationId
      )
        return;
      this.$router.push({
        name: 'overwaitingreport',
        params: {
          locationConfigurationId:
            this.latestSearchValues.locationConfigurationId,
          status: OverwaitingStatus.Uncalled,
          type: OverwaitingTypes.Regular,
        },
      });
    },
    openDeleteModal(item) {
      this.selectedRecord = item;
      this.showDeleteModal = true;
    },
  },
  async mounted() {
    await _haveRepresentativeType(this.fullProfile.companyId).then(
      ({ data }) => {
        this.haveRepresentativeType = data;
      },
    );

    this.collections.employeeStatus = [
      { id: 0, name: this.$t('selectall') },
      { id: 2, name: this.$t('attending') },
      { id: 1, name: this.$t('available') },
      { id: 3, name: this.$t('break') },
      { id: 4, name: this.$t('logout') },
    ];

    if (this.haveRepresentativeType) {
      await _getSpecialFieldConfiguration(this.fullProfile.companyId).then(
        ({ data }) => {
          this.specialFieldConfiguration = data;
        },
      );

      this.collections.employeeStatus.splice(3, 0, {
        id: -1,
        name: this.$t('signOut'),
      });
    }

    await this.getLocations(this.profile.id);
    this.getResultOfStatusWithBreaks();
  },
  destroyed() {
    this.cancelInterval = clearInterval(this.cancelInterval);
  },
};
</script>

<style lang="scss" scoped>
.stat-icon {
  cursor: pointer;
}

.chip {
  width: 40px;
  height: 23px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #707070;
  float: left;
  margin-right: 5px;

  &.chip-grey {
    background-color: #efefef;
  }
  &.chip-blue {
    background-color: #ecf7ff;
    border-color: #75a3df;
  }
  &.chip-purple {
    background-color: #efecff;
    border-color: #ac75df;
  }
  &.chip-red {
    background-color: #fad4d4;
    border-color: #b26969;
  }
  &.chip-green {
    background-color: #f5ffeb;
    border-color: #0c8a29;
  }
}
</style>
