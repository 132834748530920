<template>
  <div class="TimePicker">
    <b-form-group class="c-timePicker" :label="title ? $t(title) + ':' : ''">
      <div class="c-s-timePicker">
        <div class="icon" :class="icon" />
        <datetime
          :key="render"
          :backdrop-click="true"
          class="theme-orange"
          format="hh:mm a"
          :hide-backdrop="false"
          :max-datetime="maxDatetime"
          :min-datetime="minDatetime"
          :minute-step="minuteStep"
          type="time"
          use12-hour
          :value="value"
          value-zone="local"
          :week-start="1"
          zone="local"
          @input="updateValueEvent"
          :disabled="disabled"
        />
      </div>
      <error
        v-if="error"
        class="mt-n1"
        :message="errorMsg"
        variant="no-border no-background"
      />
    </b-form-group>
  </div>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';
import 'vue-datetime/dist/vue-datetime.css';
import Datetime from '@/components/extensions/Datetime';

export default {
  name: 'BaseTimePicker',
  components: {
    Datetime,
    Error,
  },
  props: {
    minuteStep: {
      type: Number,
      default: () => 1,
    },
    minDatetime: {
      type: String,
      default: () => null,
    },
    maxDatetime: {
      type: String,
      default: () => null,
    },
    value: {
      type: String,
      default: () => null,
    },
    icon: {
      type: String,
      default: '',
      meta: {
        description:
          'Go down, to the css section and look for a list of available icons. Use any class identifier found there.',
      },
    },
    title: {
      type: String,
      default: () => null,
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      meta: {
        description: 'Disabled flag',
      },
    },
  },
  data() {
    return {
      render: 0,
    };
  },
  methods: {
    updateValueEvent(newTime) {
      const newTimeFormatted = this.$moment(newTime)
        .set({ second: 0 })
        .format('HH:mm:ss');

      let maxDateTime = null;
      if (this.maxDatetime) {
        const max = this.$moment(this.maxDatetime);
        maxDateTime = max.isValid() ? max.format('HH:mm:ss') : null;
      }

      let minDateTime = null;
      if (this.minDatetime) {
        const min = this.$moment(this.minDatetime);
        minDateTime = min.isValid() ? min.format('HH:mm:ss') : null;
      }

      const invalid =
        (!!maxDateTime && newTimeFormatted > maxDateTime) ||
        (!!minDateTime && newTimeFormatted < minDateTime);

      if (invalid) this.render++;
      else this.$emit('input', newTime);
    },
  },
};
</script>

<style scoped lang="scss">
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: $color-primary;
  width: 100%;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: $color-primary;
}
.c-timePicker {
  display: flex;
  .c-s-timePicker {
    border: 1px solid $color-label-default;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 40px;
    width: 100%;
    .icon {
      display: flex;
      align-items: center;
      /*ICONS IDENTIFIER*/
      &.clock {
        &:after {
          content: '\f017';
          font-family: 'Font Awesome 5 Pro', sans-serif;
          font-size: 19px;
          color: $color-primary;
          margin-right: 13px;
        }
      }
    }
    ::v-deep input {
      border: none;
      padding: 0;
    }
    .bv-no-focus-ring {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.TimePicker {
  .bv-no-focus-ring {
    width: 100%;
  }
}
</style>
