import { apiTurnos } from '@/core/api/';

export const getCancellationReasons = (statusCode) => {
    return apiTurnos.get(`/api/code/appointment/cancellationreasons/?statusCode=${statusCode}`);
}
export const getAppointmentStatuses = () => {
    return apiTurnos.get(`/api/code/appointment/status`);
}
export const getServiceType = (serviceTypeId) => apiTurnos.get(`/api/appointment/service/${serviceTypeId}`);

export const getApplicationCategories = () => apiTurnos.get(`/api/code/application/categories`);
export const getLanguages = () => apiTurnos.get(`/api/code/languages`);
