const WaitingRoomStatus = Object.freeze({
  Created: 1,
  WaitingPassive: 2,
  WaitingCitizen: 3,
  SessionInProgress: 4,
  SessionCompleted: 5,
  SessionFinished: 6,
  SessionCancelled: 7,
});

export default WaitingRoomStatus;
