import { apiTurnos } from '@/core/api/';

const qs = require('querystring');

export const getServiceTypes = (locationConfigurationId, displayHiddenServiceQueuesForReports = null) => { 
  return apiTurnos.get(`/api/employee/${locationConfigurationId}/serviceTypes/list`, {
    params: {
      displayHiddenServiceQueuesForReports
    }
  }); 
};

export const getServiceTypesByMultiLocation = (locationConfigurationIds, displayHiddenServiceQueuesForReports = null) => {
    return apiTurnos.get(`api/employee/serviceTypes/multilocation/list`,{
      params: {
        locationConfigurationIds,
        displayHiddenServiceQueuesForReports
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    });
  }

export const getStatusWithBreaks = (payload) => {
    return apiTurnos.post('/api/employee/status',  payload);
}

export const closeEmployeeSession = (locationConfigurationId, userId) =>  apiTurnos.put(`/api/employee/${locationConfigurationId}/${userId}/session/close`);

export const callTurn = (payload) =>  apiTurnos.put(`/api/employee/turn/call/next`, payload);

export const finalizeTurn = (payload) =>  apiTurnos.put(`/api/employee/turn/finalize`, payload);

export const transferTurn = payload => apiTurnos.post(`api/employee/turn/transfer`, payload);

export const turnPlaceOnHold = (turnId, userId) =>  apiTurnos.put(`/api/employee/turn/hold`, { turnId : turnId, userId : userId || null });