import { apiTurnos, apiTurnosBackground } from '@/core/api/';
import { serialize } from 'object-to-formdata';

const qs = require('querystring');

export default {
  getAvailableAppointment(
    companyId,
    locationId,
    serviceIds,
    specialistIds,
    dateStart,
    dateEnd,
    IgnorePastHours,
    timeStart = null,
    timeEnd = null,
  ) {
    var data = {
      companyId: companyId,
      locationId: locationId,
      serviceIds: serviceIds,
      specialistIds: specialistIds,
      dateStart: dateStart,
      dateEnd: dateEnd,
      timeStart: timeStart,
      timeEnd: timeEnd,
      IgnorePastHours: IgnorePastHours,
    };

    return apiTurnos.post('api/appointment/available-spaces', data);
  },
  getAvailableAppointmentMonth(
    companyId,
    locationId,
    serviceIds,
    specialistIds,
    dateStart,
    dateEnd,
    ignorePastHours,
    timeStart,
    timeEnd,
  ) {
    var data = {
      companyId: companyId,
      locationId: locationId,
      serviceIds: serviceIds,
      specialistIds: specialistIds,
      dateStart: dateStart,
      dateEnd: dateEnd,
      ignorePastHours: ignorePastHours,
      timeStart: timeStart,
      timeEnd: timeEnd,
    };

    return apiTurnos.post('api/appointment/available-spaces-month', data);
  },
  calculateSpaces(
    specialistIds,
    servicesIds,
    datesSelected,
    hoursSelected,
    duration,
  ) {
    var data = {
      specialistIds: specialistIds,
      servicesIds: servicesIds,
      datesSelected: datesSelected,
      hoursSelected: hoursSelected,
      duration: duration,
    };

    return apiTurnos.post('api/appointment/calculate-spaces', data);
  },
  saveSpaces(
    specialistIds,
    servicesIds,
    datesSelected,
    hoursSelected,
    duration,
  ) {
    var data = {
      specialistIds: specialistIds,
      servicesIds: servicesIds,
      datesSelected: datesSelected,
      hoursSelected: hoursSelected,
      duration: duration,
    };

    return apiTurnos.post('api/appointment/save-spaces', data);
  },
  updateSpace(clientAppointmentId, dateSelected, hourSelected, duration) {
    var data = {
      clientAppointmentId: clientAppointmentId,
      dateSelected: dateSelected,
      hourSelected: hourSelected,
      duration: duration,
    };

    return apiTurnos.post('api/appointment/update-space', data);
  },
  deleteSpace(clientAppointmentId) {
    return apiTurnosBackground.post(
      `api/appointment/delete-space?clientAppointmentId=${clientAppointmentId}`,
    );
  },
  getInitialFiltersData() {
    return apiTurnos.get('api/appointment/availablespaces/initfilters');
  },
};

export const verifySpacesToDelete = (payload) =>
  apiTurnos.post(`/api/appointment/delete-available-spaces/verify`, payload);

export const deleteSpaces = (payload) =>
  apiTurnos.post(`/api/appointment/delete-available-spaces`, payload);

export const verifyCoordinatedAppointmentsToCancel = (payload) =>
  apiTurnos.post(`/api/appointment/cancel-coordinated-spaces/verify`, payload);

export const cancelCoordinatedAppointments = (payload) =>
  apiTurnos.post(`/api/appointment/cancel-coordinated-spaces`, payload);

export const prereserveAppointment = (clientAppointmentId) =>
  apiTurnos.post(`api/appointment/${clientAppointmentId}/prereserve`);

export const confirmAppointment = (appointment) =>
  apiTurnos.post('api/appointment/confirm', appointment);

export const rescheduleAppointment = (appointment) => {
  const payload = { ...appointment, reschedule: true };
  return apiTurnos.post('api/appointment/confirm', payload);
};

export const getInitialFiltersData = () => {
  return apiTurnos.get('api/appointment/availablespaces/initfilters');
};

export const calculateSpaces = (
  specialistIds,
  servicesIds,
  datesSelected,
  hoursSelected,
  duration,
) => {
  var data = {
    specialistIds: specialistIds,
    servicesIds: servicesIds,
    datesSelected: datesSelected,
    hoursSelected: hoursSelected,
    duration: duration,
  };

  return apiTurnos.post('api/appointment/calculate-spaces', data);
};
export const saveSpaces = (
  specialistIds,
  servicesIds,
  datesSelected,
  hoursSelected,
  duration,
) => {
  var data = {
    specialistIds: specialistIds,
    servicesIds: servicesIds,
    datesSelected: datesSelected,
    hoursSelected: hoursSelected,
    duration: duration,
  };

  return apiTurnos.post('api/appointment/save-spaces', data);
};

export const getAppointmentStamps = (appointmentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}/getstamps`);

export const getDocuments = (appointmentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}/documents/list`);

export const getDocumentsZip = (appointmentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}/documents/download`);

export const getDocument = (appointmentId, documentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}/document/${documentId}`);

export const sendConfirmation = (appointmentId) =>
  apiTurnos.post(`/api/appointment/${appointmentId}/sendconfirmation`);

export const getAppointments = (payload) =>
  apiTurnos.get(`api/appointment`, { params: { ...payload } });

export const getAppointmentById = (appointmentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}`);

export const cancelAppointment = (appointmentId, reason, comment) => {
  var data = {
    ClientAppointmentId: appointmentId,
    Reason: reason,
    Comment: comment ? comment.trim() : null,
  };
  return apiTurnos.post('api/appointment/cancel', data);
};

export const getStamps = (appointmentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}/getstamps`);

export const getStampsInfo = (appointmentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}/getstampsinfo`);

export const validateAndGetStamps = (appointmentId) =>
  apiTurnos.get(`api/appointment/${appointmentId}/stamps/validateandget`);

export const completeAppointment = (appointmentId) => {
  var data = {
    ClientAppointmentId: appointmentId,
  };
  return apiTurnos.post('api/appointment/complete', data);
};

export const getMaxAttendeesForService = (serviceTypeId) =>
  apiTurnos.get(`api/appointment/${serviceTypeId}/attendees`);

export const getAppointmentServices = (locationConfigurationId) =>
  apiTurnos.get(`api/appointment/services/${locationConfigurationId}`);

export const getAppointmentServicesByCompanyId = (companyId) =>
  apiTurnos.get(`api/appointment/services/company/${companyId}`);

export const recoordinateListOfAppointments = (
  locationConfigurationId,
  dateFrom,
  dateTo,
  serviceTypeId,
  rowLimitCount,
) => {
  return apiTurnos.post(`api/appointment/recoordinate/list`, {
    locationConfigurationId,
    dateFrom,
    dateTo,
    serviceTypeId,
    rowLimitCount,
  });
};

export const getAvailableAppointments = (
  companyId,
  locationConfigurationId,
  appointmentNumber = null,
) =>
  apiTurnos.get('api/appointment/available', {
    params: { companyId, locationConfigurationId, appointmentNumber },
  });

export const getSpaceMassCreationTemplate = () =>
  apiTurnos.get(`api/appointment/space/masscreation/template`);

export const validateSpaceMassCreationDocument = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos.post(
    '/api/appointment/space/masscreation/validate',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const createSpacesInMass = (payload) =>
  apiTurnos.post('api/appointment/space/masscreation', payload);

export const getAvailablesSpecialistForAppointments = (payload) => {
  return apiTurnos.get(`api/appointment/specialist/list`, {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getAvailableFreeSpaces = (payload) =>
  apiTurnos.post(`api/appointment/available-free-spaces`, payload);

export const createDirectAppointment = (payload) =>
  apiTurnos.post(`api/appointment/direct`, payload);

export const validateSpecialistSpaces = (
  specialistIds,
  servicesIds,
  datesSelected,
  hoursSelected,
  duration,
) => {
  var data = {
    specialistIds: specialistIds,
    servicesIds: servicesIds,
    datesSelected: datesSelected,
    hoursSelected: hoursSelected,
    duration: duration,
  };

  return apiTurnos.post('api/appointment/specialist/space/validate', data);
};
