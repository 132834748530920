<template>
  <div
    class="col"
  >
    <div
      class="
                caption
                border-bottom
                mb-4
                text-left
                sub-heading
                pb-2
              "
    >
      <slot name="before"/>
      <span>{{ title }}</span>
      <slot name="after" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-heading {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
</style>
