<template>
  <section>
    <content-header :title="$t('realTimeRegionalDashboard')" />
    <div class="main-page-container">
      <div class="col-md-12 text-center pt-3 mb-2">
        <h1 class="date"></h1>
        <span>{{ $t('lastUpdate') }}: {{ updateDate() }}</span>
        <base-filled-button
          class="mr-0 mt-1"
          icon-class="fas fa-sync"
          :on-click="() => onSearch()"
          :text="$t('refreshReport')"
        />
      </div>
      <div class="col-md-12">
        <table
          class="table table-bordered table-responsive"
          style="
            background-color: white;
            margin-left: auto;
            margin-right: auto;
            max-width: 1492px;
          "
        >
          <thead>
            <tr>
              <th class="text-center" scope="col">
                {{ $t('localityName') }}
              </th>
              <th class="text-center" scope="col">
                {{ $t('cited') }}
              </th>
              <th class="text-center" scope="col">
                {{ $t('totalClients') }}
              </th>
              <th class="text-center" scope="col">
                {{ $t('totalAttended') }}
              </th>
              <th class="text-center" scope="col">
                {{ $t('totalUnattended') }}
              </th>
              <th class="text-center" scope="col">
                {{ $t('transactionsToAttend') }}
              </th>

              <th class="text-center" scope="col">
                {{ $t('averageWaitingMinutes') }}
              </th>
              <th class="text-center" scope="col">
                {{ $t('averageServiceTime') }}
              </th>
              <th class="text-center" scope="col">
                {{ $t('totalOfficeAverage') }}
              </th>

              <th class="text-center" scope="col">
                {{ $t('totalOpenStations') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="value.length" class="total">
              <th class="text-capitalize text-center" scope="row">
                {{ $t('total') }}
              </th>
              <td class="text-right">
                {{ total.totalCited | currency(0) }}
              </td>
              <td class="text-right">
                {{ total.totalTurn | currency(0) }}
              </td>
              <td class="text-right">
                {{ total.totalAttended | currency(0) }}
              </td>
              <td class="text-right">
                {{ total.totalUnattended | currency(0) }}
              </td>
              <td class="text-right">
                {{ total.transactionsByAttend | currency(0) }}
              </td>

              <td class="text-right">
                {{ total.waitTimeMinutes | currency }}
              </td>
              <td class="text-right">
                {{ total.turnServiceTime | currency }}
              </td>
              <td class="text-right">
                {{ total.totalInofficeTime | currency }}
              </td>

              <td class="text-right">
                {{ total.totalUserSession | currency(0) }}
              </td>
            </tr>
            <tr v-for="(item, index) in value" :key="`data-row-${index}`">
              <th scope="row">
                {{ item.locationName | currency(0) }}
              </th>
              <td class="text-right">
                {{ item.totalCited | currency(0) }}
              </td>
              <td class="text-right">
                {{ item.totalTurn | currency(0) }}
              </td>
              <td class="text-right">
                {{ item.totalAttended | currency(0) }}
              </td>
              <td class="text-right">
                {{ item.totalUnattended | currency(0) }}
              </td>
              <td class="text-right">
                {{ item.transactionsByAttend | currency(0) }}
              </td>

              <td class="text-right">
                {{ item.waitTimeMinutes | currency }}
              </td>
              <td class="text-right">
                {{ item.turnServiceTime | currency }}
              </td>
              <td class="text-right">
                {{ item.totalInofficeTime | currency }}
              </td>

              <td class="text-right">
                {{ item.totalUserSession | currency(0) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import { getRegionalRealTime as _getRegionalRealTime } from '@/services/ReportService';
import { mapGetters } from 'vuex';

export default {
  name: 'RealTimeRegional',
  components: {
    contentHeader,
  },
  data() {
    return {
      value: [],
      searchInterval: null,
      total: {
        totalCited: 0,
        totalTurn: 0,
        totalAttended: 0,
        totalUnattended: 0,
        transactionsByAttend: 0,
        waitTimeMinutes: 0,
        turnServiceTime: 0,
        totalInofficeTime: 0,
        totalUserSession: 0,
      },
    };
  },
  mounted() {
    this.onSearch();
  },
  computed: {
    ...mapGetters('$_user', ['profile']),
  },
  methods: {
    loadRegionalRealTime() {
      _getRegionalRealTime(this.profile.id).then((response) => {
        this.value = response.data;
        this.total = {
          totalCited: 0,
          totalTurn: 0,
          totalAttended: 0,
          totalUnattended: 0,
          transactionsByAttend: 0,
          waitTimeMinutes: 0,
          turnServiceTime: 0,
          totalInofficeTime: 0,
          totalUserSession: 0,
        };
        let waitTimeMinutesCounter = 0;
        let turnServiceTimeCounter = 0;
        let totalInofficeTimeCounter = 0;

        this.value.forEach((item) => {
          this.total.totalCited += item.totalCited;
          this.total.totalTurn += item.totalTurn;
          this.total.totalAttended += item.totalAttended;
          this.total.totalUnattended += item.totalUnattended;
          this.total.transactionsByAttend += item.transactionsByAttend;

          if (item.waitTimeMinutes > 0) {
            waitTimeMinutesCounter++;
            this.total.waitTimeMinutes += item.waitTimeMinutes;
          }
          if (item.turnServiceTime > 0) {
            turnServiceTimeCounter++;
            this.total.turnServiceTime += item.turnServiceTime;
          }
          if (item.totalInofficeTime > 0) {
            totalInofficeTimeCounter++;
            this.total.totalInofficeTime += item.totalInofficeTime;
          }

          this.total.totalUserSession += item.totalUserSession;
        });
        this.total.waitTimeMinutes =
          Math.abs(this.total.waitTimeMinutes) / waitTimeMinutesCounter;
        this.total.turnServiceTime =
          Math.abs(this.total.turnServiceTime) / turnServiceTimeCounter;
        this.total.totalInofficeTime =
          Math.abs(this.total.totalInofficeTime) / totalInofficeTimeCounter;
      });
    },
    updateDate() {
      const date = new Date();
      return date.toLocaleDateString('es-PR') + ' '+date.toLocaleTimeString('es-PR');
    },
    async onSearch(autoSearch = false) {
      if (!autoSearch) {
        clearTimeout(this.searchInterval);
        await this.loadRegionalRealTime();
      }

      this.searchInterval = setTimeout(async () => {
        await this.loadRegionalRealTime();
        this.updateDate();
        this.onSearch(true);
      }, 60000);
    },
  },
  destroyed() {
    clearTimeout(this.searchInterval);
  },
};
</script>

<style scoped lang="scss">
table.table {
  color: #707070;
  thead {
    background-color: #545454;
    th {
      border: 0;
      color: white;
    }
  }
  tbody {
    tr.total {
      background-color: #ffdf9f;
      th {
        background-color: #ffdf9f;
        border: 0;
      }
      td {
        border: 0;
      }
    }
    tr {
      th {
        border: 0;
        background-color: #dcdcdc;
      }
    }
  }
}
</style>
