<template>
  <div>
    <h1>Encuesta</h1>
    <office-details-map id="surveyreport" />
  </div>
</template>
<script>
import OfficeDetailsMap from '@/components/reports/Survey';

// NOTE: This view is for testing purpose. This component will be inside the dashboard view
export default {
  name: 'SurveyTest',
  components: {
    OfficeDetailsMap
  }
};
</script>
