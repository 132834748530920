<template>
  <section>
    <content-header :title="$t('regionalCFSERealTime')" />
    <div class="main-page-container">
      <div class="col-md-12 text-center pt-3 mb-2">
        <h1 class="date"></h1>
        <span>{{ $t('lastUpdate') }}: {{ updateDate() }}</span>
        <base-filled-button
          class="mr-0 mt-1"
          icon-class="fas fa-sync"
          :on-click="() => onSearch()"
          :text="$t('refreshReport')"
        />
      </div>
      
      <regional-cfse-real-time-view
                ref="regional-cfse-real-time"
                class="mb-2"
                :collapsed=true
                :locationConfigurationId=1
                :title="$t('metroRegion')"
              />
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import RegionalCfseRealTimeView from '@/components/RegionalCFSERealTimeView';
import { mapGetters } from 'vuex';

export default {
  name: 'RegionalCFSERealTime',
  components: {
    contentHeader,
    RegionalCfseRealTimeView,
  },
  data() {
    return {
      value: [],
      searchInterval: null,
    };
  },
  mounted() {
    this.onSearch();
  },
  computed: {
    ...mapGetters('$_user', ['currentActiveLocation','profile']),
  },
  watch: {
  },
  methods: {
    async loadRegionalRealTime() {
      await this.$refs['regional-cfse-real-time'].loadRegionalData();
    },
    updateDate() {
      const date = new Date();
      return date.toLocaleDateString('es-PR') + ' '+date.toLocaleTimeString('es-PR');
    },
    async onSearch(autoSearch = false) {
      if (!autoSearch) {
        clearTimeout(this.searchInterval);
        await this.loadRegionalRealTime();
      }

      this.searchInterval = setTimeout(async () => {
        await this.loadRegionalRealTime();
        this.updateDate();
        this.onSearch(true);
      }, 60000);
    },
  },
  destroyed() {
    clearTimeout(this.searchInterval);
  },
};
</script>

<style scoped lang="scss">
.collapse-container {
  background: $color-app-background;
  border-radius: 10px;
}
.acordion-title {
  text-align: left;
  opacity: 1;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  padding: 18px 10px 18px 18px;
}
table.table {
  color: #707070;
  thead {
    background-color: #545454;
    th {
      border: 0;
      color: white;
    }
  }
  tbody {
    tr.total {
      background-color: #ffdf9f;
      th {
        background-color: #ffdf9f;
        border: 0;
      }
      td {
        border: 0;
      }
    }
    tr {
      th {
        border: 0;
        background-color: #dcdcdc;
      }
    }
  }
}
</style>
