const SystemRoles = Object.freeze({
  // This role names matches the DB and wont be in camel case.
  administrator: '2bdcdea5-257a-407a-ba88-3bbc45391278',
  citas: 'adc2df69-cc38-4595-969a-95b0a8991f81',
  client: '99ec3d3f-ac4a-4aee-a561-96d4ce4e32a4',
  clientautologin: '6a82c986-e7a9-42d6-be0a-a2baee2b52f9',
  clientlist: '24bde40c-c266-478d-ac2d-4616cb4c3e89',
  clientuser: '3abfb376-9221-4cba-8a77-7de11e9cb652',
  displayuser: '4610f0ee-17e1-4c37-8267-9a730ef52737',
  localuseradministrator: 'eaad13a6-e5a4-496d-a076-0e61493e9ca9',
  salesuser: 'ef4a2991-d888-45b2-b1f5-992d65415ea7',
  sponsoruser: 'f93ab2cd-2ec1-4c16-a6f8-5bbb3e0466e1',
  systemadmin: 'b39b1ca1-c589-4847-a92b-325d065c0bc1',
  useradmin: '3159c3dc-fe3f-4c83-9399-ebb7beeb6bab',
  webuser: 'c0c531a8-6834-44c6-9814-dd384bcac4b8',
  moderator: '02AF0012-E584-488A-E862-08D9FAC3EB8D',
  monitor: '1017eb8e-8522-469a-3f39-08dc1c4cd4b6'
});

export default SystemRoles;
