<template>
  <section>
    <content-header :title="$t('peopleCited')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <report-filters
          class="mb-4"
          :search-text="$t('generate')"
          :send-stamp-transaction-id="true"
          :service-types-data-source="serviceTypesDataSource"
          :show-appointment-status="true"
          :show-hour-from="true"
          :show-hour-to="true"
          :show-service-queues="false"
          :show-specialists="true"
          :show-stamp-status="true"
          :show-sub-service-types="true"
          :show-users="false"
          :show-export="true"
          @on-export="onExport($event)"
          @on-external-display="onDisplayExternally($event)"
          @search="onSearchClick($event)"
        />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <report-viewer
            ref="report-ref"
            report-name="CitedPeople"
            export-report-name="CitedPeopleExport"
            width="100%"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import ReportViewer from '@/components/ReportViewer';
import ReportFilters from '@/components/ReportFilters';

import { getAppointmentServices as _getAppointmentServices } from '@/services/AppointmentService';

export default {
  name: 'CitedPeopleReport',
  components: {
    contentHeader,
    ReportViewer,
    ReportFilters,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async serviceTypesDataSource(locationConfigurationId) {
      const response = await _getAppointmentServices(locationConfigurationId)
        .then((response) => {
          return response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
      return response;
    },
    async onSearchClick(parameters) {
      this.$refs['report-ref'].render(parameters);
    },
    async onDisplayExternally(parameters) {
      this.$refs['report-ref'].renderExternal(parameters);
    },
    async onExport(parameters) {
      this.$refs['report-ref'].renderExport(parameters);
    },
  },
};
</script>

<style lang="scss" scoped></style>
