<template>
  <section>
    <content-header :title="$t('responsesAndTurnData')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <report-filters
          :allow-external-generation="false"
          :allow-search="false"
          class="mb-4"
          :export-text="$t('export')"
          :search-text="$t('generate')"
          :send-location-parameter="false"
          :show-answer-mode="true"
          :show-export="true"
          :show-service-queues="false"
          :show-service-types="false"
          :show-surveys="true"
          :show-users="false"
          :show-language-to-attend="true"
          @on-export="onExport($event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import ReportFilters from '@/components/ReportFilters';
import _generalApi from '@/modules/app/api.js';
import { getSurveyDataDocument as _getSurveyDataDocument } from '@/services/ReportService';

export default {
  name: 'SurveyAnswerTurnReport',
  components: {
    contentHeader,
    ReportFilters,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async onExport(parameter) {
      _getSurveyDataDocument(
        parameter.SurveyId,
        parameter.DateFrom,
        parameter.DateTo,
        parameter.AnswerMode,
      )
        .then((response) => _generalApi.ConvertToBlob(response.data))
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
