<template>
  <section>
    <content-header :title="$t('statisticsSummaryMultiOffice')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <report-filters
          class="mb-4"
          :is-multi-locations="true"
          :search-text="$t('generate')"
          :send-include-on-hold-services-parameter="true"
          :service-queues-multi-location-data-source="ServiceQueueDataSource"
          :show-days="true"
          :show-display-appointment-time="true"
          :show-display-turn-waiting-time="true"
          :show-display-waiting-queue="true"
          :show-group-by-office="true"
          :show-misc-field="true"
          :include-locations-from-service-queue-groups="true"
          :append-location-to-service-queues="true"
          :append-service-queue-ids-on-all="true"
          :show-company-misc-field9="true"
          @on-external-display="onDisplayExternally($event)"
          @on-groupbyoffice-change="onGroupByChange($event)"
          @search="onSearchClick($event)"
        />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <report-viewer
            ref="report-ref"
            :report-name="reportName"
            :width="reportWidth"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import ReportViewer from '@/components/ReportViewer';
import ReportFilters from '@/components/ReportFilters';

import { getQueueGroupByMultiLocationList as _getQueueGroupByMultiLocationList } from '@/services/ServicesService';

export default {
  name: 'StatisticsSummaryMultiOfficeReport',
  components: {
    contentHeader,
    ReportViewer,
    ReportFilters,
  },
  data() {
    return {
      reportName: 'StatisticsSummaryMultiOffice',
      reportWidth: '1110px',
    };
  },
  computed: {},
  methods: {
    async onSearchClick(parameters) {
      this.$refs['report-ref'].render(parameters);
    },
    async onDisplayExternally(parameters) {
      this.$refs['report-ref'].renderExternal(parameters);
    },
    async onGroupByChange(val) {
      if (val) {
        this.reportName = 'StatisticsSummaryMultiOfficeGrouped';
        this.reportWidth = '1280px';
      } else {
        this.reportName = 'StatisticsSummaryMultiOffice';
        this.reportWidth = '1110px';
      }
    },
    async ServiceQueueDataSource(locationConfigurationIds) {
      if (!locationConfigurationIds || !locationConfigurationIds.length)
        return [];

      let result = [];

      await _getQueueGroupByMultiLocationList(locationConfigurationIds)
        .then((response) => {
          result = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
