import { apiTurnos } from '@/core/api/';

const qs = require('querystring');

export const getSurveyList = (locationConfigurationIds) => {
  return apiTurnos.get(`api/survey/list`, {
    params: {
      locationConfigurationIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getSurveys = (payload) =>
  apiTurnos.get('/api/survey/search', { params: { ...payload } });

export const getSurveyByLocation = (locationConfigurationIds) => {
  return apiTurnos.get(`api/survey/searchByLocation`, {
    params: {
      locationConfigurationIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getTurnSurveyDetail = (turnId) =>
  apiTurnos.get(`api/survey/${turnId}/detail`);
