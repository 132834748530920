<template>
  <section>
    <content-header :title="$t('callStatisticsSummary')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <report-filters
          class="mb-4"
          :export-text="$t('export')"
          :allow-search="false"
          :allow-external-generation="false"
          :show-export="true"
          :show-misc-field="true"
          :show-company-misc-field9="true"
          @on-export="onExport($event)"
        />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <report-viewer
            ref="report-ref"
            report-name="CallStatisticsSummary"
            export-format="EXCEL"
            export-report-name="CallStatisticsSummaryExport"
            width="1008.03px"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import ReportViewer from '@/components/ReportViewer';
import ReportFilters from '@/components/ReportFilters';

export default {
  name: 'CallStatisticsSummaryReport',
  components: {
    contentHeader,
    ReportViewer,
    ReportFilters,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async onExport(parameters) {
      delete parameters.HasAllServiceQueues;
      this.$refs['report-ref'].renderExport(parameters);
    },
  },
};
</script>

<style lang="scss" scoped></style>
