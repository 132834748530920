<template>
  <b-container
    id="surveybyoperator-container"
    fluid
  >
    <b-row>
      <content-header :title="$t('surveybyoperator')" />
    </b-row>
    <div class="main-content">
      <b-row>
        <b-col>
          <div class="caption border-bottom mb-4 text-left main-content-title pb-2">
            {{ $t('filters') }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="4"
          sm="4"
        >
          <b-form-group>
            <filter-multi-select
              v-model="selectedOrganizations"
              :disabled="isOrganizationDisabled"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('organization')"
              :options="filters.organizations"
              @change="onOrganizationsChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          sm="4"
        >
          <b-form-group>
            <filter-multi-select
              v-model="selectedCompanies"
              :disabled="isCompanyDisabled"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('company')"
              :options="filters.companies"
              @change="onCompaniesChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          sm="4"
        >
          <b-form-group>
            <filter-multi-select
              v-model="selectedLocations"
              :disabled="isLocationDisabled"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('office')"
              :options="filters.locations"
              @change="onLocationsChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-form-group>
            <button
              class="btn btn-primary"
              @click="loadReport()"
            >
              {{ $t('view') }}
            </button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <powerbi-report
            :ref="id"
            datasource="PBIEncuestas"
            height="1024px"
            :params="reportParameters"
            :report-id="reportId"
            width="100%"
          />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { PBI_REPORTS } from '@/config';

import ContentHeader from '@/components/Header';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocations as _getLocations } from '@/services/LocationService';
import { getReportConfig as _getReportConfig } from '@/services/PowerBiService';
import { getInitialSurveyByOperatorFilters as _getInitialSurveyByOperatorFilters } from '@/services/ReportService';

import PowerbiReport from '@/components/PowerbiReport';

export default {
  components: {
    ContentHeader,
    PowerbiReport
  },
  props: {
    id: {
      type: String,
      default: 'survey'
    }
  },
  data () {
    return {
      showLoader: false,
      selectedOrganizations: [],
      selectedCompanies: [],
      selectedLocations: [],
      filters: {
        organizations: [],
        companies: [],
        locations: []
      }
    };
  },
  computed: {
    reportId () {
      return PBI_REPORTS.SURVEYBYOPERATOR_ID;
    },
    reportParameters () {
      const params = {};

      if (this.selectedOrganizations.length > 0) {
        params.OrganizationId = this.selectedOrganizations.map(x => x.id);
      }

      if (this.selectedCompanies.length > 0) {
        params.CompanyId = this.selectedCompanies.map(x => x.id);
      }

      if (this.selectedLocations.length > 0) {
        params.LocationId = this.selectedLocations.map(x => x.id);
      }

      return params;
    },
    selectedOrganizationIds () {
      let organizationIds = [];

      if (this.selectedOrganizations.length > 0) {
        organizationIds = this.selectedOrganizations.map(x => x.id);
      }

      return organizationIds;
    },
    selectedCompanyIds () {
      let companyIds = [];

      if (this.selectedCompanies.length > 0) {
        companyIds = this.selectedCompanies.map(x => x.id);
      }

      return companyIds;
    },
    selectedLocationIds () {
      let locationIds = [];

      if (this.selectedLocations.length > 0) {
        locationIds = this.selectedLocations.map(x => x.id);
      }

      return locationIds;
    },
    isOrganizationDisabled () {
      const result = this.selectedOrganizations.some(x => x.disabled);

      return result;
    },
    isCompanyDisabled () {
      const result = this.selectedCompanies.some(x => x.disabled);

      return result;
    },
    isLocationDisabled () {
      const result = this.selectedLocations.some(x => x.disabled);

      return result;
    }
  },
  async mounted () {
    await this.initFilters();
  },
  methods: {
    async initFilters () {
      await _getInitialSurveyByOperatorFilters().then(async (resp) => {
        this.filters.organizations = resp.data.collections.organizations;
        this.filters.companies = resp.data.collections.companies;
        this.filters.locations = resp.data.collections.locations;

        this.selectedOrganizations = resp.data.filters.organizations;
        this.selectedCompanies = resp.data.filters.companies;
        this.selectedLocations = resp.data.filters.locations;
      });
    },
    async loadCompanies () {
      await _getCompanies(this.selectedOrganizationIds).then(async (resp) => {
        this.selectedCompanies = [];
        this.selectedLocations = [];

        this.filters.companies = resp.data;
      });
    },
    async loadLocations () {
      await _getLocations(this.selectedCompanyIds).then(async (resp) => {
        this.selectedLocations = [];

        this.filters.locations = resp.data;
      });
    },
    async onOrganizationsChanged (items) {
      this.selectedOrganizations = items;

      await this.loadCompanies();
    },
    async onCompaniesChanged (items) {
      this.selectedCompanies = items;

      await this.loadLocations();
    },
    onLocationsChanged (items) {
      this.selectedLocations = items;
    },
    async loadReport () {
      await _getReportConfig(this.reportId).then((resp) => {
        const embedTokenInfo = resp.data.embedToken;
        const reportInfo = resp.data.embedReport[0];

        if (reportInfo != undefined) {
          this.$refs[this.id].render(reportInfo.reportId, embedTokenInfo.token, reportInfo.embedUrl);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
body {
  font-size: 14px;
  font: normal normal medium 14px/29px Lato;
}
#appointments-container {
  background-color: $color-app-background;
}
.main-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 11px !important;
}
.main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
.title-description {
  margin-top: 18px;
  margin-bottom: 18px;
}
.filters label {
  font: normal normal normal 14px/21px Lato;
  letter-spacing: 0px;
  color: $color-font-secondary;
  opacity: 1;
}
.select-all {
  text-decoration: underline;
}
select {
  padding: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}
select option {
  color: $color-font-primary;
  opacity: 1;
  margin: 0px;
  padding: 5px;
}

select option:selected {
  background-color: $color-primary !important;
  background: $color-primary 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/28px Lato;
  letter-spacing: 0px;
  color: $color-font-primary;
  opacity: 1;
  margin: 0px;
  padding: 5px;
}
</style>
