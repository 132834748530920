<template>
  <div
    class="p-3 card search-result"
    :class="{
      'search-result-grey': value.attended,
    }"
  >
    <div class="row">
      <div class="col-md-6 col-xl-5">
        <div class="cell">
          <label>{{ $t('number') }}: </label>
          <span>{{ value.turnNumber || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('client') }}: </label>
          <span>{{ value.turnClientName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('row') }}: </label>
          <span>{{ value.serviceQueueName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('visiting') }}: </label>
          <span>{{ value.visiting || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('comment') }}: </label>
          <span>{{ value.comment || '-' }}</span>
        </div>
      </div>
      <div class="col-md-6 col-xl-5">
        <div class="cell">
          <label>{{ $t('cited') }}: </label>
          <span>{{ appointmentDate }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('hour') }}: </label>
          <span>{{ value.arrivalTime || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('estimatedWait') }}: </label>
          <span>{{ value.waitingTime || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('waitTimeInMinutes') }}: </label>
          <span>{{ value.waitTimeMinutes || '-' }}</span>
        </div>
      </div>
      <div class="col-md-6 col-xl-2 align-self-center text-right">
        <div class="d-inline-block">
          <action-button
            :actions="actions"
            :data-item="value"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'SearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    hasHoldConfigured: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actions() {
      return [
        {
          label: this.$t('edit'),
          iconClass: 'fal fa-edit',
          iconColor: '#FF8105',
          action: (dataItem) => this.$emit('edit', dataItem.turnId),
        },
        {
          label: this.$t('removeAttended'),
          iconClass: 'fal fa-check-circle',
          iconColor: '#FF0000',
          action: (dataItem) => this.$emit('remove-attended', dataItem),
          visible: (dataItem) => dataItem.canBeReactivated,
        },
      ];
    },
    appointmentDate() {
      if(this.value.appointmentDate) {
        return this.$moment(this.value.appointmentDate).format('MM/DD/yyyy hh:mm:ss A')
      } else {
        return '-';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card.search-result {
  .cell {
    i {
      font-size: 20px;
      margin-right: 8px;
      color: #707070;
    }
  }
  &.search-result-grey {
    border: 1px solid #707070 !important;
    background-color: #efefef;
    ::v-deep .c-actionButton {
      border: 1px solid #707070 !important;
    }
  }
}
</style>
