<template>
  <div class="p-2">
    <validation-observer ref="validator">
      <validation-provider v-slot="{ errors }" rules="required">
        <filter-select
          v-model="breakTypeId"
          :clearable="false"
          :error="errors[0]"
          :error-msg="$t('mustSelectOne')"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('breakType')"
          :options="breakTypes"
          :placeholder="$t('select')"
        />
      </validation-provider>
    </validation-observer>
    <div class="row">
      <div class="col-6 mt-3">
        <base-filled-button
          bg-color="#707070"
          class="ml-0"
          icon-class="far fa-times"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />
      </div>
      <div class="col-6 text-right mt-3">
        <base-filled-button
          class="mr-0"
          icon-class="fas fa-check"
          :on-click="setBreak"
          :text="$t('confirm')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBreakTypes as _getBreakTypes,
  createBreak,
} from '@/services/BreakManagerService';

export default {
  name: 'SetBreakForm',
  data() {
    return {
      breakTypes: [],
      breakTypeId: null,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => null,
    },
    isRequestForAnotherUser: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    if (this.value.locationConfigurationId)
      this.getBreakTypes(this.value.locationConfigurationId);
  },
  methods: {
    async getBreakTypes(locationConfigurationId) {
      if (!locationConfigurationId) return;
      await _getBreakTypes(locationConfigurationId)
        .then((resp) => {
          this.breakTypes = resp.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async setBreak() {
      const isValid = await this.$refs.validator.validate();
      if (!isValid) return;

      const payload = {
        locationConfigurationId: this.value.locationConfigurationId,
        userId: this.value.userId,
        breakId: this.breakTypeId,
        isAttendingCustomer: this.value.isAttending,
        isAuthorization: false,
        isPushBreak: true,
        isRequestForAnotherUser: this.isRequestForAnotherUser
      };

      await createBreak(payload).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('on-break-set');
      });
    },
  },
};
</script>

<style scoped>
</style>
