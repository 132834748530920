<template functional>
  <div class="no-info text-center mt-4" :style="cssVar">
    <div class="row">
      <div class="col-12">
        <em :style="`color: ${props.warning ? 'red' : ''}`" :class="`${props.iconClass} no-info__icon`" />
      </div>
      <div class="col-12 mt-2">
        <span :style="`color: ${props.warning ? 'red' : ''}`" class="no-info__text">{{ props.text || 'No hay información disponible.' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CustomNotFound',
    props: {
        text: {
            type: String,
            required: true
        },
        iconClass: {
            type: String,
            default: () => 'fal fa-folder-open'
        },
        warning: {
            type: Boolean,
            default: () => false
        },
    },
    computed: {
        // cssVar () {
        //     return {
        //         '--text-color': this.textColor,
        //     };
        // },
    },
}
</script>

<style scoped lang="scss">
.no-info {
  &__icon {
    color: #c9c9c9;
    font-size: 50px;
  }
  &__text {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    color: #c9c9c9;
  }
}
</style>