<template>
  <div
    v-if="visibleActions.length > 0"
    class="c-actionButton-container"
  >
    <button
      class="c-actionButton"
      :style="`${ width ? `width: ${width};` : '' }`"
      @blur="closeDropdown"
      @click="toggleDropdown"
    >
      <span style="margin: 0 0 0 5px">{{ text }}</span>
      <i
        :class="
          isDropdownVisible
            ? 'icon far fa-chevron-up'
            : 'icon far fa-chevron-down'
        "
      />
    </button>
    <div
      v-if="isDropdownVisible"
      class="dropdown"
    >
      <div
        v-for="action in visibleActions"
        :key="action.label"
        class="dropdown-listItem"
        @click="
          () => {
            action.action(dataItem);
            toggleDropdown();
          }
        "
      >
        <i
          :class="`icon ${action.iconClass}`"
          :style="`color: ${action.iconColor}`"
        />
        <span>{{ action.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
      default: () => {},
    },
    dataItem: {
      type: [Number, Object],
      default: () => null,
    },
    width: {
      type: String
    }
  },
  data() {
    return {
      isDropdownVisible: false,
    };
  },
  computed: {
    visibleActions() {
      const dataItem = this.dataItem;
      return this.actions.filter(x => {
        if (x.visible === undefined) return true;

        if (typeof x.visible === 'function') return x.visible(dataItem);
        else return x.visible;
      });
    },
  },
  methods: {
    closeDropdown() {
      setTimeout(() => {
        this.isDropdownVisible = false;
      }, 300);
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
  },
};
</script>

<style scoped lang="scss">
.c-actionButton {
  font-family: 'lato_medium', sans-serif;
  font-size: 14px;
  color: black;
  transition: background-color 0.3s;
  background-color: var(--bg-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid $color-primary;
  appearance: none;
  &:hover {
    background-color: var(--darker-bg-color);
  }
  &:active {
    background-color: var(--active-bg-color);
  }
  &:focus {
    outline: none;
  }
  .icon {
    fill: $color-white;
    background-color: none;
    margin: 0 0 0 25px;
    font-size: 10px;
  }

  @media screen and (max-width: 989px) {
    width: 100%;
  }
}

.c-actionButton-container {
  width: 100%;
  margin-top: 5px;
}

.dropdown {
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  border: $color-label-default solid 1px;
  border-radius: 4px;
  width: max-content;
  background-color: $color-white;

  &-listItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 30px 8px 8px;
    border-bottom: $color-label-default solid 1px;
    &:hover {
      background-color: rgb(230, 230, 230);
      cursor: pointer;
    }
    &:last-child {
      border: none;
    }
    .icon {
      font-size: 18px;
      margin: 0 10px 0 0;
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
