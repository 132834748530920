<template>
  <div class="row">
    <div class="col-12 px-0">
      <div v-if="text" key="label" class="separator-custom">
        <span class="text active">{{ text }}</span>
      </div>
      <div v-else key="filter-options" class="separator-custom">
        <span
          v-for="(option, idx) in options"
          :key="option.name"
          :class="`text fixed ${option.class} ${option.active ? 'active' : ''}`"
          :data-id="`${option.id ? option.id : idx}`"
          @click="changeOption"
        >
          {{ option.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSeparator',
  props: {
    text: {
      type: String,
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changeOption(event) {
      this.$el
        .getElementsByClassName('text')
        .forEach((text) => text.classList.remove('active'));
      event.target.classList.add('active');
      this.$emit('selected', event.target.getAttribute('data-id'));
    },
  },
};
</script>

<style lang="scss" scoped>
$radius: 21px;

.separator-custom {
  display: flex;
  align-items: center;
  text-align: center;
  font: 700 17px/23px Lato;
}

.separator-custom .text:first-child {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

.separator-custom .text:last-child {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

.separator-custom .text {
  color: $color-font-secondary;
  background: $color-label-default 0% 0% no-repeat padding-box;
  padding: 5px 16px;
  cursor: pointer;
}

.separator-custom .text.active {
  color: $color-white;
  background: $color-font-secondary 0% 0% no-repeat padding-box;
}

.separator-custom::before,
.separator-custom::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #b5c3bc;
  margin-right: 15px;
  margin-left: 15px;
}

@media screen and (max-width: 480px) {
  .separator-custom {
    flex-direction: column;
  }

  .separator-custom .text {
    border-radius: 21px;
  }

  .fixed {
    width: 100%;
    margin-bottom: 2%;
  }
}
</style>
