<template>
  <section>
    <content-header :title="$t('regionalDashboard')" />
    <div class="main-page-container">
      <div class="col-md-12 text-center pt-3 mb-2">
        <h1 class="date"></h1>
        <span>{{ $t('lastUpdate') }}: {{ updateDate() }}</span>
        <base-filled-button
          class="mr-0 mt-1"
          icon-class="fas fa-sync"
          :on-click="() => onSearch()"
          :text="$t('refreshReport')"
        />
      </div>
      <div class="col-md-12 mb-2 mt-3">
        <div class="collapse-container">
          <div class="acordion-title">
            <span class="title-description"
              ><i
                class="collapse-button fal text-dark pointer"
                :class="[
                  { 'fa-plus-circle': isCollapsed },
                  { 'fa-minus-circle': !isCollapsed },
                ]"
                @click="isCollapsed = !isCollapsed"
              />
              {{ $t('islandLevel') }}</span
            >
          </div>
          <b-collapse
            id="specialist-selection-collapse"
            :visible="!isCollapsed"
          >
            <table
              class="table table-bordered table-responsive"
              style="background-color: white"
            >
              <thead>
                <tr>
                  <th class="text-center" scope="col">
                    {{ $t('localityName') }}
                  </th>
                  <th class="text-center" scope="col">
                    {{ $t('totalClients') }}
                  </th>
                  <th class="text-center" scope="col">
                    {{ $t('totalAttended') }}
                  </th>
                  <th class="text-center" scope="col">
                    {{ $t('totalUnattended') }}
                  </th>
                  <th class="text-center" scope="col">
                    {{ $t('averageWaitingMinutes') }}
                  </th>
                  <th class="text-center" scope="col">
                    {{ $t('averageServiceTime') }}
                  </th>
                  <th class="text-center" scope="col">
                    {{ $t('totalOfficeAverage') }}
                  </th>

                  <th class="text-center" scope="col">
                    {{ $t('totalOpenStations') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-capitalize text-center" scope="row">
                    {{ AAAtext }}
                  </th>
                  <td class="text-right">
                    {{ totalAAA.totalTurn | currency(0) }}
                  </td>
                  <td class="text-right">
                    {{ totalAAA.totalAttended | currency(0) }}
                  </td>
                  <td class="text-right">
                    {{ totalAAA.totalUnattended | currency(0) }}
                  </td>
                  <td class="text-right">
                    {{ totalAAA.waitTimeMinutes | currency }}
                  </td>
                  <td class="text-right">
                    {{ totalAAA.turnServiceTime | currency }}
                  </td>
                  <td class="text-right">
                    {{ totalAAA.totalInofficeTime | currency }}
                  </td>

                  <td class="text-right">
                    {{ totalAAA.totalUserSession | currency(0) }}
                  </td>
                </tr>
                <tr>
                  <th class="text-capitalize text-center" scope="row">
                    {{ ORANFtext }}
                  </th>
                  <td class="text-right">
                    {{ totalORANF.totalTurn | currency(0) }}
                  </td>
                  <td class="text-right">
                    {{ totalORANF.totalAttended | currency(0) }}
                  </td>
                  <td class="text-right">
                    {{ totalORANF.totalUnattended | currency(0) }}
                  </td>
                  <td class="text-right">
                    {{ totalORANF.waitTimeMinutes | currency }}
                  </td>
                  <td class="text-right">
                    {{ totalORANF.turnServiceTime | currency }}
                  </td>
                  <td class="text-right">
                    {{ totalORANF.totalInofficeTime | currency }}
                  </td>

                  <td class="text-right">
                    {{ totalORANF.totalUserSession | currency(0) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-collapse>
        </div>
      </div>
      <regional-dashboard-view
        ref="regional-dashboard-view-metro"
        class="mb-2"
        :collapsed="true"
        :locationConfigurationId="1"
        :title="$t('metroRegion')"
      />
      <regional-dashboard-view
        ref="regional-dashboard-view-north"
        class="mb-2"
        :collapsed="true"
        :locationConfigurationId="2"
        :title="$t('northRegion')"
      />
      <regional-dashboard-view
        ref="regional-dashboard-view-south"
        class="mb-2"
        :collapsed="true"
        :locationConfigurationId="3"
        :title="$t('southRegion')"
      />
      <regional-dashboard-view
        ref="regional-dashboard-view-east"
        class="mb-2"
        :collapsed="true"
        :locationConfigurationId="4"
        :title="$t('eastRegion')"
      />
      <regional-dashboard-view
        ref="regional-dashboard-view-west"
        class="mb-2"
        :collapsed="true"
        :locationConfigurationId="5"
        :title="$t('westRegion')"
      />
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import RegionalDashboardView from '@/components/RegionalDashboardView';
import { mapGetters } from 'vuex';

export default {
  name: 'RegionalDashboardAAA',
  components: {
    contentHeader,
    RegionalDashboardView,
  },
  data() {
    return {
      value: [],
      searchInterval: null,
      totalAAA: {
        totalTurn: 0,
        totalAttended: 0,
        totalUnattended: 0,
        waitTimeMinutes: 0,
        turnServiceTime: 0,
        totalInofficeTime: 0,
        totalUserSession: 0,
      },
      totalORANF: {
        totalTurn: 0,
        totalAttended: 0,
        totalUnattended: 0,
        waitTimeMinutes: 0,
        turnServiceTime: 0,
        totalInofficeTime: 0,
        totalUserSession: 0,
      },
      isCollapsed: this.collapsed,
      ORANFtext: 'ORANF',
      AAAtext: 'AAA',
    };
  },
  mounted() {
    this.onSearch();
    this.isCollapsed = true;
  },
  computed: {
    ...mapGetters('$_user', ['currentActiveLocation', 'profile']),
  },
  watch: {
    collapsed: function (value) {
      this.isCollapsed = value;
    },
  },
  methods: {
    async loadRegionalRealTime() {
      await this.$refs['regional-dashboard-view-metro'].loadRegionalData();
      await this.$refs['regional-dashboard-view-north'].loadRegionalData();
      await this.$refs['regional-dashboard-view-south'].loadRegionalData();
      await this.$refs['regional-dashboard-view-east'].loadRegionalData();
      await this.$refs['regional-dashboard-view-west'].loadRegionalData();

      this.totalAAA = {
        totalTurn: 0,
        totalAttended: 0,
        totalUnattended: 0,
        waitTimeMinutes: 0,
        turnServiceTime: 0,
        totalInofficeTime: 0,
        totalUserSession: 0,
      };

      this.totalORANF = {
        totalTurn: 0,
        totalAttended: 0,
        totalUnattended: 0,
        waitTimeMinutes: 0,
        turnServiceTime: 0,
        totalInofficeTime: 0,
        totalUserSession: 0,
      };
      let values = [];
      let idsORANF = [174, 175, 176, 177, 178];

      values = values.concat(this.$refs['regional-dashboard-view-metro'].value);
      values = values.concat(this.$refs['regional-dashboard-view-north'].value);
      values = values.concat(this.$refs['regional-dashboard-view-south'].value);
      values = values.concat(this.$refs['regional-dashboard-view-east'].value);
      values = values.concat(this.$refs['regional-dashboard-view-west'].value);

      let waitTimeMinutesCounterAAA = 0;
      let turnServiceTimeCounterAAA = 0;
      let totalInofficeTimeCounterAAA = 0;
      let waitTimeMinutesCounterORANF = 0;
      let turnServiceTimeCounterORANF = 0;
      let totalInofficeTimeCounterORANF = 0;

      values.forEach((item) => {
        if (idsORANF.includes(item.locationConfigurationId)) {
          this.totalORANF.totalTurn += item.totalTurn;
          this.totalORANF.totalAttended += item.totalAttended;
          this.totalORANF.totalUnattended += item.totalUnattended;

          if (item.waitTimeMinutes > 0) {
            waitTimeMinutesCounterORANF++;
            this.totalORANF.waitTimeMinutes += item.waitTimeMinutes;
          }
          if (item.turnServiceTime > 0) {
            turnServiceTimeCounterORANF++;
            this.totalORANF.turnServiceTime += item.turnServiceTime;
          }
          if (item.totalInofficeTime > 0) {
            totalInofficeTimeCounterORANF++;
            this.totalORANF.totalInofficeTime += item.totalInofficeTime;
          }

          this.totalORANF.totalUserSession += item.totalUserSession;
        } else {
          this.totalAAA.totalTurn += item.totalTurn;
          this.totalAAA.totalAttended += item.totalAttended;
          this.totalAAA.totalUnattended += item.totalUnattended;

          if (item.waitTimeMinutes > 0) {
            waitTimeMinutesCounterAAA++;
            this.totalAAA.waitTimeMinutes += item.waitTimeMinutes;
          }
          if (item.turnServiceTime > 0) {
            turnServiceTimeCounterAAA++;
            this.totalAAA.turnServiceTime += item.turnServiceTime;
          }
          if (item.totalInofficeTime > 0) {
            totalInofficeTimeCounterAAA++;
            this.totalAAA.totalInofficeTime += item.totalInofficeTime;
          }

          this.totalAAA.totalUserSession += item.totalUserSession;
        }
      });

      if (waitTimeMinutesCounterORANF > 0) {
        this.totalORANF.waitTimeMinutes =
          Math.abs(this.totalORANF.waitTimeMinutes) /
          waitTimeMinutesCounterORANF;
      }
      if (turnServiceTimeCounterORANF > 0) {
        this.totalORANF.turnServiceTime =
          Math.abs(this.totalORANF.turnServiceTime) /
          turnServiceTimeCounterORANF;
      }
      if (totalInofficeTimeCounterORANF > 0) {
        this.totalORANF.totalInofficeTime =
          Math.abs(this.totalORANF.totalInofficeTime) /
          totalInofficeTimeCounterORANF;
      }
      if (waitTimeMinutesCounterAAA > 0) {
        this.totalAAA.waitTimeMinutes =
          Math.abs(this.totalAAA.waitTimeMinutes) / waitTimeMinutesCounterAAA;
      }
      if (turnServiceTimeCounterAAA > 0) {
        this.totalAAA.turnServiceTime =
          Math.abs(this.totalAAA.turnServiceTime) / turnServiceTimeCounterAAA;
      }
      if (totalInofficeTimeCounterAAA > 0) {
        this.totalAAA.totalInofficeTime =
          Math.abs(this.totalAAA.totalInofficeTime) /
          totalInofficeTimeCounterAAA;
      }
    },
    updateDate() {
      const date = new Date();
      return (
        date.toLocaleDateString('es-PR') +
        ' ' +
        date.toLocaleTimeString('es-PR')
      );
    },
    async onSearch(autoSearch = false) {
      if (!autoSearch) {
        clearTimeout(this.searchInterval);
        await this.loadRegionalRealTime();
      }

      this.searchInterval = setTimeout(async () => {
        await this.loadRegionalRealTime();
        this.updateDate();
        this.onSearch(true);
      }, 60000);
    },
  },
  destroyed() {
    clearTimeout(this.searchInterval);
  },
};
</script>

<style scoped lang="scss">
.collapse-container {
  background: $color-app-background;
  border-radius: 10px;
}
.acordion-title {
  text-align: left;
  opacity: 1;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  padding: 18px 10px 18px 18px;
}
table.table {
  color: #707070;
  thead {
    background-color: #545454;
    th {
      border: 0;
      color: white;
    }
  }
  tbody {
    tr.total {
      background-color: #ffdf9f;
      th {
        background-color: #ffdf9f;
        border: 0;
      }
      td {
        border: 0;
      }
    }
    tr {
      th {
        border: 0;
        background-color: #dcdcdc;
      }
    }
  }
}
</style>
