<template>
  <div>
    <h1>Rendimiento de Atención</h1>
    <office-details-map id="attentionperformancereport" />
  </div>
</template>
<script>
import OfficeDetailsMap from '@/components/reports/AttentionPerformance';

// NOTE: This view is for testing purpose. This component will be inside the dashboard view
export default {
  name: 'AttentionPerformanceTest',
  components: {
    OfficeDetailsMap
  }
};
</script>
