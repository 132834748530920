<template>
  <section>
    <button
      v-if="showGoBack"
      class="btn btn-outline-primary mt-3 mr-3 float-right"
      @click="$router.go(-1)"
    >
      <i class="far fa-arrow-left" /> {{ $t('goBack') }}
    </button>
    <content-header
      :class="{ 'p-0': showGoBack }"
      :title="$t('clientsWaitingEstimatedTime')"
    />
    <div class="container-fluid">
      <div class="main-page-container">
        <filter-manager
          v-model="selectedFilters"
          class="mb-4"
          :disabled-search="!isLocationSelected"
          :filters="filtersConfig"
          :search-text="$t('generate')"
          @search="onSearch(false)"
        />
      </div>
      <div v-if="turns.length" class="row my-5">
        <div
          v-for="(item, index) in indicators"
          :key="`item-${index}`"
          class="col-xs-12 col-sm-6 col-lg-3"
        >
          <status-indicator-icon
            :count="item.count"
            :icon="item.icon"
            :title="item.title"
          />
        </div>
      </div>
      <div v-if="turns.length" class="main-page-container col-md-12 mb-5">
        <div class="row">
          <div class="col-8 mt-4 mb-2">
            <div class="row">
              <div class="col-md-4">
                <div class="chip" />
                {{ $t('pending') }}
              </div>
              <div class="col-md-4">
                <div class="chip chip-grey" />
                {{ $t('attended') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="(item, i) in turns" :key="i" class="col-md-12 mt-3">
            <search-result
              :value="item"
              @edit="editTurn($event)"
              @remove-attended="removeAttended($event)"
            />
          </div>
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <custom-modal v-model="showEditFormModal" :title="$t('editTurn')">
        <EditForm
          :attendants="filters.attendants"
          :carriers="carriers"
          :service-queues="filters.serviceQueues"
          :value="model"
          @cancel="showEditFormModal = false"
          @submitted="
            showEditFormModal = false;
            onSearch(false);
          "
        />
      </custom-modal>
    </div>
  </section>
</template>

<script>
import FilterManager from '@/components/FilterManager';
import ContentHeader from '@/components/Header';
import StatusIndicatorIcon from '@/components/StatusIndicatorIcon.vue';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import SearchResult from './components/SearchResult.vue';
import EditForm from './components/EditForm';
import { getUserAttendantsOverWaiting as _getUserAttendantsOverWaiting } from '@/services/UserService';
import { getQueueList as _getQueueList } from '@/services/ServicesService';
import {
  getTurnsOverWaiting as _getTurnsOverWaiting,
  getTurn as _getTurn,
  getTotalStatistics as _getTotalStatistics,
  isReactivateValid as _isReactivateValid,
  reactivateTurn as _reactivateTurn,
} from '@/services/TurnService';
import { getUserOffices as _getUserOffices } from '@/services/UserService';
import { getAllCarriers as _getAllCarriers } from '@/services/CarrierService';
import { modulesName } from '@/store';

export default {
  name: 'Overwaiting',
  components: {
    FilterManager,
    ContentHeader,
    StatusIndicatorIcon,
    CustomNotFound,
    SearchResult,
    CustomModal,
    EditForm,
  },
  data() {
    return {
      prevRoute: null,
      modalShow: false,
      filters: {
        locations: [],
        types: [
          { id: 1, name: this.$t('regular') },
          { id: 2, name: this.$t('cited') },
        ],
        status: [
          { id: 0, name: this.$t('statusUncalled') },
          { id: 1, name: this.$t('statusCalled') },
        ],
        serviceQueues: [],
        attendants: [],
      },
      selectedFilters: {
        locationConfigurationId: null,
        typeId: null,
        statusId: null,
        serviceQueues: [],
        userId: null,
      },
      lastSearchParams: null,
      searchInterval: null,
      turns: [],
      noResultsFound: false,
      model: {},
      showEditFormModal: false,
      carriers: [],
      stats: {
        totalAttended: 0,
        totalNotAttended: 0,
        totalClients: 0,
        totalAttendedClients: 0,
      },
    };
  },
  computed: {
    showGoBack() {
      return this.prevRoute === 'employeeStatus';
    },
    isLocationSelected() {
      return !!this.selectedFilters.locationConfigurationId;
    },
    filtersConfig() {
      return [
        {
          name: 'locationConfigurationId',
          label: this.$t('office'),
          component: 'LocationFilterSelect',
          options: this.filters.locations,
          clearable: false,
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-xs-12 col-sm-12 col-lg-6',
          onChanged: this.onLocationChange,
        },
        {
          name: 'typeId',
          label: this.$t('type'),
          component: 'FilterSelect',
          options: this.filters.types,
          disabled: !this.isLocationSelected,
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-xs-12 col-sm-12 col-lg-6',
        },
        {
          name: 'statusId',
          label: this.$t('status'),
          component: 'FilterSelect',
          options: this.filters.status,
          disabled: !this.isLocationSelected,
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-xs-12 col-sm-12 col-lg-6',
        },
        {
          name: 'serviceQueues',
          label: this.$t('row'),
          placeholder: 'select',
          component: 'FilterSelect',
          options: this.filters.serviceQueues,
          disabled: !this.isLocationSelected,
          isMultiple: true,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-xs-12 col-sm-12 col-lg-6',
          selectAllEnabled: true,
        },
        {
          name: 'userId',
          label: this.$t('visiting'),
          component: 'FilterSelect',
          options: this.filters.attendants,
          placeholder: 'all',
          disabled: !this.isLocationSelected,
          fieldtext: 'name',
          fieldvalue: 'userId',
          clearable: true,
          class: 'col-xs-12 col-sm-12 col-lg-6',
        },
      ];
    },
    overWaitingTimeCount() {
      return this.turns.reduce((prev, current) => {
        return current.waitTimeMinutes > current.waitingTime ? prev + 1 : prev;
      }, 0);
    },
    indicators() {
      return [
        {
          icon: 'fal fa-hourglass-end',
          title: this.$t('overwaiting'),
          count: this.overWaitingTimeCount,
        },
        {
          icon: 'fal fa-users',
          title: this.$t('clients'),
          count: this.stats.totalClients,
        },
        {
          icon: 'fal fa-people-arrows',
          title: this.$t('waitingClients'),
          count: this.stats.totalClients - this.stats.totalAttendedClients,
        },
        {
          icon: 'fal fa-check-double',
          title: this.$t('attendedClients'),
          count: this.stats.totalAttendedClients,
        },
      ];
    },
  },
  methods: {
    async getTotalStatistics() {
      await _getTotalStatistics(
        this.lastSearchParams.locationConfigurationId,
      ).then(({ data }) => (this.stats = data));
    },
    async getServiceQueues() {
      const { data } = await _getQueueList(
        this.selectedFilters.locationConfigurationId,
      );

      this.filters.serviceQueues = data;
      this.selectedFilters.serviceQueues = data.map((x) => x.id);
    },
    async getUserAttendants() {
      const { data } = await _getUserAttendantsOverWaiting(
        this.selectedFilters.locationConfigurationId,
      );

      this.filters.attendants = data;
    },
    async onLocationChange() {
      if (!this.selectedFilters.locationConfigurationId) return;
      this.selectedFilters.userId = null;
      this.selectedFilters.serviceQueues = [];

      await this.getUserAttendants();
      await this.getServiceQueues();
    },
    async getUserLocations() {
      const { userId } =
        this.$store.getters[`${modulesName.userModuleName}/fullProfile`];

      const { data } = await _getUserOffices(userId);

      this.filters.locations = data;

      const locationConfigurationId =
        this.$route.params.locationConfigurationId;

      if (locationConfigurationId) {
        this.selectedFilters.locationConfigurationId = locationConfigurationId;
        await this.onLocationChange();

        this.selectedFilters.statusId = this.$route.params.status;
        this.selectedFilters.typeId = this.$route.params.type;

        await this.onSearch(false);
        return;
      }

      if (data.length === 1)
        this.selectedFilters.locationConfigurationId = data[0].id;
    },
    async getCarriers() {
      await _getAllCarriers()
        .then(({ data }) => (this.carriers = data))
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getOverwaitData() {
      await _getTurnsOverWaiting(this.lastSearchParams)
        .then(({ data }) => {
          this.noResultsFound = !data.length;
          this.turns = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
      await this.getTotalStatistics();
    },
    async onSearch(autoSearch = true) {
      if (
        !this.selectedFilters.locationConfigurationId ||
        !this.selectedFilters.serviceQueues.length
      )
        return;

      if (!autoSearch) {
        this.lastSearchParams = {
          locationConfigurationId: this.selectedFilters.locationConfigurationId,
          serviceQueueIds: this.selectedFilters.serviceQueues,
          userId: this.selectedFilters.attendantId,
          statusId: this.selectedFilters.statusId,
          typeId: this.selectedFilters.typeId,
        };
        clearTimeout(this.searchInterval);
        await this.getOverwaitData();
      }

      this.searchInterval = setTimeout(async () => {
        if (!this.showEditFormModal) await this.getOverwaitData();
        this.onSearch();
      }, 60000);
    },
    async editTurn(turnId) {
      await _getTurn(turnId)
        .then(async ({ data }) => {
          this.model = {
            ...data,
            clientAppointmentId: data.clientAppointmentId?.toString(),
          };
          this.showEditFormModal = true;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async removeAttended(turn) {
      const { data: isReactivatedValid } = await _isReactivateValid(
        turn.turnNumber,
        turn.locationConfigurationId,
      );

      if (!isReactivatedValid) {
        this.ShowErrorToast(this.$t('cantreactivateturn'));

        return;
      }

      await _reactivateTurn(turn.locationConfigurationId, turn.turnId)
        .then(() => {
          this.ShowSuccessToast(
            this.$t('operationCompleted'),
            this.$t('turnReactivated'),
          );
          this.onSearch(false);
        })
        .catch((err) => this.ShowErrorToast(err.response.data.message));
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from ? from.name : null;
    });
  },
  mounted() {
    this.getCarriers();
    this.getUserLocations();
  },
  destroyed() {
    clearTimeout(this.searchInterval);
  },
};
</script>

<style lang="scss" scoped>
.chip {
  width: 40px;
  height: 23px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #707070;
  float: left;
  margin-right: 5px;

  &.chip-grey {
    background-color: #efefef;
  }
}
</style>
