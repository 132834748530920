<template>
  <div>
    <loading :active="loading" color="#F37E00" :is-full-page="false" />
    <validation-observer ref="transfer-turn-form-ref" novalidate>
      <div class="row">
        <div class="col-sm-12">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="model.serviceQueueId"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('row')"
              :options="transferTurnServicesQueues"
              @input="onTransferTurnServiceQueueChanged"
            />
          </validation-provider>
        </div>
        <div class="col-sm-12" v-if="showServiceTypes || model.serviceRequiredOnTransfer">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="model.serviceTypeCd"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('service')"
              :options="transferTurnServicesTypes"
            />
          </validation-provider>
        </div>
      </div>
      <div class="clearfix" />
      <div class="row">
        <div class="col-sm-12">
          <filter-select
            v-model="model.attendantId"
            :clearable="false"
            :disabled="!allowAttendants"
            :error-msg="$t('mustSelectOne')"
            fieldtext="name"
            fieldvalue="id"
            :label="$t('visitTo')"
            :options="userAttendants"
          />
        </div>
        <div class="col-sm-12">
          <validation-provider v-slot="{ errors }" :rules="commentRules">
            <base-input
              v-model="model.comment"
              :error="errors[0]"
              :error-msg="commentErrorMessage"
              :label="$t('comment')"
              :max-length="commentRules.max"
              :textarea="true"
            />
          </validation-provider>
        </div>
      </div>
    </validation-observer>
    <base-filled-button
      bg-color="#707070"
      class="float-left"
      icon-class="fas fa-ban"
      :on-click="onCancel"
      :text="$t('cancel')"
    />
    <base-filled-button
      class="float-right"
      icon-class="far fa-check"
      :on-click="onTransferTurn"
      :text="$t('transferTurn')"
    />
  </div>
</template>

<script>
import { modulesName } from '@/store';
import { mapState } from 'vuex';
import SystemRoles from '@/constants/SystemRoles';

import BaseInput from '@/components/BaseInput';
import FilterSelect from '@/components/FilterSelect.vue';

import { transferTurn as _transferTurn } from '@/services/EmployeeService';
import {
  getServicesTransferTurnList as _getServicesTransferTurnList,
  getServicesTypeTransferTurnList as _getServicesTypeTransferTurnList,
} from '@/services/ServicesService';

import { getAttenderList as _getAttenderList } from '@/services/UserService';

export default {
  components: {
    BaseInput,
    FilterSelect,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    attenders: {
      type: Array,
      default: () => [],
    },
    showServiceTypes: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: {
        locationConfigurationId: null,
        serviceQueueId: null,
        attendantId: null,
        serviceTypeCd: null,
        turnId: null,
        comment: '',
        serviceRequiredOnTransfer: false
      },
      transferTurnServicesQueues: [],
      transferTurnServicesTypes: [],
      userAttendants: [],
      loading: false,
      allowAttendants: true,
    };
  },
  computed: {
    ...mapState(modulesName.userModuleName, ['profile']),
    isSupervisor() {
      return this.profile.roleId === SystemRoles.client;
    },
    commentRules() {
      return {
        min: 5,
        max: 1024,
        required: false,
      };
    },
    commentErrorMessage() {
      const rules = this.commentRules;

      if (!this.model.comment) return null;

      const currentComment = this.model.comment;

      if (currentComment && currentComment.length < rules.min) {
        return this.$t('commentMinLength').replace('{0}', rules.min);
      } else if (currentComment.length >= rules.max) {
        return this.$t('commentMaxLength').replace('{0}', rules.max);
      }

      return null;
    },
  },
  async mounted() {
    this.initTransferTurnModel();

    this.model.locationConfigurationId = this.item.locationConfigurationId;

    this.model.turnId = this.item.turnId;

    await Promise.all[
      (this.fetchServicesTransferList(),
      this.fetchUserAttendants(),
      this.fetchServicesTypeTransferList())
    ];

    if (this.item.transferVisitTo && !!this.item.visitingId) {
      this.model.attendantId = this.item.visitingId;
    }

    this.setAttendatAllowed();
  },
  methods: {
    async fetchServicesTransferList() {
      const { data } = await _getServicesTransferTurnList(
        this.item.locationConfigurationId,
        this.item.serviceQueueId,
      );

      this.transferTurnServicesQueues = data;
    },
    async fetchServicesTypeTransferList() {
      const { data } = await _getServicesTypeTransferTurnList(
        this.model.locationConfigurationId,
      );

      this.transferTurnServicesTypes = data;
    },

    async fetchUserAttendants() {
      if (this.attenders.length > 0) {
        this.userAttendants = this.attenders;
      } else {
        const { data } = await _getAttenderList(
          this.model.locationConfigurationId,
        );
        this.userAttendants = data;
      }
    },
    initTransferTurnModel() {
      this.model.locationConfigurationId = null;
      this.model.serviceQueueId = null;
      this.model.attendantId = null;
      this.model.serviceTypeCd = null;
      this.model.turnId = null;
      this.model.comment = '';
    },

    async onTransferTurnServiceQueueChanged(value) {
      this.model.attendantId = null;
      this.model.serviceRequiredOnTransfer = this.transferTurnServicesQueues.filter(x => x.id == value)[0].serviceRequiredOnTransfer;
      await this.fetchServicesTypeTransferList();
      this.setAttendatAllowed();
    },
    setAttendatAllowed() {
      this.allowAttendants =
        this.isSupervisor ||
        !this.transferTurnServicesQueues.some(
          (serviceQueue) =>
            serviceQueue.id === this.model.serviceQueueId &&
            !!serviceQueue.canNotAssignOther,
        );

      const attendantId = !this.allowAttendants
        ? this.profile.userId
        : this.model.attendantId;
      this.model.attendantId = attendantId;
    },
    async onTransferTurn() {
      if (!(await this.$refs['transfer-turn-form-ref'].validate())) return;

      this.loading = true;
      const payload = {
        ...this.model,
        comment: this.item.comment || null,
        newComment: this.model.comment || null,
      };

      await _transferTurn(payload)
        .then(() => {
          this.ShowSuccessToast(this.$t('turnTransferredSuccessfully'));

          this.model.comment = '';
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => {
          this.loading = false;
          this.$emit('transfer-turn');
        });
    },
    onCancel(){
      this.$emit('close');
    }
  },
};
</script>
