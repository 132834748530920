<template>
  <section>
    <content-header :title="$t('extendedStatisticsReport')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <report-filters
          class="mb-4"
          :group-by-options="groupByOptions"
          :group-by-title="$t('displayBy')"
          :search-text="$t('generate')"
          :show-display-waiting-queue="true"
          :show-display-turn-waiting-time="true"
          :show-group-by="true"
          :show-misc-field="true"
          :show-company-misc-field9="true"
          @on-external-display="onDisplayExternally($event)"
          @on-group-change="onGroupChange($event)"
          @search="onSearchClick($event)"
        />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <report-viewer
            ref="report-ref"
            :report-name="reportName"
            width="100%"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import ReportViewer from '@/components/ReportViewer';
import ReportFilters from '@/components/ReportFilters';

export default {
  name: 'StatisticsExtendedReport',
  components: {
    contentHeader,
    ReportViewer,
    ReportFilters,
  },
  data() {
    return {
      reportName: 'StatisticsExtendedByServiceQueue',
      groupByOptions: [
        { id: 0, name: this.$t('row') },
        { id: 1, name: this.$t('rowCategories') },
        { id: 2, name: this.$t('serviceCategory') },
      ],
    };
  },
  computed: {},
  methods: {
    async onSearchClick(parameters) {
      this.$refs['report-ref'].render(parameters);
    },
    async onDisplayExternally(parameters) {
      this.$refs['report-ref'].renderExternal(parameters);
    },
    async onGroupChange(group) {
      if (group) {
        switch (group.id) {
          case 0:
            this.reportName = 'StatisticsExtendedByServiceQueue';
            break;
          case 1:
            this.reportName = 'StatisticsExtendedByServiceQueueCategory';
            break;
          case 2:
            this.reportName = 'StatisticsExtendedByServiceCategory';
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
