<template>
  <section>
    <div class="col-md-12">
      <table
        class="table table-bordered table-responsive"
        style="
          background-color: white;
          margin-left: auto;
          margin-right: auto;
          max-width: 1492px;
        "
      >
        <thead>
          <tr>
            <th class="text-center" scope="col">
              {{ $t('localityName') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('totalCustomersInjured') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('totalAttendedVideocall') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('totalClientEmployers') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('totalAttended') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('totalUnattended') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('averageWaitingMinutes') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('averageServiceTime') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('averageVideocallServiceTime') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('totalOfficeAverage') }}
            </th>
            <th class="text-center" scope="col">
              {{ $t('totalOpenStations') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="value.length" class="total">
            <th class="text-capitalize text-center" scope="row">
              {{ $t('total') }}
            </th>
            <td class="text-right">
              {{ total.totalCustomersInjured | currency(0) }}
            </td>
            <td class="text-right">
              {{ total.totalAttendedVideocall | currency(0) }}
            </td>
            <td class="text-right">
              {{ total.totalClientEmployers | currency(0) }}
            </td>
            <td class="text-right">
              {{ total.totalAttended | currency(0) }}
            </td>
            <td class="text-right">
              {{ total.totalUnattended | currency(0) }}
            </td>
            <td class="text-right">
              {{ total.waitTimeMinutes | currency }}
            </td>
            <td class="text-right">
              {{ total.turnServiceTime | currency }}
            </td>
            <td class="text-right">
              {{ total.turnoServiceTimeVideocall | currency }}
            </td>
            <td class="text-right">
              {{ total.totalInofficeTime | currency }}
            </td>
            <td class="text-right">
              {{ total.totalUserSession | currency(0) }}
            </td>
          </tr>
          <tr v-for="(item, index) in value" :key="`data-row-${index}`">
            <th scope="row">
              {{ item.locationName | currency(0) }}
            </th>
            <td class="text-right">
              {{ item.totalTurnLesionados | currency(0) }}
            </td>
            <td class="text-right">
              {{ item.totalVideocall | currency(0) }}
            </td>
            <td class="text-right">
              {{ item.totalTurnPatronos | currency(0) }}
            </td>
            <td class="text-right">
              {{ item.totalAttended | currency(0) }}
            </td>
            <td class="text-right">
              {{ item.totalUnattended | currency(0) }}
            </td>
            <td class="text-right">
              {{ item.waitTimeMinutes | currency }}
            </td>
            <td class="text-right">
              {{ item.turnServiceTime | currency }}
            </td>
            <td class="text-right">
              {{ item.turnoServiceTimeVideocall | currency }}
            </td>
            <td class="text-right">
              {{ item.totalInofficeTime | currency }}
            </td>
            <td class="text-right">
              {{ item.totalUserSession | currency(0) }}
            </td>             
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { getRegionalCFSERealTime as _getRegionalCFSERealTime} from '@/services/ReportService';
import { mapGetters } from 'vuex';

export default {
  name: 'RegionalCFSERealTimeView',
  components: {
  },
  data() {
    return {
      value: [],
      searchInterval: null,
      total: {
        totalCited: 0,
        totalCustomersInjured: 0,
        totalAttendedVideocall: 0,
        totalClientEmployers: 0,
        totalAttended: 0,
        totalUnattended: 0,
        transactionsByAttend: 0,
        waitTimeMinutes: 0,
        turnServiceTime: 0,
        turnoServiceTimeVideocall: 0,
        totalInofficeTime: 0,
        totalUserSession: 0,
      },
      isCollapsed: this.collapsed,
    };
  },
  mounted() {
    this.loadRegionalData();
    //this.isCollapsed = true;
  },
  props: {
    id: {
      type: String,
      default: () => 'regional-cfse-real-time-view',
    }, 
    title: {
      type: String,
      default: ''
    },
    collapsed: {
      type: Boolean,
      default: () => true
    },
    locationConfigurationId: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters('$_user', ['currentActiveLocation','profile']),
  },
  watch: {
    collapsed: function (value) {
      this.isCollapsed = value;
    }
  },
  methods: {
    async loadRegionalData() {
      await _getRegionalCFSERealTime(this.profile.id).then((response) => {
        this.value = response.data;
        this.total = {
          totalCited: 0,
          totalCustomersInjured: 0,
          totalAttendedVideocall: 0,
          totalClientEmployers: 0,
          totalAttended: 0,
          totalUnattended: 0,
          transactionsByAttend: 0,
          waitTimeMinutes: 0,
          turnServiceTime: 0,
          turnoServiceTimeVideocall: 0,
          totalInofficeTime: 0,
          totalUserSession: 0,
        };
        let waitTimeMinutesCounter = 0;
        let turnServiceTimeCounter = 0;
        let totalInofficeTimeCounter = 0;
        let turnoServiceTimeVideocallCounter = 0;

        this.value.forEach((item) => {
          this.total.totalCited += item.totalCited;
          this.total.totalCustomersInjured += item.totalTurnLesionados;
          this.total.totalAttendedVideocall += item.totalVideocall;
          this.total.totalClientEmployers += item.totalTurnPatronos;
          this.total.totalAttended += item.totalAttended;
          this.total.totalUnattended += item.totalUnattended;
          this.total.transactionsByAttend += item.transactionsByAttend;

          if (item.waitTimeMinutes > 0) {
            waitTimeMinutesCounter++;
            this.total.waitTimeMinutes += item.waitTimeMinutes;
          }
          if (item.turnServiceTime > 0) {
            turnServiceTimeCounter++;
            this.total.turnServiceTime += item.turnServiceTime;
          }
          if (item.totalInofficeTime > 0) {
            totalInofficeTimeCounter++;
            this.total.totalInofficeTime += item.totalInofficeTime;
          }

          if (item.turnoServiceTimeVideocall > 0) {
            turnoServiceTimeVideocallCounter++;
            this.total.turnoServiceTimeVideocall += item.turnoServiceTimeVideocall;
          }

          this.total.totalUserSession += item.totalUserSession;
        });
        this.total.waitTimeMinutes =
          Math.abs(this.total.waitTimeMinutes) / waitTimeMinutesCounter;
        this.total.turnServiceTime =
          Math.abs(this.total.turnServiceTime) / turnServiceTimeCounter;
        this.total.totalInofficeTime =
          Math.abs(this.total.totalInofficeTime) / totalInofficeTimeCounter;
        this.total.turnoServiceTimeVideocall =
          Math.abs(this.total.turnoServiceTimeVideocall) / turnoServiceTimeVideocallCounter;
      });
    },
  },
  destroyed() {
    clearTimeout(this.searchInterval);
  },
};
</script>

<style scoped lang="scss">
.collapse-container {
  background: $color-app-background;
  border-radius: 10px;
}
.acordion-title {
  text-align: left;
  opacity: 1;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  padding: 18px 10px 18px 18px;
}
table.table {
  color: #707070;
  thead {
    background-color: #545454;
    th {
      border: 0;
      color: white;
    }
  }
  tbody {
    tr.total {
      background-color: #ffdf9f;
      th {
        background-color: #ffdf9f;
        border: 0;
      }
      td {
        border: 0;
      }
    }
    tr {
      th {
        border: 0;
        background-color: #dcdcdc;
      }
    }
  }
}
</style>
