<template>
  <div
    class="p-3 card search-result"
    :class="{
      'search-result-blue': value.approveOrDenyBreak,
      'search-result-purple': value.isAttending && closingSection,
      'search-result-grey': value.isAttending && !closingSection,
      'search-result-red': !!value.breakTimeOver,
      'search-result-green':
        value.isOnBreak && !value.approveOrDenyBreak && !value.breakTimeOver,
      'small': isActiveParticipant || small
    }"
  >
    <div class="row">
      <div :class="{ 'col-md-6 col-xl-3': !small, 'col-md-12': small }">
        <div class="cell">
          <label>{{ $t('userName') }}: </label>
          <span>{{ value.userName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('role') }}: </label>
          <span>{{ value.roleName }} {{ representativeCompanionType }}</span>
        </div>
        <div v-if="haveRepresentativeType" class="cell">
          <label>{{ specialFieldConfiguration.specialField3Name }}: </label>
          <span>{{ specialField3 || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('lastActivityDate') }}: </label>
          <span>{{ lastActivityDate }}</span>
        </div>
        <div v-if="!small" class="cell">
          <label>{{ $t('lastStation') }}: </label>
          <span>{{ value.lastStation || '-' }}</span>
        </div>
        <div v-if="!small" class="cell">
          <label>{{ $t('lastRow') }}: </label>
          <span>{{ value.lastRow || '-' }}</span>
        </div>
        <div v-if="small" class="cell">
          <label class="mr-2">{{ $t('approveOrDenyBreak') }}: </label>
          <div v-if="value.approveOrDenyBreak">
            <i
              class="fa fa-thumbs-up cursor-pointer"
              @click="$emit('on-approve-or-deny-break', true)"
            />
            <i
              class="fa fa-thumbs-down cursor-pointer"
              @click="$emit('on-approve-or-deny-break', false)"
            />
          </div>
          <div v-else>
            <span>{{ '-' }}</span>
          </div>
        </div>
        <div class="cell" v-if="value.reasonComment">
          <label>{{ $t('breakComment') }}: </label>
          <span>{{ value.reasonComment }}</span>
        </div>
      </div>
      <div v-if="!small" class="col-md-6 col-xl-3">
        <div v-if="haveRepresentativeType && waitingRoomStatus" class="cell">
          <label>{{ $t('roomStatus') }}: </label>
          <span>{{ waitingRoomStatus }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('services') }}: </label>
          <span>{{ value.waitingRoomServices || value.serviceTypeNames || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('number') }}: </label>
          <span>{{ value.waitingRoomTurnNumber || value.number || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('status') }}: </label>
          <span>{{ value.status || '-' }} {{ status }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('statusTimeMin') }}: </label>
          <span>{{ value.statusTimeMin || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('type') }}: </label>
          <span>{{ value.type || '-' }}</span>
        </div>
      </div>
      <div v-if="!small" class="col-md-6 col-xl-3">
        <div class="cell">
          <label class="mr-2">{{ $t('approveOrDenyBreak') }}: </label>
          <div v-if="value.approveOrDenyBreak">
            <i
              class="fa fa-thumbs-up cursor-pointer"
              @click="$emit('on-approve-or-deny-break', true)"
            />
            <i
              class="fa fa-thumbs-down cursor-pointer"
              @click="$emit('on-approve-or-deny-break', false)"
            />
          </div>
          <div v-else>
            <span>{{ '-' }}</span>
          </div>
        </div>
        <div class="cell">
          <label>{{ $t('beginningOfBreak') }}: </label>
          <span>{{ beginningOfBreak }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('timeOfBreak') }}: </label>
          <span>{{ value.timeOfBreak || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('maximumTime') }}: </label>
          <span>{{ value.maximumTime || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('timeOverBreak') }}: </label>
          <span>{{ value.breakTimeOver || '-' }}</span>
        </div>
      </div>
      <div v-if="!small" class="col-md-6 col-xl-3 align-self-center">
        <action-button
          :actions="actions"
          class="d-flex justify-content-center"
          :text="$t('actions')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import IdentityPermission from '@/constants/IdentityPermission';
import EmployeeStatus from '@/constants/status/EmployeeStatus';
import SystemRoles from '@/constants/SystemRoles';
import ParticipantType from '@/constants/ParticipantType';
import WaitingRoomStatus from '@/constants/status/WaitingRoomStatus';
import RepresentativeCompanionType from '@/constants/RepresentativeCompanionType';
import { addToWaitingRoom as _addToWaitingRoom } from '@/services/WaitingRoomService';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    specialFieldConfiguration: {
      type: Object,
      default: () => ({}),
    },
    hasHoldConfigured: {
      type: Boolean,
      default: false,
    },
    haveRepresentativeType: {
      type: Boolean,
      default: false,
    },
    isActiveParticipant: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['profile']),
    closingSection() {
      return this.value.waitingRoomStatus == WaitingRoomStatus.SessionCompleted;
    },
    lastActivityDate() {
      let result = '-';
      if (this.value.lastActivityDate) {
        result = new Date(this.value.lastActivityDate).toLocaleString();
      }

      return result;
    },
    beginningOfBreak() {
      let result = '-';
      if (this.value.beginningOfBreak) {
        result = new Date(this.value.beginningOfBreak).toLocaleString();
      }

      return result;
    },
    showActions() {
      if (
        this.value.roleId == SystemRoles.client ||
        this.value.roleId == SystemRoles.monitor
      )
        return false;
      return true;
    },
    specialField3() {
      return this.specialFieldConfiguration.specialField3Options
        ?.filter((x) => this.value.specialField3Ids.find((m) => m == x.id))
        .map((x) => x.name)
        .join(', ');
    },
    representativeCompanionType() {
      let result = '';
      if (!this.haveRepresentativeType) return result;
      switch (this.value.representativeTypeId) {
        case RepresentativeCompanionType.Active:
          result = this.$t('active');
          break;
        case RepresentativeCompanionType.Passive:
          result = this.$t('passive');
          break;
      }

      return !result ? '' : `(${result})`;
    },
    waitingRoomStatus() {
      let result = '';
      switch (this.value.waitingRoomStatus) {
        case WaitingRoomStatus.Created:
          result = this.$t('created');
          break;
        case WaitingRoomStatus.WaitingPassive:
          result = this.$t('waitingPassive');
          break;
        case WaitingRoomStatus.WaitingCitizen:
          result = this.$t('waitingCitizen');
          break;
        case WaitingRoomStatus.SessionInProgress:
          result = this.$t('inProgress');
          break;
        case WaitingRoomStatus.SessionCompleted:
          result = this.$t('signOut');
          break;
      }

      return result;
    },
    status() {
      let result = '';
      if (this.haveRepresentativeType && this.value.participantType) {
        switch (this.value.participantType) {
          case ParticipantType.Monitor:
            result = this.$t('monitor');
            break;
          case ParticipantType.Supervisor:
            result = this.$t('supervisor');
            break;
        }
      }

      return result ? `como (${result})` : ''
    },
    isPassiveParticipant() {
      return this.value.participantType == ParticipantType.Passive;
    },
    actions() {
      const options = [
        {
          label: this.$t('getInto'),
          iconClass: 'fas fa-desktop',
          iconColor: '#399C21',
          action: this.getInto,
          visible: () =>
            !!this.value.waitingRoomId && this.haveRepresentativeType,
        },
        {
          label: this.$t('technicalSetup'),
          iconClass: 'far fa-cogs',
          iconColor: '#FF8105',
          visible: () => this.showActions,
          action: () => {
            this.$router.push({
              name: 'technicianSetup',
              params: {
                userId: this.value.userId,
              },
            });
          },
        },
        {
          label: this.$t('closeTechnicianSection'),
          iconClass: 'far fa-sign-out',
          iconColor: '#5C0C8A',
          action: async () => this.$emit('on-close-session'),
          visible: () =>
            this.showActions &&
            (this.value.status == EmployeeStatus.Available ||
              (this.value.status != EmployeeStatus.Logout &&
                (this.value.isOnBreak ||
                  this.value.status == EmployeeStatus.Login))),
        },
        {
          label: this.$t('callTurn'),
          iconClass: 'far fa-plus',
          iconColor: '#0C8A29',
          action: () => this.$emit('on-turn-call'),
          visible: () => this.value.isAvailable && this.showActions && !this.isPassiveParticipant,
        },
        {
          label: this.$t('endTurn'),
          iconClass: 'far fa-check',
          iconColor: '#F700FF',
          action: () => this.$emit('on-turn-finalize'),
          visible: () => this.value.isAttending && this.showActions && !this.isPassiveParticipant,
        },
        {
          label: this.$t('transferTurn'),
          iconClass: 'far fa-arrows-alt',
          iconColor: '#006FFF',
          action: () => this.$emit('on-transfer-turn'),
          visible: () => this.value.isAttending && this.showActions && !this.isPassiveParticipant,
        },
        {
          label: this.$t('placeInHold'),
          iconClass: 'far fa-stop-circle',
          iconColor: '#FF0000',
          action: () => {
            this.$emit('on-turn-place-on-Hold');
          },
          visible: () =>
            this.value.isAttending &&
            this.hasHoldConfigured &&
            this.showActions && !this.isPassiveParticipant,
        },
        {
          label: this.$t('notPresent'),
          iconClass: 'far fa-shoe-prints',
          iconColor: '#707070',
          visible: () => {
            return (
              this.showActions && !this.isPassiveParticipant &&
              this.value.isAttending &&
              this.userHasPermissions(
                IdentityPermission.maintenance.employeeStatus.notPresent,
              )
            );
          },
          action: () => this.$emit('on-set-turn-not-present'),
        },
        {
          label: this.$t('placeInRecess'),
          iconClass: 'far fa-pause',
          iconColor: '#27A8AC',
          visible: () => !this.value.isOnBreak && this.showActions,
          action: () => this.$emit('on-set-turn-break'),
        },
      ];

      return options;
    },
  },
  methods: {
    getInto() {
      _addToWaitingRoom({
        userId: this.profile.id,
        waitingRoomId: this.value.waitingRoomId,
        participantType: ParticipantType.Supervisor,
      })
        .then(() => {
          this.$router.push({ name: 'turnsManager' });
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>

<style lang="scss" scoped>
.card.search-result {
  height: 100%;
  &.small {
    .cell {
      label,
      span {
        font-size: 14px !important;
      }
    }
  }
  .cell {
    i {
      font-size: 20px;
      margin-right: 8px;
      color: #707070;
    }
  }
  &.search-result-blue {
    border: 1px solid #75a3df !important;
    background-color: #ecf7ff;
    ::v-deep .c-actionButton {
      border: 1px solid #75a3df !important;
    }
  }
  &.search-result-purple {
    border: 1px solid #ac75df !important;
    background-color: #efecff;
    ::v-deep .c-actionButton {
      border: 1px solid #ac75df !important;
    }
  }
  &.search-result-red {
    border: 1px solid #b26969 !important;
    background-color: #fad4d4;
    ::v-deep .c-actionButton {
      border: 1px solid #b26969 !important;
    }
  }
  &.search-result-grey {
    border: 1px solid #707070 !important;
    background-color: #efefef;
    ::v-deep .c-actionButton {
      border: 1px solid #707070 !important;
    }
  }
  &.search-result-green {
    border: 1px solid #0c8a29 !important;
    background-color: #f5ffeb;
    ::v-deep .c-actionButton {
      border: 1px solid #0c8a29 !important;
    }
  }
  ::v-deep .dropdown {
    margin-top: 37px;
  }
}
</style>
