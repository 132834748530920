<template>
  <div>
    <div class="row">
      <sub-header :title="$t('filter')" />
    </div>
    <div class="pb-1 filtermanager">
      <validation-observer ref="formValidator" novalidate>
        <div class="row filters-container">
          <!-- Filters Column -->
          <div v-if="showDateFrom" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <base-datetime-picker
              format="dd/MMM/yyyy"
              name="fromDate"
              :title="$t('fromDate')"
              :min-datetime="minimumDate"
              :max-datetime="maximumDate"
              type="date"
              :value="selectedFilters.dateFrom"
              @change="onDateFromChange($event)"
              :clearable="false"
            />
          </div>
          <div v-if="showHourFrom" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <base-time-picker
              v-model="selectedFilters.hourFrom"
              icon="clock"
              :minute-step="1"
              :title="$t('timeFrom')"
            />
          </div>
          <div v-if="showDateTo" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <base-datetime-picker
              format="dd/MMM/yyyy"
              name="toDate"
              :title="$t('toDate')"
              :min-datetime="minimumDate"
              :max-datetime="maximumDate"
              type="date"
              :value="selectedFilters.dateTo"
              @change="onDateToChange($event)"
              :clearable="false"
            />
          </div>
          <div v-if="showHourTo" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <base-time-picker
              v-model="selectedFilters.hourTo"
              icon="clock"
              :minute-step="1"
              :title="$t('timeTo')"
            />
          </div>
          <div v-if="showLocations" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <validation-provider v-slot="{ errors }" rules="required">
              <location-filter-select
                v-if="!isMultiLocations"
                v-model="selectedFilters.locationConfigurationId"
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('office')"
                :options="availableLocations || []"
                :placeholder="$t('select')"
                @on-selection-changed="onLocationChange($event)"
              />

              <filter-select
                v-if="isMultiLocations"
                v-model="selectedFilters.locations"
                :error="errors[0]"
                :error-msg="$t('mustSelectAtLeastOne')"
                fieldtext="name"
                fieldvalue="id"
                :group-when-all-selected="false"
                :is-multiple="true"
                :label="$t('offices')"
                :options="availableLocations || []"
                :placeholder="$t('select')"
                :return-object="true"
                @change="onLocationsChange($event)"
              />
            </validation-provider>
          </div>
          <div
            v-if="showRepresentativeCompanion"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <filter-select
              v-model="selectedFilters.representativeCompanion"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('type')"
              :options="availableRepresentativeCompanions || []"
              :placeholder="$t('select')"
              :return-object="true"
              @change="onRepresentativeCompanionChange($event)"
            />
          </div>
          <div
            v-if="showParticipantType"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <filter-select
              v-model="selectedFilters.participantType"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('type')"
              :options="availableParticipantTypes || []"
              :placeholder="$t('select')"
              :return-object="true"
              @change="onParticipantTypeChange($event)"
            />
          </div>
          <div v-if="showMiscField" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <!-- ExtraField3 -->
            <filter-select
              v-model="selectedFilters.miscField"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('extraField')"
              :options="availableMiscFields || []"
              :placeholder="$t('select')"
              :return-object="true"
              @change="onMiscFieldChange($event)"
            />
          </div>
          <div
            v-if="showAppointmentStatus"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <filter-multi-select
              fieldtext="name"
              fieldvalue="id"
              :is-multiple="true"
              :label="$t('status')"
              :options="availableAppointmentStatus"
              :placeholder="`-- ${$t('all')} --`"
              :value="selectedFilters.appointmentStatus"
              @change="onAppointmentStatusChange($event)"
            />
          </div>
          <div v-if="showStampStatus" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <filter-multi-select
              fieldtext="name"
              fieldvalue="id"
              :is-multiple="true"
              :label="$t('stampStatus')"
              :options="availableStampStatus"
              :placeholder="`-- ${$t('all')} --`"
              :value="selectedFilters.stampStatus"
              @change="onStampStatusChange($event)"
            />
          </div>
          <div
            v-if="
              showCompanyMiscField9 && availableCompanyMiscField9.length > 0
            "
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <!-- Company ExtraField9 -->
            <filter-list-box
              id="availableCompanyMiscField9"
              fieldtext="name"
              fieldvalue="id"
              :is-multiple="true"
              :label="$t('UserCategory')"
              :options="availableCompanyMiscField9 || []"
              :placeholder="`-- ${$t('select')} --`"
              :value="selectedFilters.companyMiscField9"
              @change="onCompanyMiscField9Change"
            />
          </div>
          <div v-if="showUsers" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <!-- <filter-multi-select
              fieldtext="name"
              fieldvalue="id"
              :is-multiple="true"
              :label="$t('users')"
              :options="availableUsers"
              :placeholder="`-- ${$t('all')} --`"
              :value="selectedFilters.users"
              @change="onUserChange($event)"
            /> -->
            <validation-provider
              v-slot="{ errors }"
              :rules="availableUsers.length > 0 ? 'required' : ''"
            >
              <filter-list-box
                id="availableUsers"
                :error="errors[0]"
                :error-msg="$t('mustSelectAtLeastOne')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('users')"
                :options="filteredUsers"
                :placeholder="`-- ${$t('select')} --`"
                :value="selectedFilters.users"
                @change="onUserChange"
              />
            </validation-provider>
          </div>
          <div
            v-if="showServiceQueues"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <!-- <filter-multi-select
              v-if="!serviceQueuesListBox"
              fieldtext="name"
              fieldvalue="id"
              :is-multiple="true"
              :label="$t('row')"
              :options="availableServiceQueues"
              :placeholder="`-- ${$t('all')} --`"
              :value="selectedFilters.serviceQueues"
              @change="onServiceQueueChange($event)"
            /> -->
            <validation-provider
              v-slot="{ errors }"
              :rules="availableServiceQueues.length > 0 ? 'required' : ''"
            >
              <filter-list-box
                id="availableServiceQueues"
                :error="errors[0]"
                :error-msg="$t('mustSelectAtLeastOne')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('row')"
                :options="availableServiceQueues"
                :placeholder="`-- ${$t('select')} --`"
                :value="selectedFilters.serviceQueues"
                @change="onServiceQueueChange"
              />
            </validation-provider>
          </div>
          <div v-if="showServiceTypes" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <validation-provider
              v-slot="{ errors }"
              :rules="availableServiceTypes.length > 0 ? 'required' : ''"
            >
              <filter-list-box
                id="availableServiceTypes"
                :error="errors[0]"
                :error-msg="$t('mustSelectAtLeastOne')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('service')"
                :options="availableServiceTypes"
                :placeholder="`-- ${$t('select')} --`"
                :value="selectedFilters.serviceTypes"
                @change="onServiceTypeChange"
              />
            </validation-provider>
          </div>
          <div
            v-if="showSubServiceTypes && hasSubServices"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <filter-list-box
              id="availableSubServices"
              fieldtext="name"
              fieldvalue="id"
              :is-multiple="true"
              :label="$t('subService')"
              :options="availableSubServices"
              :placeholder="`-- ${$t('select')} --`"
              :value="selectedFilters.subServices"
              @change="onSubServicesChange"
            />
          </div>
          <div v-if="showSpecialists" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <!-- <filter-multi-select
              fieldtext="name"
              fieldvalue="id"
              :is-multiple="true"
              :label="$t('specialists')"
              :options="availableSpecialists"
              :placeholder="`-- ${$t('all')} --`"
              :value="selectedFilters.specialists"
              @change="onSpecialistsChange($event)"
            /> -->

            <validation-provider
              v-slot="{ errors }"
              :rules="availableSpecialists.length > 0 ? 'required' : ''"
            >
              <filter-list-box
                id="availableSpecialists"
                :error="errors[0]"
                :error-msg="$t('mustSelectAtLeastOne')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('specialists')"
                :options="availableSpecialists"
                :placeholder="`-- ${$t('select')} --`"
                :value="selectedFilters.specialists"
                @change="onSpecialistsChange"
              />
            </validation-provider>
          </div>
          <div
            v-if="showAppointmentNumber"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <label>{{ $t('appointmentNumber') }}</label>
            <base-input v-model="selectedFilters.appointmentNumber" />
          </div>
          <div v-if="showStations" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <filter-select
              v-model="selectedFilters.station"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('station')"
              :options="availableStations || []"
              :placeholder="$t('select')"
              :return-object="true"
            />
          </div>
          <div v-if="showTimeFormat" class="col-sm-12 col-md-8 col-lg-3 mb-2">
            <filter-select
              v-model="selectedFilters.timeFormat"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('format')"
              :options="availableTimeFormats || []"
              :placeholder="$t('select')"
            />
          </div>

          <div
            v-if="showRowLimitCount"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <validation-provider v-slot="{ errors }" rules="required">
              <filter-numeric
                v-model="selectedFilters.rowLimitCount"
                :error="errors[0]"
                :error-msg="$t('requiredField')"
                :max="1000"
                :min="1"
                title="recordsLimit"
              />
            </validation-provider>
          </div>

          <div
            v-if="showFilterVideoCalls"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <filter-select
              v-model="selectedFilters.filterVideoCalls"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('videoCall')"
              :options="availableVideoCallFilters"
            />
          </div>
          <div v-if="showGroupBy" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <validation-provider v-slot="{ errors }" rules="required">
              <filter-select
                v-model="selectedFilters.group"
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
                fieldtext="name"
                fieldvalue="id"
                :label="groupByTitle"
                :options="groupByOptions"
                @change="onGroupByChange($event)"
              />
            </validation-provider>
          </div>
          <div v-if="showSurveys" class="col-sm-12 col-md-8 col-lg-3 mb-2">
            <validation-provider v-slot="{ errors }" rules="required">
              <filter-select
                v-model="selectedFilters.survey"
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('survey')"
                :options="availableSurveys"
                :placeholder="$t('select')"
                :return-object="true"
                @change="onSurveyChange($event)"
              />
            </validation-provider>
          </div>
          <div v-if="showAnswerMode" class="col-sm-12 col-md-6 col-lg-3 mb-2">
            <validation-provider v-slot="{ errors }" rules="required">
              <filter-select
                v-model="selectedFilters.answerMode"
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('answers')"
                :options="availableAnswerModes"
                :return-object="true"
                @change="onAnswerModeChange($event)"
              />
            </validation-provider>
          </div>

          <div
            v-if="showLanguageToAttend && availableLanguagesToAttend.length > 0"
            class="col-sm-12 col-md-6 col-lg-3 mb-2"
          >
            <filter-select
              v-model="selectedFilters.languageToAttend"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('attentionlanguage')"
              :options="availableLanguagesToAttend"
              :return-object="true"
              @change="onLanguageToAttendChange($event)"
            />
          </div>

          <div v-if="showDays" class="col col-sm-12 col-md-12 col-lg-6 mb-2">
            <b-form-group>
              <label class="col-form-label mr-1 pt-0"> {{ $t('days') }}:</label>
              <base-checkbox-group
                id="checkbox-group-1"
                v-model="selectedFilters.days"
                name="flavour-1"
                :options="availableWeekDays"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-sm-12 col-md-2">
            <b-form-checkbox
              v-if="showDisplayWaitingQueue"
              v-model="selectedFilters.displayWaitingQueue"
              class="c-s-filterLabel"
              switch
            >
              {{ $t('includeWaitingQueues') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-if="showDisplayTurnWaitingTime"
              v-model="selectedFilters.displayTurnWaitingTime"
              class="c-s-filterLabel"
              switch
            >
              {{ $t('considerTimes') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-if="showDisplayAppointmentTime"
              v-model="selectedFilters.displayAppointmentTime"
              class="c-s-filterLabel"
              switch
            >
              {{ $t('considerAppointmentDate') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-if="showGroupByOffice"
              v-model="selectedFilters.groupByOffice"
              class="c-s-filterLabel"
              switch
              @input="onGroupByOfficeChange"
            >
              {{ $t('groupByOffice') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selectedFilters.historic"
              class="c-s-filterLabel"
              @input="onHistoricDataChange"
              switch
            >
              <span class="mt-1">{{ $t('displayHistoricData') }}</span>
            </b-form-checkbox>
          </div>
          <div class="col text-right">
            <button
              v-if="showExport && !isExportHidden"
              class="btn btn-danger ml-3"
              @click="onExport()"
            >
              <i class="far fa-download" /> {{ this.exportText }}
            </button>

            <button
              v-if="allowExternalGeneration"
              class="btn btn-outline-primary ml-3"
              @click="onDisplayExternally()"
            >
              <i class="far fa-cog" /> {{ $t('externalDisplay') }}
            </button>

            <button
              v-if="allowSearch"
              class="btn btn-primary ml-3"
              @click="onSearch()"
            >
              <i class="far fa-cog" /> {{ this.searchText }}
            </button>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import LocationFilterSelect from '@/components/LocationFilterSelect';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import FilterListBox from '@/components/FilterListBox';
import BaseDatetimePicker from '@/components/BaseDatetimePicker';
import BaseTimePicker from '@/components/BaseTimePicker';
import BaseInput from './BaseInput.vue';
import ReportType from '@/constants/ReportType';

import { mapGetters } from 'vuex';
import _formatter from 'moment';

import {
  getUserOffices as _getUserOffices,
  getClientUsersList as _getClientUsersList,
  getClientUsersByMultiLocationList as _getClientUsersByMultiLocationList,
} from '@/services/UserService';

import {
  getLanguages as _getLanguages,
  getCompanyMiscellaneous as _getCompanyMiscellaneous,
} from '@/services/CompanyService';

import {
  getMiscData as _getMiscData,
  getMiscDataForLocations as _getMiscDataForLocations,
} from '@/services/LocationService';

import {
  getQueueList as _getServiceQueues,
  getQueueByMultiLocationList as _getServiceQueuesByMultiLocationList,
} from '@/services/ServicesService';
import {
  getServiceTypes as _getServiceTypes,
  getServiceTypesByMultiLocation as _getServiceTypesByMultiLocation,
} from '@/services/EmployeeService';

import { getStationList as _getStationsList } from '@/services/StationService';
import { getAppointmentStatuses as _getAppointmentStatuses } from '@/services/CodeService';

import { getSpecialistList as _getSpecialistList } from '@/services/SpecialistService';
import {
  getStampStatusList as _getStampStatusList,
  getSubServicesList as _getSubServicesList,
} from '@/services/StampService';

import { getSurveyList as _getSurveysList } from '@/services/SurveyService';

import CompanySpecialFieldType from '@/constants/CompanySpecialFieldType';
import RepresentativeCompanionTypeOptions from '@/constants/RepresentativeCompanionTypeOptions';
import ParticipantTypeOptions from '@/constants/ParticipantTypeOptions';

export default {
  components: {
    SubHeader,
    FilterMultiSelect,
    BaseDatetimePicker,
    LocationFilterSelect,
    FilterListBox,
    BaseTimePicker,
    BaseInput,
  },
  props: {
    id: {
      type: String,
      default: 'reportFiltersContainer',
    },
    isMultiLocations: {
      type: Boolean,
      default: () => false,
    },
    showLocations: {
      type: Boolean,
      default: () => true,
    },
    locationDefaultValue: {
      type: Number,
      default: () => null,
    },
    sendLocationParameter: {
      type: Boolean,
      default: true,
    },
    companyDefaultValue: {
      type: Number,
      default: () => null,
    },
    showServiceQueues: {
      type: Boolean,
      default: () => true,
    },
    showDateFrom: {
      type: Boolean,
      default: () => true,
    },
    showHourFrom: {
      type: Boolean,
      default: () => false,
    },
    showDateTo: {
      type: Boolean,
      default: () => true,
    },
    showHourTo: {
      type: Boolean,
      default: () => false,
    },
    showTimeFormat: {
      type: Boolean,
      default: () => false,
    },
    showUsers: {
      type: Boolean,
      default: () => true,
    },
    showAppointmentStatus: {
      type: Boolean,
      default: () => false,
    },
    showStampStatus: {
      type: Boolean,
      default: () => false,
    },
    showSpecialists: {
      type: Boolean,
      default: () => false,
    },
    showStations: {
      type: Boolean,
      default: () => false,
    },
    showServiceTypes: {
      type: Boolean,
      default: () => true,
    },
    showSubServiceTypes: {
      type: Boolean,
      default: () => false,
    },
    showDays: {
      type: Boolean,
      default: () => false,
    },
    showAnswerMode: {
      type: Boolean,
      default: () => false,
    },
    showDisplayWaitingQueue: {
      type: Boolean,
      default: () => false,
    },
    showDisplayTurnWaitingTime: {
      type: Boolean,
      default: () => false,
    },
    showDisplayAppointmentTime: {
      type: Boolean,
      default: () => false,
    },
    showFilterVideoCalls: {
      type: Boolean,
      default: () => false,
    },
    showRowLimitCount: {
      type: Boolean,
      default: () => false,
    },
    showExport: {
      type: Boolean,
      default: () => false,
    },
    showGroupByOffice: {
      type: Boolean,
      default: () => false,
    },
    showGroupBy: {
      type: Boolean,
      default: () => false,
    },
    showSurveys: {
      type: Boolean,
      default: () => false,
    },
    showMiscField: {
      type: Boolean,
      default: () => false,
    },
    showLanguageToAttend: {
      type: Boolean,
      default: () => false,
    },
    showCompanyMiscField9: {
      type: Boolean,
      default: () => false,
    },
    hideExportOnParameterChange: {
      type: Boolean,
      default: () => false,
    },
    searchText: {
      type: String,
      default: () => 'Generar',
    },
    exportText: {
      type: String,
      default: () => 'Exportar',
    },
    groupByTitle: {
      type: String,
      default: () => '',
    },
    groupByOptions: {
      type: Array,
      default: () => [],
    },
    groupByDefaultValue: {
      type: Number,
      default: () => 0,
    },
    sendGroupByParameter: {
      type: Boolean,
      default: false,
    },
    sendDaysWorked: {
      type: Boolean,
      default: () => false,
    },
    allowSearch: {
      type: Boolean,
      default: () => true,
    },
    allowExternalGeneration: {
      type: Boolean,
      default: () => true,
    },
    locationsDataSource: {
      type: Function,
      default: undefined,
    },
    sendIncludeOnHoldServicesParameter: {
      type: Boolean,
      default: () => false,
    },
    usersDataSource: {
      type: Function,
      default: undefined,
    },
    stampStatusDataSource: {
      type: Function,
      default: undefined,
    },
    appointmentStatusDataSource: {
      type: Function,
      default: undefined,
    },
    specialistsDataSource: {
      type: Function,
      default: undefined,
    },
    subServicesDataSource: {
      type: Function,
      default: undefined,
    },
    stationsDataSource: {
      type: Function,
      default: undefined,
    },
    serviceQueuesDataSource: {
      type: Function,
      default: undefined,
    },
    serviceTypesDataSource: {
      type: Function,
      default: undefined,
    },
    usersMultiLocationDataSource: {
      type: Function,
      default: undefined,
    },
    stationsMultiLocationDataSource: {
      type: Function,
      default: undefined,
    },
    serviceQueuesMultiLocationDataSource: {
      type: Function,
      default: undefined,
    },
    serviceTypesMultiLocationDataSource: {
      type: Function,
      default: undefined,
    },
    surveyDataSource: {
      type: Function,
      default: undefined,
    },
    miscFieldDataSource: {
      type: Function,
      default: undefined,
    },
    sendStampTransactionId: {
      type: Boolean,
      default: () => false,
    },
    sendCurrentUserId: {
      type: Boolean,
      default: () => false,
    },
    showAppointmentNumber: {
      type: Boolean,
      default: () => false,
    },
    displayHiddenServiceQueuesForReports: {
      type: Boolean,
      default: () => false,
    },
    includeLocationsFromServiceQueueGroups: {
      type: Boolean,
      default: () => false,
    },
    appendServiceQueueIdsOnAll: {
      type: Boolean,
      default: () => false,
    },
    companyMiscField9DataSource: {
      type: Function,
      default: undefined,
    },
    showRepresentativeCompanion: {
      type: Boolean,
      default: () => false,
    },
    showParticipantType: {
      type: Boolean,
      default: () => false,
    },
    reportType: {
      type: Number,
      default: () => ReportType.SSRS,
    }
  },
  data() {
    return {
      companyId: null,
      availableLocations: [],
      availableUsers: [],
      availableServiceQueues: [],
      availableServiceTypes: [],
      availableStations: [],
      availableVideoCallFilters: [
        { id: 0, name: `-- ${this.$t('select')} --` },
        { id: 1, name: this.$t('yes') },
        { id: 2, name: this.$t('no') },
      ],
      availableSurveys: [],
      availableAppointmentStatus: [],
      availableStampStatus: [],
      availableSpecialists: [],
      availableSubServices: [],
      availableAnswerModes: [
        { id: 1, name: this.$t('description') },
        { id: 2, name: this.$t('numeric') },
      ],
      availableMiscFields: [],
      availableLanguagesToAttend: [],
      availableCompanyMiscField9: [],
      availableRepresentativeCompanions: RepresentativeCompanionTypeOptions,
      availableParticipantTypes: ParticipantTypeOptions,
      isExportHidden: false,
      selectedFilters: {
        historic: false,
        locationConfigurationId: null,
        locations: [],
        users: [],
        serviceQueues: [],
        serviceTypes: [],
        days: [1, 2, 3, 4, 5, 6, 7],
        station: null,
        survey: null,
        answerMode: null,
        appointmentStatus: [],
        stampStatus: [],
        specialists: [],
        subServices: [],
        miscField: null,
        representativeCompanion: null,
        participantType: null,
        dateFrom: this.$moment().format(),
        dateTo: this.$moment().format(),
        hourFrom: '00:00:00',
        hourTo: '23:59:59',
        timeFormat: 'DW',
        displayWaitingQueue: true,
        displayTurnWaitingTime: false,
        displayAppointmentTime: false,
        filterVideoCalls: 0,
        group: this.groupByDefaultValue,
        groupByOffice: false,
        appointmentNumber: null,
        languageToAttend: null,
        rowLimitCount: 50,
        companyMiscField9: [],
      },
    };
  },
  computed: {
    hasSubServices() {
      return (
        (this.selectedFilters.serviceTypes.length == 0 &&
          this.availableServiceTypes.some(
            (serviceType) => serviceType.hasSubServices,
          )) ||
        this.selectedFilters.serviceTypes.some(
          (serviceType) => serviceType.hasSubServices,
        )
      );
    },
    availableTimeFormats() {
      return [
        { id: 'DW', name: this.$t('day') },
        { id: 'WW', name: this.$t('week') },
        { id: 'MM', name: this.$t('month') },
        { id: 'YYYY', name: this.$t('year') },
        { id: 'QQ', name: this.$t('trimester') },
      ];
    },
    today() {
      return this.$moment().format();
    },
    availableWeekDays() {
      const result = this.$moment.weekdays().map((e, i) => {
        return { text: 'day_' + i, value: i + 1 };
      });

      return result;
    },
    minimumDate() {
      return !this.selectedFilters.historic
        ? this.$moment(new Date(2023, 0, 1)).format()
        : this.$moment(new Date(2000, 0, 1)).format();
    },
    maximumDate() {
      return !this.selectedFilters.historic
        ? this.$moment().add(1, 'years').format()
        : this.$moment(new Date(2023, 11, 31)).format();
    },
    ...mapGetters('$_user', ['fullProfile', 'profile']),
    filteredUsers() {
      if (!this.selectedFilters.companyMiscField9.length) {
        return this.availableUsers;
      }

      const selectedIds = this.selectedFilters.companyMiscField9.map(
        (m) => m.id,
      );
      return this.availableUsers.filter((user) =>
        user.companyMiscIds.some((id) => selectedIds.includes(id)),
      );
    },
  },
  watch: {
    selectedFilters: {
      handler() {
        if (this.hideExportOnParameterChange) this.isExportHidden = true;
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadLocations();
    await this.loadAppointmentStatus();
    await this.loadStampStatus();
    if (!this.showLocations && this.showServiceTypes) {
      await this.loadServiceTypesBySingleLocation(
        this.locationDefaultValue,
        this.companyDefaultValue,
      );
    }
    if (this.showGroupBy && this.groupByOptions.length) {
      this.selectedFilters.group = this.groupByOptions[0].id;
    }
    if (this.showAnswerMode)
      this.selectedFilters.answerMode = this.availableAnswerModes[0];
    if (this.hideExportOnParameterChange) this.isExportHidden = true;

    const companyIds = Object.keys(this.fullProfile.companies).map((a) =>
      Number.parseInt(a),
    );

    this.companyId = Math.max(...companyIds);

    await this.loadLanguagesToAttend();
    await this.loadCompanyMiscField9();
  },
  created() {},
  methods: {
    async loadLanguagesToAttend() {
      if (this.showLanguageToAttend) {
        await _getLanguages(this.companyId)
          .then((response) => {
            this.availableLanguagesToAttend = response.data || [];
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async loadLocations() {
      if (this.showLocations) {
        if (this.locationsDataSource) {
          this.availableLocations = this.locationsDataSource();
        } else {
          await _getUserOffices(
            this.profile.id,
            false,
            this.includeLocationsFromServiceQueueGroups,
          )
            .then((response) => {
              this.availableLocations = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableLocations.length === 1) {
          this.selectedFilters.locationConfigurationId =
            this.availableLocations[0].id;

          await this.onLocationChange({ ...this.availableLocations[0] });
        }
      }
    },
    async loadSurveys(locationConfigurationId) {
      if (this.showSurveys) {
        if (!locationConfigurationId) {
          this.availableUsers = [];
          return;
        }

        if (this.surveyDataSource)
          this.availableSurveys = this.surveyDataSource(
            locationConfigurationId,
          );
        else {
          await _getSurveysList(locationConfigurationId)
            .then((response) => {
              this.availableSurveys = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableSurveys.length === 1) {
          this.selectedFilters.survey = this.availableSurveys[0];
        }
      }
    },
    async loadUsersBySingleLocation(locationConfigurationId) {
      if (this.showUsers) {
        if (!locationConfigurationId) {
          this.availableUsers = [];
          return;
        }

        if (this.usersDataSource)
          this.availableUsers = this.usersDataSource(locationConfigurationId);
        else {
          await _getClientUsersList(locationConfigurationId)
            .then((response) => {
              this.availableUsers = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableUsers.length === 1) {
          await this.onUserChange([this.availableUsers[0]]);
        }
      }
    },
    async loadUsersByMultiLocation(locationConfigurationIds) {
      if (this.showUsers) {
        if (!locationConfigurationIds || !locationConfigurationIds.length) {
          this.availableUsers = [];
          return;
        }

        if (this.usersDataSource)
          this.availableUsers = this.usersDataSource(locationConfigurationIds);
        else {
          await _getClientUsersByMultiLocationList(locationConfigurationIds)
            .then((response) => {
              this.availableUsers = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableUsers.length === 1) {
          await this.onUserChange([this.availableUsers[0]]);
        }
      }
    },
    async loadMiscDataBySingleLocation(locationConfigurationId) {
      if (this.showMiscField) {
        if (!locationConfigurationId) {
          this.availableMiscFields = [];
          return;
        }

        if (this.miscFieldDataSource)
          this.availableMiscFields = this.miscFieldDataSource(
            locationConfigurationId,
          );
        else {
          await _getMiscData(locationConfigurationId)
            .then((response) => {
              this.availableMiscFields = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }
      }
    },
    async loadMiscDataByMultiLocation(locationConfigurationIds) {
      this.availableMiscFields = [];
      this.selectedFilters.miscField = null;
      if (this.showMiscField) {
        if (!locationConfigurationIds || !locationConfigurationIds.length) {
          this.availableMiscFields = [];
          return;
        }

        if (this.miscFieldDataSource)
          this.availableMiscFields = this.miscFieldDataSource(
            locationConfigurationIds,
          );
        else {
          await _getMiscDataForLocations(locationConfigurationIds)
            .then((response) => {
              var newArray = [];
              if (response.data && response.data.length > 0) {
                response.data.forEach((element) => {
                  var result = newArray.find((p) => p.name == element.name);
                  if (!result) {
                    newArray.push(element);
                  }
                });
              }
              this.availableMiscFields = newArray;
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }
      }
    },
    async loadServiceQueuesBySingleLocation(locationConfigurationId) {
      if (this.showServiceQueues) {
        if (!locationConfigurationId) {
          this.availableServiceQueues = [];
          return;
        }

        if (this.serviceQueuesDataSource)
          this.availableServiceQueues = this.serviceQueuesDataSource(
            locationConfigurationId,
          );
        else {
          await _getServiceQueues(
            locationConfigurationId,
            this.includeOnHoldServices,
            this.displayHiddenServiceQueuesForReports,
          )
            .then((response) => {
              this.availableServiceQueues = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableServiceQueues.length === 1) {
          await this.onServiceQueueChange([this.availableServiceQueues[0]]);
        }
      }
    },
    async loadServiceQueuesByMultiLocation(locationConfigurationIds) {
      if (this.showServiceQueues) {
        if (!locationConfigurationIds || !locationConfigurationIds.length) {
          this.availableServiceQueues = [];
          return;
        }

        if (this.serviceQueuesMultiLocationDataSource)
          this.availableServiceQueues =
            await this.serviceQueuesMultiLocationDataSource(
              locationConfigurationIds,
            );
        else {
          await _getServiceQueuesByMultiLocationList(
            locationConfigurationIds,
            this.includeOnHoldServices,
            this.displayHiddenServiceQueuesForReports,
          )
            .then((response) => {
              this.availableServiceQueues = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }
      }
    },
    async loadServiceTypesBySingleLocation(locationConfigurationId, companyId) {
      if (this.showServiceTypes) {
        if (!locationConfigurationId && !companyId) {
          this.availableServiceTypes = [];
          this.availableSubServices = [];
          return;
        }

        if (this.serviceTypesDataSource)
          this.availableServiceTypes = await this.serviceTypesDataSource(
            locationConfigurationId,
            companyId,
          );
        else {
          if (!locationConfigurationId) {
            this.availableServiceTypes = [];
            this.availableSubServices = [];
            return;
          }

          await _getServiceTypes(
            locationConfigurationId,
            this.displayHiddenServiceQueuesForReports,
          )
            .then((response) => {
              this.availableServiceTypes = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableServiceTypes.length === 1) {
          this.selectedFilters.serviceTypes = [this.availableServiceTypes[0]];
        }

        this.onServiceTypeChange();
      }
    },
    async loadServiceTypesByMultiLocation(locationConfigurationIds) {
      if (this.showServiceTypes) {
        if (!locationConfigurationIds || !locationConfigurationIds.length) {
          this.availableServiceTypes = [];
          return;
        }

        if (this.serviceTypesMultiLocationDataSource) {
          this.availableServiceTypes =
            await this.serviceTypesMultiLocationDataSource(
              locationConfigurationIds,
            );
        } else {
          await _getServiceTypesByMultiLocation(
            locationConfigurationIds,
            this.displayHiddenServiceQueuesForReports,
          )
            .then((response) => {
              this.availableServiceTypes = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }
      }
    },
    async loadStations(locationConfigurationId) {
      if (this.showStations) {
        if (!locationConfigurationId) {
          this.availableStations = [];
          return;
        }

        if (this.stationsDataSource)
          this.availableStations = this.stationsDataSource(
            locationConfigurationId,
          );
        else {
          await _getStationsList(locationConfigurationId)
            .then((response) => {
              this.availableStations = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableStations.length === 1) {
          this.selectedFilters.station = this.availableStations[0];

          await this.onStationChange();
        }
      }
    },
    async cleanSelectedFilters() {
      // Clean users
      if (this.selectedFilters.users.length) {
        this.selectedFilters.users = this.selectedFilters.users.filter((x) =>
          this.availableUsers.some((y) => y.id == x.id),
        );
      }

      // Clean service queues
      if (this.selectedFilters.serviceQueues.length) {
        this.selectedFilters.serviceQueues =
          this.selectedFilters.serviceQueues.filter((x) =>
            this.availableServiceQueues.some((y) => y.id == x.id),
          );
      }

      // Clean service types
      if (this.selectedFilters.serviceTypes.length) {
        this.selectedFilters.serviceTypes =
          this.selectedFilters.serviceTypes.filter((x) =>
            this.availableServiceTypes.some((y) => y.id == x.id),
          );
      }

      // Clean stations
      if (this.selectedFilters.station) {
        if (
          !this.selectedFilters.locations.some(
            (x) => x.id == this.selectedFilters.station.locationConfigurationId,
          )
        ) {
          this.selectedFilters.station = null;
        }
      }

      // Clean company misc field 9
      if (this.selectedFilters.companyMiscField9.length) {
        this.selectedFilters.companyMiscField9 =
          this.selectedFilters.companyMiscField9.filter((x) =>
            this.availableCompanyMiscField9.some((y) => y.id == x.id),
          );
      }
    },
    async loadAppointmentStatus() {
      if (this.showAppointmentStatus) {
        if (this.appointmentStatusDataSource)
          this.availableAppointmentStatus = this.appointmentStatusDataSource();
        else {
          await _getAppointmentStatuses()
            .then((response) => {
              this.availableAppointmentStatus = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableAppointmentStatus.length === 1) {
          this.selectedFilters.appointmentStatus = [
            this.availableAppointmentStatus[0],
          ];

          await this.onAppointmentStatusChange();
        }
      }
    },
    async loadStampStatus() {
      if (this.showStampStatus) {
        if (this.stampStatusDataSource)
          this.availableStampStatus = this.stampStatusDataSource();
        else {
          await _getStampStatusList()
            .then((response) => {
              this.availableStampStatus = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableStampStatus.length === 1) {
          this.selectedFilters.stampStatus = [this.availableStampStatus[0]];

          await this.onStampStatusChange();
        }
      }
    },
    async loadSpecialists(locationConfigurationId) {
      if (this.showSpecialists) {
        if (!locationConfigurationId) {
          this.availableSpecialists = [];
          return;
        }

        if (this.specialistsDataSource)
          this.availableSpecialists = this.specialistsDataSource(
            locationConfigurationId,
          );
        else {
          await _getSpecialistList(locationConfigurationId)
            .then((response) => {
              this.availableSpecialists = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }

        if (this.availableSpecialists.length === 1) {
          this.selectedFilters.specialists = [this.availableSpecialists[0]];

          await this.onSpecialistsChange();
        }
      }
    },
    async loadSubServices() {
      if (!this.showSubServiceTypes || !this.hasSubServices) return;

      const serviceTypeList = (
        (this.selectedFilters.serviceTypes.length == 0 &&
          this.availableServiceTypes.filter(
            (serviceType) => serviceType.hasSubServices,
          )) ||
        this.selectedFilters.serviceTypes.filter(
          (serviceType) => serviceType.hasSubServices,
        )
      ).map((serviceType) => serviceType.id);

      if (serviceTypeList.length === 0) return;

      if (this.subServicesDataSource)
        this.availableSubServices = this.subServicesDataSource(serviceTypeList);
      else {
        await _getSubServicesList(serviceTypeList)
          .then((response) => {
            this.availableSubServices = response.data || [];
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }

      if (this.availableSubServices.length === 1) {
        this.selectedFilters.subServices = [this.availableSubServices[0]];

        await this.onSubServicesChange();
      }
    },
    async loadCompanyMiscField9() {
      this.availableCompanyMiscField9 = [];
      this.selectedFilters.companyMiscField9 = [];

      if (this.showCompanyMiscField9) {
        if (!this.companyId) {
          this.availableCompanyMiscField9 = [];
          return;
        }

        if (this.companyMiscField9DataSource) {
          this.availableCompanyMiscField9 = this.companyMiscField9DataSource(
            this.companyId,
          );
        } else {
          await _getCompanyMiscellaneous(
            this.companyId,
            CompanySpecialFieldType.Categorization,
          )
            .then((response) => {
              this.availableCompanyMiscField9 =
                response.data.filter((f) => f.isActive) || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }
      }
    },
    async onDateFromChange(date) {
      this.selectedFilters.dateFrom = _formatter(date).format();
    },
    async onDateToChange(date) {
      this.selectedFilters.dateTo = _formatter(date).format();
    },
    async onLocationsChange(value) {
      if (value.length) {
        const locationConfigurationIds = value.map((x) => x.id);

        await this.loadUsersByMultiLocation(locationConfigurationIds)
          .then(() => {
            this.loadServiceQueuesByMultiLocation(locationConfigurationIds);
          })
          .then(() =>
            this.loadServiceTypesByMultiLocation(locationConfigurationIds),
          );

        await this.loadMiscDataByMultiLocation(locationConfigurationIds);
      } else {
        this.availableUsers = [];
        this.availableServiceQueues = [];
        this.availableServiceTypes = [];
        this.availableMiscFields = [];
      }

      this.cleanSelectedFilters();

      this.selectedFilters.locations = value;
    },
    async onLocationChange(locationConfiguration) {
      if (locationConfiguration) {
        await this.loadUsersBySingleLocation(locationConfiguration.id);
        await this.loadServiceQueuesBySingleLocation(locationConfiguration.id);
        await this.loadServiceTypesBySingleLocation(locationConfiguration.id);
        await this.loadStations(locationConfiguration.id);
        await this.loadSurveys(locationConfiguration.id);
        await this.loadMiscDataBySingleLocation(locationConfiguration.id);

        this.loadSpecialists(locationConfiguration.id);
      } else {
        this.availableUsers = [];
        this.availableServiceQueues = [];
        this.availableServiceTypes = [];
        this.availableStations = [];
        this.availableSpecialists = [];
        this.availableSubServices = [];
        this.availableSurveys = [];
        this.availableMiscFields = [];
      }

      this.cleanSelectedFilters();
    },
    async onRepresentativeCompanionChange(value) {
      this.selectedFilters.representativeCompanion = value;
    },
    async onParticipantTypeChange(value) {
      this.selectedFilters.participantType = value;
    },
    async onMiscFieldChange(value) {
      this.selectedFilters.miscField = value;
    },
    async onUserChange(value, multipleSelectionEnabled = false) {
      if (value.length == 0) {
        this.availableUsers.forEach((x) => (x.selected = false));

        this.selectedFilters.users = [];
      } else if (value.length == 1) {
        // Remove the item if its already selected
        let selectedItemIndex = this.selectedFilters.users.findIndex(
          (x) => x.id == value[0].id,
        );

        if (selectedItemIndex > -1) {
          this.selectedFilters.users[selectedItemIndex].selected = false;

          this.selectedFilters.users.splice(selectedItemIndex, 1);
        } else {
          if (!multipleSelectionEnabled) {
            const serviceQueueToRemove = this.availableUsers.find(
              (x) => x.selected,
            );

            if (serviceQueueToRemove !== undefined) {
              this.availableUsers.find((x) => x.selected).selected = false;

              this.selectedFilters.users.pop();
            }
          }

          // Find the clicked item and select it
          selectedItemIndex = this.availableUsers.findIndex(
            (x) => x.id == value[0].id,
          );

          if (selectedItemIndex > -1) {
            this.availableUsers[selectedItemIndex].selected = true;

            this.selectedFilters.users.push(
              this.availableUsers[selectedItemIndex],
            );
          }
        }
      } else {
        this.availableUsers.forEach((x) => (x.selected = true));

        this.selectedFilters.users = [...this.availableUsers];
      }
    },
    async onServiceQueueChange(value, multipleSelectionEnabled = false) {
      if (value.length == 0) {
        this.availableServiceQueues.forEach((x) => (x.selected = false));

        this.selectedFilters.serviceQueues = [];
      } else if (value.length == 1) {
        // Remove the item if its already selected
        let selectedItemIndex = this.selectedFilters.serviceQueues.findIndex(
          (x) => x.id == value[0].id,
        );

        if (selectedItemIndex > -1) {
          this.selectedFilters.serviceQueues[
            selectedItemIndex
          ].selected = false;

          this.selectedFilters.serviceQueues.splice(selectedItemIndex, 1);
        } else {
          if (!multipleSelectionEnabled) {
            const serviceQueueToRemove = this.availableServiceQueues.find(
              (x) => x.selected,
            );

            if (serviceQueueToRemove !== undefined) {
              this.availableServiceQueues.find(
                (x) => x.selected,
              ).selected = false;

              this.selectedFilters.serviceQueues.pop();
            }
          }

          // Find the clicked item and select it
          selectedItemIndex = this.availableServiceQueues.findIndex(
            (x) => x.id == value[0].id,
          );

          if (selectedItemIndex > -1) {
            this.availableServiceQueues[selectedItemIndex].selected = true;

            this.selectedFilters.serviceQueues.push(
              this.availableServiceQueues[selectedItemIndex],
            );
          }
        }
      } else {
        this.availableServiceQueues.forEach((x) => (x.selected = true));

        this.selectedFilters.serviceQueues = [...this.availableServiceQueues];
      }
    },
    async onServiceTypeChange(value = [], multipleSelectionEnabled = false) {
      if (value.length == 0) {
        this.availableServiceTypes.forEach((x) => (x.selected = false));

        this.selectedFilters.serviceTypes = [];
      } else if (value.length == 1) {
        // Remove the item if its already selected
        let selectedItemIndex = this.selectedFilters.serviceTypes.findIndex(
          (x) => x.id == value[0].id,
        );

        if (selectedItemIndex > -1) {
          this.selectedFilters.serviceTypes[selectedItemIndex].selected = false;

          this.selectedFilters.serviceTypes.splice(selectedItemIndex, 1);
        } else {
          if (!multipleSelectionEnabled) {
            const serviceQueueToRemove = this.availableServiceTypes.find(
              (x) => x.selected,
            );

            if (serviceQueueToRemove !== undefined) {
              this.availableServiceTypes.find(
                (x) => x.selected,
              ).selected = false;

              this.selectedFilters.serviceTypes.pop();
            }
          }

          // Find the clicked item and select it
          selectedItemIndex = this.availableServiceTypes.findIndex(
            (x) => x.id == value[0].id,
          );

          if (selectedItemIndex > -1) {
            this.availableServiceTypes[selectedItemIndex].selected = true;

            this.selectedFilters.serviceTypes.push(
              this.availableServiceTypes[selectedItemIndex],
            );
          }
        }
      } else {
        this.availableServiceTypes.forEach((x) => (x.selected = true));

        this.selectedFilters.serviceTypes = [...this.availableServiceTypes];
      }

      this.loadSubServices();
    },
    async onGroupByChange(value) {
      this.$emit('on-group-change', value);
    },
    async onAnswerModeChange(value) {
      this.selectedFilters.answerMode = value;
    },
    async onLanguageToAttendChange(value) {
      this.selectedFilters.languageToAttend = value;
    },
    async onSurveyChange(value) {
      this.selectedFilters.survey = value;
    },
    async onDayChange(elem) {
      if (elem.target.checked == true) {
        var checkedValue = this.value.find((a) => a == elem.target.value);

        if (checkedValue == null) {
          this.value.push(parseInt(elem.target.value));
        }
      } else if (elem.target.checked == false) {
        var unCheckedValue = this.value.findIndex(
          (a) => a == elem.target.value,
        );

        if (unCheckedValue != null) {
          this.value.splice(unCheckedValue, 1);
        }
      }
    },
    async onHistoricDataChange(value) {
      this.selectedFilters.historic = value;

      if (value) {
        this.selectedFilters.dateFrom = this.$moment(
          new Date(2021, 11, 31),
        ).format();
        this.selectedFilters.dateTo = this.$moment(
          new Date(2021, 11, 31),
        ).format();
      } else {
        this.selectedFilters.dateFrom = this.$moment().format();
        this.selectedFilters.dateTo = this.$moment().format();
      }
    },
    async onGroupByOfficeChange(value) {
      this.$emit('on-groupbyoffice-change', value);
    },
    async onAppointmentStatusChange(value) {
      this.selectedFilters.appointmentStatus = value;
    },
    async onStampStatusChange(value) {
      this.selectedFilters.stampStatus = value;
    },
    async onSpecialistsChange(value, multipleSelectionEnabled = false) {
      if (value.length == 0) {
        this.availableSpecialists.forEach((x) => (x.selected = false));

        this.selectedFilters.specialists = [];
      } else if (value.length == 1) {
        // Remove the item if its already selected
        let selectedItemIndex = this.selectedFilters.specialists.findIndex(
          (x) => x.id == value[0].id,
        );

        if (selectedItemIndex > -1) {
          this.selectedFilters.specialists[selectedItemIndex].selected = false;

          this.selectedFilters.specialists.splice(selectedItemIndex, 1);
        } else {
          if (!multipleSelectionEnabled) {
            const serviceQueueToRemove = this.availableSpecialists.find(
              (x) => x.selected,
            );

            if (serviceQueueToRemove !== undefined) {
              this.availableSpecialists.find(
                (x) => x.selected,
              ).selected = false;

              this.selectedFilters.specialists.pop();
            }
          }

          // Find the clicked item and select it
          selectedItemIndex = this.availableSpecialists.findIndex(
            (x) => x.id == value[0].id,
          );

          if (selectedItemIndex > -1) {
            this.availableSpecialists[selectedItemIndex].selected = true;

            this.selectedFilters.specialists.push(
              this.availableSpecialists[selectedItemIndex],
            );
          }
        }
      } else {
        this.availableSpecialists.forEach((x) => (x.selected = true));

        this.selectedFilters.specialists = [...this.availableSpecialists];
      }
    },
    async onSubServicesChange(value, multipleSelectionEnabled = false) {
      if (value.length == 0) {
        this.availableSubServices.forEach((x) => (x.selected = false));

        this.selectedFilters.subServices = [];
      } else if (value.length == 1) {
        // Remove the item if its already selected
        let selectedItemIndex = this.selectedFilters.subServices.findIndex(
          (x) => x.id == value[0].id,
        );

        if (selectedItemIndex > -1) {
          this.selectedFilters.subServices[selectedItemIndex].selected = false;

          this.selectedFilters.subServices.splice(selectedItemIndex, 1);
        } else {
          if (!multipleSelectionEnabled) {
            const serviceQueueToRemove = this.availableSubServices.find(
              (x) => x.selected,
            );

            if (serviceQueueToRemove !== undefined) {
              this.availableSubServices.find(
                (x) => x.selected,
              ).selected = false;

              this.selectedFilters.subServices.pop();
            }
          }

          // Find the clicked item and select it
          selectedItemIndex = this.availableSubServices.findIndex(
            (x) => x.id == value[0].id,
          );

          if (selectedItemIndex > -1) {
            this.availableSubServices[selectedItemIndex].selected = true;

            this.selectedFilters.subServices.push(
              this.availableSubServices[selectedItemIndex],
            );
          }
        }
      } else {
        this.availableSubServices.forEach((x) => (x.selected = true));

        this.selectedFilters.subServices = [...this.availableSubServices];
      }
    },
    buildParameters() {
      // Build the parameters object
      let parameters = {};

      if(this.reportType == ReportType.SSRS || this.reportType == ReportType.PowerBI) 
      {
        parameters.historic = this.selectedFilters.historic ? '1' : '0';

        if (this.showDateFrom && this.selectedFilters.dateFrom) {
          parameters.DateFrom = this.$moment(
            this.selectedFilters.dateFrom,
          ).format('MM/DD/yyyy');

          if (this.showHourFrom && this.selectedFilters.hourFrom) {
            parameters.DateFrom += ` ${this.$moment(
              this.selectedFilters.hourFrom,
            ).format('HH:mm:ss')}`;
          }
        }

        if (this.showDateTo && this.selectedFilters.dateTo) {
          parameters.DateTo = this.$moment(this.selectedFilters.dateTo).format(
            'MM/DD/yyyy',
          );

          if (this.showHourTo && this.selectedFilters.hourTo) {
            parameters.DateTo += ` ${this.$moment(
              this.selectedFilters.hourTo,
            ).format('HH:mm:ss')}`;
          }
        }

        if (this.showLocations && this.sendLocationParameter) {
          if (!this.isMultiLocations) {
            parameters.LocationConfigurationId =
              this.selectedFilters.locationConfigurationId;
          } else {
            parameters.LocationConfigurationId = this.selectedFilters.locations
              .map((x) => x.id)
              .join(',');

            if (
              this.availableLocations.length ==
              this.selectedFilters.locations.length
            )
              parameters.HasAllLocations = true;
          }
        }

        if (this.showServiceQueues) {
          let serviceQueuesToSend = [];
          let hasAllServiceQueues = false;

          if (this.selectedFilters.displayWaitingQueue) {
            serviceQueuesToSend =
              this.selectedFilters.serviceQueues.length > 0
                ? this.selectedFilters.serviceQueues
                : this.availableServiceQueues;

            hasAllServiceQueues =
              serviceQueuesToSend.length == this.availableServiceQueues.length;
          } else {
            serviceQueuesToSend =
              this.selectedFilters.serviceQueues.length > 0
                ? this.selectedFilters.serviceQueues.filter((x) => !x.isOnHold)
                : this.availableServiceQueues.filter((x) => !x.isOnHold);

            hasAllServiceQueues =
              serviceQueuesToSend.length ==
              this.availableServiceQueues.filter((x) => !x.isOnHold).length;
          }

          if (this.selectedFilters.displayWaitingQueue) {
            parameters.ServiceId =
              this.selectedFilters.serviceQueues.length > 0
                ? this.selectedFilters.serviceQueues.map((x) => x.id).join(',')
                : this.availableServiceQueues.map((x) => x.id).join(',');
          } else {
            parameters.ServiceId =
              this.selectedFilters.serviceQueues.length > 0
                ? this.selectedFilters.serviceQueues
                    .filter((x) => !x.isOnHold)
                    .map((x) => x.id)
                    .join(',')
                : this.availableServiceQueues
                    .filter((x) => !x.isOnHold)
                    .map((x) => x.id)
                    .join(',');
          }

          if (this.selectedFilters.representativeCompanion)
            parameters.RepresentativeTypeId =
              this.selectedFilters.representativeCompanion.value;

          parameters.HasAllServiceQueues = hasAllServiceQueues;

          if (
            !this.appendServiceQueueIdsOnAll &&
            this.isMultiLocations &&
            hasAllServiceQueues
          ) {
            parameters.ServiceId = '0';
          }
        }

        if (this.showServiceTypes) {
          if (
            this.selectedFilters.serviceTypes.length ==
            this.availableServiceTypes.length
          )
            parameters.ServiceTypeId = '0';
          else
            parameters.ServiceTypeId =
              this.selectedFilters.serviceTypes.length > 0
                ? this.selectedFilters.serviceTypes.map((x) => x.id).join(',')
                : '0';
        }

        if (this.showDays) {
          parameters.DaysOfWeek =
            this.selectedFilters.days.length > 0
              ? this.selectedFilters.days
                  .sort((a, b) => a - b)
                  .map((x) => x)
                  .join(',')
              : '0';
        }

        if (this.showUsers) {
          if (
            this.selectedFilters.users.length == 0 ||
            this.selectedFilters.users.length == this.availableUsers.length
          ) {
            parameters.UserID = '00000000-0000-0000-0000-000000000000';
          } else if (this.selectedFilters.users.length > 0) {
            parameters.UserID = `${this.selectedFilters.users
              .map((x) => x.id)
              .join(',')}`;
          }
        } else if (this.sendCurrentUserId) {
          parameters.UserID = this.profile.id;
        }

        if (this.sendDaysWorked) {
          parameters.DaysWorked = '-1';
        }

        if (this.showStations) {
          parameters.StationId = this.selectedFilters.station
            ? this.selectedFilters.station.id
            : '0';
        }

        if (this.showTimeFormat) {
          parameters.TimeFormat = this.selectedFilters.timeFormat
            ? this.selectedFilters.timeFormat
            : '';
        }

        if (this.showDisplayTurnWaitingTime) {
          parameters.TurnoWaitingTime = this.selectedFilters
            .displayTurnWaitingTime
            ? -1
            : 0;
        }

        if (this.showDisplayAppointmentTime) {
          parameters.AppointmentTime =
            this.selectedFilters.displayAppointmentTime;
        }

        if (this.showFilterVideoCalls) {
          parameters.FilterVideoCall = !this.selectedFilters.filterVideoCalls
            ? 0
            : this.selectedFilters.filterVideoCalls;
        }

        if (this.showGroupBy && this.sendGroupByParameter) {
          parameters.GroupByFlag = this.selectedFilters.group;
        }

        if (this.groupByOffice) {
          parameters.GroupOffice = this.selectedFilters.groupByOffice;
        }

        if (this.showSurveys && this.selectedFilters.survey) {
          parameters.SurveyId = this.selectedFilters.survey.id;
        }

        if (
          this.showDisplayWaitingQueue &&
          this.sendIncludeOnHoldServicesParameter
        ) {
          parameters.IncludeOnHoldServices =
            this.selectedFilters.displayWaitingQueue;
        }

        if (this.showAppointmentStatus) {
          parameters.Status =
            this.selectedFilters.appointmentStatus.length > 0
              ? this.selectedFilters.appointmentStatus.map((x) => x.id).join(',')
              : '0';
        }

        if (this.showStampStatus) {
          parameters.StampStatus =
            this.selectedFilters.stampStatus.length > 0
              ? this.selectedFilters.stampStatus.map((x) => x.id).join(',')
              : '0';
        }

        if (this.showSpecialists) {
          parameters.SpecialistId =
            this.selectedFilters.specialists.length > 0
              ? this.selectedFilters.specialists.map((x) => x.id).join(',')
              : '0';
        }

        if (this.sendStampTransactionId) {
          if (this.hasSubServices) {
            parameters.StampTransactionId =
              this.selectedFilters.subServices &&
              this.selectedFilters.subServices.length > 0
                ? this.selectedFilters.subServices.map((x) => x.id).join(',')
                : '0';
          } else {
            parameters.StampTransactionId = '0';
          }
        }

        if (this.showAppointmentNumber) {
          parameters.AppointmentNumber =
            this.selectedFilters.appointmentNumber || '0';
        }

        if (this.showLanguageToAttend && this.selectedFilters.languageToAttend) {
          parameters.LanguageToAttendId =
            this.selectedFilters.languageToAttend.id;
        }

        if (this.showAnswerMode) {
          parameters.AnswerMode = this.selectedFilters.answerMode.id;
        }

        if (this.showRowLimitCount) {
          parameters.RowLimitCount = this.selectedFilters.rowLimitCount;
        }

        if (this.showMiscField && this.selectedFilters.miscField) {
          parameters.ExtraField3Value = this.selectedFilters.miscField.id;
        }

        // If the user filter is active, the user category filters the users, so the category should not be sent to the report.
        if (!this.showUsers && this.showCompanyMiscField9) {
          parameters.CompanyMiscField9 =
            this.selectedFilters.companyMiscField9.length > 0
              ? this.selectedFilters.companyMiscField9.map((x) => x.id).join(',')
              : '';
        }

        if (this.showParticipantType) {
          parameters.ParticipantType =
            this.selectedFilters.participantType?.value ?? '';
        }
      }

      return parameters;
    },
    async onSearch() {
      this.$emit('beforeSearch');
      this.$nextTick(async () => {
        const isValid = await this.$refs.formValidator.validate();
        if (!isValid) {
          return;
        }

        if (this.hideExportOnParameterChange) this.isExportHidden = false;

        this.$emit('search', this.buildParameters());
      });
    },
    async onDisplayExternally() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        return;
      }

      this.$emit('on-external-display', this.buildParameters());
    },
    async onExport() {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) {
        return;
      }

      this.$emit('on-export', this.buildParameters());
    },
    async HideExport() {
      this.isExportHidden = true;
    },
    async onCompanyMiscField9Change(value, multipleSelectionEnabled = false) {
      // Clear seelct users
      this.selectedFilters.users = [];
      this.availableUsers.forEach((x) => (x.selected = false));

      if (value.length == 0) {
        this.availableCompanyMiscField9.forEach((x) => (x.selected = false));

        this.selectedFilters.companyMiscField9 = [];
      } else if (value.length == 1) {
        // Remove the item if its already selected
        let selectedItemIndex =
          this.selectedFilters.companyMiscField9.findIndex(
            (x) => x.id == value[0].id,
          );

        if (selectedItemIndex > -1) {
          this.selectedFilters.companyMiscField9[
            selectedItemIndex
          ].selected = false;

          this.selectedFilters.companyMiscField9.splice(selectedItemIndex, 1);
        } else {
          if (!multipleSelectionEnabled) {
            const companyMiscFieldToRemove =
              this.availableCompanyMiscField9.find((x) => x.selected);

            if (companyMiscFieldToRemove !== undefined) {
              this.availableCompanyMiscField9.find(
                (x) => x.selected,
              ).selected = false;

              this.selectedFilters.companyMiscField9.pop();
            }
          }

          // Find the clicked item and select it
          selectedItemIndex = this.availableCompanyMiscField9.findIndex(
            (x) => x.id == value[0].id,
          );

          if (selectedItemIndex > -1) {
            this.availableCompanyMiscField9[selectedItemIndex].selected = true;

            this.selectedFilters.companyMiscField9.push(
              this.availableCompanyMiscField9[selectedItemIndex],
            );
          }
        }
      } else {
        this.availableCompanyMiscField9.forEach((x) => (x.selected = true));

        this.selectedFilters.companyMiscField9 = [
          ...this.availableCompanyMiscField9,
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
  padding-top: 75%;
}

.filters-container .custom-switch {
  margin-top: 35px;
}
</style>
