<template>
  <section>
    <content-header :title="$t('employeeHistory')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <report-filters
          class="mb-4"
          :search-text="$t('generate')"
          :show-display-waiting-queue="true"
          :show-service-types="true"
          :show-export="true"
          :show-company-misc-field9="true"
          @on-export="onExport($event)"
          @on-external-display="onDisplayExternally($event)"
          @search="onSearchClick($event)"
        />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <report-viewer
            ref="report-ref"
            report-name="EmployeeDetailedHistory"
            export-report-name="EmployeeDetailedHistoryExport"
            width="100%"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import ReportViewer from '@/components/ReportViewer';
import ReportFilters from '@/components/ReportFilters';

export default {
  name: 'UserDetailedHistoryReport',
  components: {
    contentHeader,
    ReportViewer,
    ReportFilters,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async onSearchClick(parameters) {
      this.$refs['report-ref'].render(parameters);
    },
    async onDisplayExternally(parameters) {
      this.$refs['report-ref'].renderExternal(parameters);
    },
    async onExport(parameters) {
      this.$refs['report-ref'].renderExport(parameters);
    },
  },
};
</script>