<template>
  <div>
    <div
      :id="id"
      :style="reportStyle"
      :width="width"
    />
  </div>
</template>

<script>
import * as pbi from 'powerbi-client';

export default {
  props: {
    id: {
      type: String,
      default: 'reportContainer'
    },
    reportId: {
      type: String,
      default: ''
    },
    filterPaneEnabled: {
      type: Boolean,
      default: false
    },
    navContentPaneEnabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '541px'
    },
    datasource: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return { };
  },
  computed: {
    reportStyle () {
      return `height: ${this.height}`;
    },
    reportParameters () {
      let strFilter = '';

      if (this.params == null || Object.keys(this.params).length == 0) {
        return strFilter;
      }

      strFilter += '&filter=';

      for (const property in this.params) {
        if (Array.isArray(this.params[property])) {
          strFilter += `${this.datasource}/${property} in (`;

          for (let i = 0; i < this.params[property].length; i++) {
            strFilter += `${this.params[property][i]}`;

            if (i < this.params[property].length - 1) {
              strFilter += ', ';
            }
          }

          strFilter += ') and ';
        } else {
          strFilter += `${this.datasource}/${property} in ('${this.params[property]}') and `;
        }
      }

      strFilter = strFilter.replace(/ and $/, '');

      return strFilter;
    }
  },
  async mounted () {
  },
  methods: {
    render: function (reportId, token, embedUrl) {
      var permissions = pbi.models.Permissions.All;

      var config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: token,
        embedUrl: embedUrl + this.reportParameters,
        id: reportId,
        permissions: permissions,
        height: 542,
        settings: {
          navContentPaneEnabled: false,
          bars: {
            actionBar: {
              visible: false
            }
          },
          panes: {
            bookmarks: {
              visible: false
            },
            fields: {
              expanded: false
            },
            filters: {
              expanded: false,
              visible: false
            },
            pageNavigation: {
              visible: false
            },
            selection: {
              visible: false
            },
            syncSlicers: {
              visible: false
            },
            visualizations: {
              expanded: false
            }
          },
          height: 541.25
        }
      };

      var container = document.getElementById(this.id);

      const powerbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

      // Reset the embeded report
      powerbiService.reset(container);

      // Embed the report and display it within the div container.
      var report = powerbiService.embed(container, config);

      // Report.off removes a given event handler if it exists.
      report.off('loaded');

      // Report.off removes a given event handler if it exists.
      report.off('rendered');

      report.off('saved');
    }
  }
};
</script>

<style lang="scss" scoped>
  .header-title h1.title {
    text-align: left;
    letter-spacing: 0px;
    color: $color-font-primary;
    opacity: 1;
    font-size: 24px;
    font-weight: bold;
  }
</style>
