<template>
  <custom-modal
    id="delete-modal"
    ref="delete-modal-ref"
    :no-close-on-back-drop="true"
    size="md"
  >
    <validation-observer
      ref="form"
      novalidate
    >
      <div class="back-container text-center">

      <loading :active="loading" :is-full-page="false" color="#F37E00" />
        <i
          class="fal fa-exclamation-circle back-container__icon fa-3x"
          style="color: #ff8105"
        />

        <div class="back-container__title text-center mt-3 no-select">
          {{ $t(title || 'areYouSureYouWantToDelete') }}
          <br />
          {{ $t(subtitle || 'toDeleteWriteTheWord') }}
          <b> " {{ item ? item[name] : null }} " </b> {{ $t('inTheBox') }}
        </div>
        <br />
        <div class="col">
          <label v-if="displayLabel">{{ $t(label) }}</label>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <base-input
              v-model="confirmationName"
              :error="errors[0]"
              :error-msg="$t('requiredField')"
            />
          </validation-provider>
        </div>
        <div class="d-flex flex-row mt-4">
          <button
            class="btn btn-secondary ml-3 flex-grow-1"
            type="button"
            @click="$emit('input', false)"
          >
            {{ $t('no') }}
          </button>

          <button
            class="btn btn-primary mx-4 flex-grow-1"
            type="button"
            @click="onConfirm()"
          >
            {{ $t('yes') }}
          </button>
        </div>
      </div>
    </validation-observer>
  </custom-modal>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import BaseInput from './BaseInput.vue';

export default {
  name: 'DeleteModal',
  components: {
    CustomModal,
    BaseInput,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    displayLabel: {
      type: Boolean,
      default: () => true,
    },
    item: {
      type: Object,
      default: () => null,
    },
    name: {
      type: String,
      default: 'name',
    },
    label: {
      type: String,
      default: 'name',
    },
    errorMessage: {
      type: String,
      default: 'nameIsNotCorrect',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      confirmationName: '',
    };
  },
  watch: {
    value(val) {
      this.$refs['delete-modal-ref'][val ? 'show' : 'hide']();

      if (!val)
        setTimeout(() => {
          this.confirmationName = '';
        }, 1000);
    },
  },
  methods: {
    async onConfirm() {
      if (!(await this.$refs.form.validate())) return;
      if (this.confirmationName.trim() != this.item[this.name].toString().trim())
        return this.ShowErrorToast(this.$t(this.errorMessage));
      this.$emit('on-confirm');
    },
  },
};
</script>
